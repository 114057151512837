import { Injectable } from '@angular/core';
import { WithdrawalProductType } from '@generated/generatedEntities';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ExistingWptService {
  existingWpt: WithdrawalProductType[] = [];
  allWpts: WithdrawalProductType[] = [];
  constructor() {
    this.intializeService();
  }

  intializeService() {
    /**
     * Those are Public Wpts
     */
    this.existingWpt.push('BUILDING_COSTS_AND_DATES');
    this.existingWpt.push('REBUILD_COSTS');
    this.existingWpt.push('OPERATING_COSTS');
    this.existingWpt.push('LCC_PACKAGE');
    /**
     * Those are all wpts including private.
     */
    this.allWpts.push('LCC'); //we need LCC for BA - as the package is not an actual withdrawalProductType but a package type...
    this.allWpts.push('BUILDING_COSTS_AND_DATES');
    this.allWpts.push('BUILDING_INSURANCE_VALUES');
    this.allWpts.push('OPERATING_COSTS');
    this.allWpts.push('REBUILD_COSTS');
    this.allWpts.push('DAMAGE_COSTS');
  }

  getExistingWpt(): WithdrawalProductType[] {
    return this.existingWpt;
  }
  getAllExistingWpts(): WithdrawalProductType[] {
    return _.cloneDeep(this.allWpts);
  }
}
