<ng-container *transloco="let t">
  <mat-card class="p-0">
    <div class="flexBoxOuter rowBackgroundRed">
      <div class="center-box flexBoxInnerWrapper color-white">
        <form>
          <div class="centeredBoxText">
            <h3>{{ t("register.catch.curious") }}</h3>
          </div>
          <div class="centeredBoxText">{{ t("register.catch.ki") }}</div>
          <div class="centeredBoxText mt-4" *ngIf="regiterBtn">
            <button
              mat-button
              color="primary"
              id="freeMonth"
              type="submit"
              class="btn-elegant-white btn-lg"
              (click)="showRegBlock()"
            >
              {{ t("register.initFreeMonth.button") }}
            </button>
          </div>
          <div class="centeredBoxText mt-4" data-translate="register.catch.test-ki">
            {{ t("register.catch.test-ki") }}
          </div>
        </form>
      </div>
    </div>
  </mat-card>

  <!-- reg form section -->

  <div *ngIf="regBlock" class="centeredBox flexBoxOuter rowBackgroundBlue reg-container">
    <div class="flexBoxInnerWrapper">
      <form (ngSubmit)="checkRegistraion()" [formGroup]="registerForm">
        <div class="centeredBoxText" style="padding-bottom: 20px">
          <h4 data-translate="register.form.first-account.title-1">{{ t("register.form.first-account.title-1") }}</h4>
          <div>
            <p data-translate="register.form.first-account.text-1">{{ t("register.form.first-account.text-1") }}</p>
          </div>
        </div>
        <mat-form-field
          style="padding-top: 20px; height: 85px; margin-bottom: 25px"
          [floatLabel]="'always'"
          class="example-full-width"
          appearance="outline"
        >
          <mat-label>{{ t("global.form.email") }}</mat-label>
          <input
            type="email"
            matInput
            formControlName="emailFormControl"
            placeholder="{{ 'global.form.email.placeholder' | transloco }}"
          />
          <mat-error *ngIf="registerForm.get('emailFormControl')?.hasError('required')">
            {{ t("global.messages.validate.email.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('emailFormControl')?.hasError('email')">
            {{ t("global.messages.validate.email.invalid") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('emailFormControl')?.hasError('minlength')">
            {{ t("global.messages.validate.email.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('emailFormControl')?.hasError('maxlength')">
            {{ t("global.messages.validate.email.maxlength") }}
          </mat-error>
          <!-- <mat-error *ngIf="registerForm.hasError('email') && !registerForm.hasError('required')">
          </mat-error> -->
          <!-- <mat-error *ngIf="registerForm.hasError('required')"> Email is <strong>required</strong> </mat-error> -->
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" [hideRequiredMarker]="true" appearance="outline">
          <mat-label>{{ t("global.form.newpassword") }}</mat-label>
          <input
            #password
            type="password"
            matInput
            formControlName="password"
            autocomplete="current-password"
            [placeholder]="'global.form.newpassword.placeholder' | transloco"
            required
          />
          <mat-error *ngIf="registerForm.get('password')?.hasError('required')">
            {{ t("global.messages.validate.newpassword.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('password')?.hasError('minlength')">
            {{ t("global.messages.validate.newpassword.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('password')?.hasError('maxlength')">
            {{ t("global.messages.validate.newpassword.maxlength") }}
          </mat-error>
        </mat-form-field>
        <password-strength-meter
          [password]="password.value"
          style="display: flex; margin-bottom: 10px; margin-top: 5px"
        ></password-strength-meter>
        <mat-form-field [floatLabel]="'always'" [hideRequiredMarker]="true" appearance="outline">
          <mat-label>{{ t("global.form.confirmpassword") }}</mat-label>
          <input
            type="password"
            matInput
            formControlName="confirmPassword"
            autocomplete="confirm-password"
            [placeholder]="t('global.form.confirmpassword.placeholder')"
            required
          />
          <!-- <mat-error *ngIf="registerForm.hasError('notSame')"> Passwords do not match </mat-error> -->
          <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('required')">
            {{ t("global.messages.validate.confirmpassword.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('minlength')">
            {{ t("global.messages.validate.confirmpassword.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('confirmPassword')?.hasError('maxlength')">
            {{ t("global.messages.validate.confirmpassword.maxlength") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.firstname") }}</mat-label>
          <input
            matInput
            formControlName="firstname"
            autocomplete="first-name"
            [placeholder]="'settings.form.firstname.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('firstname')?.hasError('required')">
            {{ t("settings.messages.validate.firstname.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('firstname')?.hasError('minlength')">
            {{ t("settings.messages.validate.firstname.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('firstname')?.hasError('maxlength')">
            {{ t("settings.messages.validate.firstname.maxlength") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.lastname") }}</mat-label>
          <input
            matInput
            formControlName="lastname"
            autocomplete="last-name"
            [placeholder]="'settings.form.lastname.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('lastname')?.hasError('required')">
            {{ t("settings.messages.validate.lastname.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('lastname')?.hasError('minlength')">
            {{ t("settings.messages.validate.lastname.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('lastname')?.hasError('maxlength')">
            {{ t("settings.messages.validate.lastname.maxlength") }}
          </mat-error>
        </mat-form-field>

        <div class="centeredBoxText mt-4 mb-3">
          <h4>{{ t("register.form.company.title") }}</h4>
          <div>
            <p>{{ t("register.form.company.text-1") }}</p>
          </div>
          <div>
            <p>{{ t("register.form.company.text-2") }}</p>
          </div>
        </div>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgname") }}</mat-label>
          <input
            matInput
            formControlName="orgName"
            autocomplete="Org-Name"
            [placeholder]="'settings.form.orgname.placeholder' | transloco"
            minlength="1"
            maxlength="50"
          />
          <mat-error *ngIf="registerForm.get('orgName')?.hasError('required')">
            {{ t("settings.messages.validate.orgname.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('orgName')?.hasError('minlength')">
            {{ t("settings.messages.validate.orgname.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('orgName')?.hasError('maxlength')">
            {{ t("settings.messages.validate.orgname.maxlength") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgStreet") }}</mat-label>
          <input
            matInput
            formControlName="orgStreet"
            autocomplete="orgStreet"
            [placeholder]="'settings.form.orgStreet.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('orgStreet')?.hasError('required')">
            {{ t("settings.messages.validate.orgStreet.required") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgZipcode") }}</mat-label>
          <input
            matInput
            formControlName="orgZipcode"
            autocomplete="orgZipcode"
            [placeholder]="'settings.form.orgZipcode.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('orgZipcode')?.hasError('required')">
            {{ t("settings.messages.validate.orgZipcode.required") }}
          </mat-error>
          <mat-error
            *ngIf="
              registerForm.get('orgZipcode')?.hasError('minlength') ||
              registerForm.get('orgZipcode')?.hasError('maxlength')
            "
          >
            {{ t("settings.messages.validate.orgZipcode.length") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgPlace") }}</mat-label>
          <input
            matInput
            formControlName="orgPlace"
            autocomplete="orgPlace"
            [placeholder]="'settings.form.orgPlace.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('orgPlace')?.hasError('required')">
            {{ t("settings.messages.validate.orgPlace.required") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgCountry") }}</mat-label>
          <mat-select panelClass="wide-mat-select-pane" formControlName="orgCountry" [(value)]="selected" required>
            <mat-option [value]="'SWITZERLAND'">{{
              t("modelrealEstate.realEstate.general.country.SWITZERLAND")
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.orgPhone") }}</mat-label>
          <input
            matInput
            formControlName="orgPhone"
            autocomplete="orgPhone"
            [placeholder]="'settings.form.orgPhone.placeholder' | transloco"
          />
          <mat-error *ngIf="registerForm.get('orgPhone')?.hasError('required')">
            {{ t("settings.messages.validate.orgPhone.required") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('orgPhone')?.hasError('minlength')">
            {{ t("settings.messages.validate.orgPhone.minlength") }}
          </mat-error>
          <mat-error *ngIf="registerForm.get('orgPhone')?.hasError('maxlength')">
            {{ t("settings.messages.validate.orgPhone.maxlength") }}
          </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'" appearance="outline" class="numEmp">
          <mat-label>{{ t("settings.form.orgNumEmployees") }}</mat-label>
          <input
            matInput
            formControlName="orgNumEmployees"
            autocomplete="orgNumEmployees"
            [placeholder]="'settings.form.orgNumEmployees' | transloco"
          />
          <mat-error *ngIf="registerForm.get('orgNumEmployees')?.hasError('required')">
            {{ t("settings.messages.validate.orgNumEmployees.required") }}
          </mat-error>

          <mat-error *ngIf="registerForm.get('orgNumEmployees')?.hasError('pattern')">
            {{ t("settings.messages.validate.orgNumEmployees.required") }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="!registerForm.get('orgNumEmployees')?.invalid">
          <p class="help-block">
            <span data-translate="">
              {{ t("register.form.company.count-text") }}
            </span>
            <span> {{ registerForm.get("orgNumEmployees")?.value }} {{ t("register.form.company.persons") }} </span>
          </p>
        </div>
        <div class="kv-checkbox-wrapper">
          <label>{{ t("settings.form.orgSiaMember") }}</label>
          <mat-checkbox
            class="kv-checkbox"
            formControlName="orgSiaIndividualMember"
            [checked]="isSiaIndividualMember"
            (change)="onCheckboxToggle('isSiaIndividualMember', $event.checked)"
            >{{ t("register.form.sia.single-membership") }}</mat-checkbox
          >
          <mat-checkbox
            class="kv-checkbox"
            formControlName="orgSiaCorporateMember"
            [checked]="isSiaCorporateMember"
            (change)="onCheckboxToggle('isSiaCorporateMember', $event.checked)"
            >{{ t("register.form.sia.company-membership") }}</mat-checkbox
          >
          <div *ngIf="registerForm.get('orgSiaIndividualMember')?.dirty && isSiaIndividualMember">
            <p class="help-block" data-translate="">{{ t("settings.messages.validate.orgSiaIndividualMember.tip") }}</p>
          </div>
          <div *ngIf="registerForm.get('orgSiaCorporateMember')?.dirty && isSiaCorporateMember">
            <p class="help-block">{{ t("settings.messages.validate.orgSiaCorporateMember.tip") }}</p>
          </div>
        </div>
        <mat-form-field [floatLabel]="'always'" appearance="outline">
          <mat-label>{{ t("settings.form.additionalAccounts") }}</mat-label>
          <input
            matInput
            formControlName="additionalAccounts"
            autocomplete="Additional Accounts"
            [placeholder]="'settings.form.additionalAccounts' | transloco"
          />
        </mat-form-field>
        <div class="kv-help-wrapper">
          <p class="help-block">
            <span>{{ t("settings.form.additionalAccounts.description") }}</span>
          </p>
        </div>
        <div class="kv-checkbox-wrapper">
          <mat-checkbox
            class="kv-checkbox"
            (change)="isAddressSameAsBillingAddress($event)"
            formControlName="billingContactEqualsOrgContact"
            >{{ t("register.form.invoice-address") }}</mat-checkbox
          >
        </div>

        <div class="billling-wrapper" *ngIf="isBillingAddreessSame">
          <div class="centeredBoxText">
            <mat-card-subtitle>{{ t("register.form.billingContact.title-1") }}</mat-card-subtitle>
          </div>
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("settings.form.orgname") }}</mat-label>
            <input
              matInput
              formControlName="billingOrgName"
              autocomplete="orgPhone"
              [placeholder]="'settings.form.orgname.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('billingOrgName')?.hasError('required')">
              {{ t("settings.messages.validate.orgname.required") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingOrgName')?.hasError('minlength')">
              {{ t("settings.messages.validate.orgname.minlength") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingOrgName')?.hasError('maxlength')">
              {{ t("settings.messages.validate.orgname.maxlength") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="example-full-width" appearance="outline">
            <mat-label>{{ t("global.form.email") }}</mat-label>
            <input
              type="email"
              matInput
              formControlName="billingEmail"
              [placeholder]="'register.form.billingContact.email.placeholder' | transloco"
            />
            <!-- <mat-error *ngIf="billingEmail.hasError('email') && !billingEmail.hasError('required')">
              Please enter a valid email address
            </mat-error> -->
            <mat-error *ngIf="registerForm.get('billingEmail')?.hasError('required')">
              {{ t("register.form.billingContact.email.required") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingEmail')?.hasError('email')">
              {{ t("register.form.billingContact.email.invalid") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingEmail')?.hasError('minlength')">
              {{ t("register.form.billingContact.email.minlength") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingEmail')?.hasError('maxlength')">
              {{ t("register.form.billingContact.email.maxlength") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("register.form.billingContact.firstname.label") }}</mat-label>
            <input
              matInput
              formControlName="billingFirstName"
              autocomplete="billingFirstName"
              [placeholder]="'register.form.billingContact.firstname.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('billingFirstName')?.hasError('required')">
              {{ t("register.form.billingContact.firstname.required") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingFirstName')?.hasError('minlength')">
              {{ t("register.form.billingContact.firstname.minlength") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingFirstName')?.hasError('maxlength')">
              {{ t("register.form.billingContact.firstname.maxlength") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("register.form.billingContact.lastname.label") }}</mat-label>
            <input
              matInput
              formControlName="billingLastName"
              autocomplete="billingLastName"
              [placeholder]="'register.form.billingContact.lastname.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('billingLastName')?.hasError('required')">
              {{ t("register.form.billingContact.lastname.required") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingLastName')?.hasError('minlength')">
              {{ t("register.form.billingContact.lastname.minlength") }}
            </mat-error>
            <mat-error *ngIf="registerForm.get('billingLastName')?.hasError('maxlength')">
              {{ t("register.form.billingContact.lastname.maxlength") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("register.form.billingContact.billingDepartment.label") }}</mat-label>
            <input
              matInput
              formControlName="billingDepartment"
              autocomplete="billingDepartment"
              [placeholder]="'register.form.billingContact.billingDepartment.placeholder' | transloco"
            />
          </mat-form-field>

          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("settings.form.orgStreet") }}</mat-label>
            <input
              matInput
              formControlName="billingStreet"
              autocomplete="billingStreet"
              [placeholder]="'settings.form.orgStreet.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('billingStreet')?.hasError('required')">
              {{ t("settings.messages.validate.orgStreet.required") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("settings.form.orgZipcode") }}</mat-label>
            <input
              matInput
              formControlName="orgZipcodeBilling"
              autocomplete="orgZipcodeBilling"
              [placeholder]="'register.form.billingContact.orgZipcodeBilling.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('orgZipcodeBilling')?.hasError('required')">
              {{ t("settings.messages.validate.orgZipcode.required") }}
            </mat-error>
            <mat-error
              *ngIf="
                registerForm.get('orgZipcodeBilling')?.hasError('minlength') ||
                registerForm.get('orgZipcodeBilling')?.hasError('maxlength')
              "
            >
              {{ t("settings.messages.validate.orgZipcode.length") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" appearance="outline">
            <mat-label>{{ t("register.form.billingContact.orgPlaceBilling.label") }}</mat-label>
            <input
              matInput
              formControlName="orgPlaceBilling"
              autocomplete="orgPlaceBilling"
              minlength="1"
              maxlength="172"
              [placeholder]="'register.form.billingContact.orgPlaceBilling.placeholder' | transloco"
            />
            <mat-error *ngIf="registerForm.get('orgPlaceBilling')?.hasError('required')">
              {{ t("register.form.billingContact.orgPlaceBilling.required") }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="kv-checkbox-wrapper">
          <mat-checkbox
            class="kv-checkbox"
            formControlName="acceptConditions"
            (change)="checkAcceptedCondition($event)"
          >
            {{ t("register.form.AGB.text-1-0") }}
            <span
              ><a href="../../pdf/{{ '' | currentLanguage }}/AGB_keeValue_AG.pdf" target="_blank">{{
                t("register.form.AGB.text-1-1")
              }}</a>
              {{ t("register.form.AGB.text-1-2") }}</span
            >
          </mat-checkbox>
        </div>
        <div class="kv-checkbox-wrapper">
          <!-- <label>{{ t('settings.form.orgSiaMember') }}</label> -->
          <mat-checkbox
            class="kv-checkbox reg-checkbox"
            formControlName="acceptedNoAnonymous"
            (change)="checkNoAnonymous($event)"
          >
            <span>{{ t("register.form.ANONYMOUS") }}</span>
          </mat-checkbox>
        </div>
        <div class="centeredBoxText mb-30">
          <button type="submit " mat-button class="btn-elegant-black" color="primary">
            {{ t("register.form.button") }}
          </button>
        </div>
      </form>

      <div>
        <div
          *ngIf="numEmp < 51 && checkSuccess"
          class="alert kv-attention mt-3"
          [innerHtml]="t('register.messages.checkSuccess')"
        >
          {{ t("register.messages.checkSuccess") }}
        </div>
        <div
          *ngIf="numEmp > 50"
          class="alert kv-attention mt-3"
          [innerHtml]="t('register.messages.checkSuccess2')"
        ></div>
        <!--
        <div class="alert kw-attention" ng-show="vm.error" data-translate="register.messages.error.fail">
            <strong>Registration failed!</strong> Please try again later.
        </div> -->

        <!-- <div class="alert kw-attention" ng-show="vm.errorMissing" data-translate="register.messages.error.missing">
            <strong>Information missing!</strong> Please fill all fields.
        </div> -->

        <div
          class="alert kv-attention"
          *ngIf="errorUserExists"
          [innerHtml]="t('register.messages.error.userexists')"
        ></div>

        <div
          class="alert kv-attention"
          *ngIf="errorEmailExists"
          [innerHtml]="t('register.messages.error.emailexists')"
        ></div>

        <div
          class="alert kv-attention"
          *ngIf="errorInvalidEmail"
          [innerHtml]="t('register.messages.error.invalidemail')"
        ></div>

        <div
          class="alert kv-attention"
          *ngIf="errorOrgNameExists"
          [innerHtml]="t('register.messages.error.orgnameexists')"
        ></div>

        <div class="alert kv-attention" *ngIf="doNotMatch">
          {{ t("global.messages.error.dontmatch") }}
        </div>

        <div
          class="alert kv-attention"
          *ngIf="hasAcceptedConditions"
          [innerHtml]="t('register.messages.error.acceptconditions')"
        ></div>
        <div
          class="alert kv-attention"
          *ngIf="hasAcceptedNoAnonymous"
          [innerHtml]="t('register.messages.error.accept-no-anonymous')"
        ></div>
      </div>
    </div>
  </div>

  <!-- Custom License Part -->
  <!-- *ngIf="hasStartFreeMonth && checkSuccess && numEmp < 51" -->
  <div *ngIf="hasStartFreeMonth && checkSuccess && numEmp < 51">
    <div class="centeredBoxText rowBackgroundWhite spacing" data-offset="66">
      <h4 data-translate="register.form.module.title">Modulwahl</h4>
      <p data-translate="register.form.module.text-1">An welchen Modulen sind Sie interessiert?</p>
      <div class="flexBoxOuter transparent">
        <div class="flexBoxInner center-align">
          <div>
            <div class="check-wrapper">
              <mat-checkbox
                #bundleLCC
                class="kv-checkbox"
                id="chooseLCCBundle"
                name="chooseLCCBundle"
                value="LCC_PACKAGE"
                (change)="fetchBilling($event)"
                >{{ t("register.form.module.lcc_bundle") }}
              </mat-checkbox>
            </div>
            <div class="check-wrapper">
              <mat-checkbox
                #moduleBuilding
                [disabled]="bundleLCC.checked"
                class="kv-checkbox"
                id="chooseBuildingCosts"
                name="chooseBuildingCosts"
                value="BUILDING_COSTS_AND_DATES"
                (change)="fetchBilling($event)"
                >{{ t("register.form.module.building-costs") }}
              </mat-checkbox>
            </div>
            <div class="check-wrapper">
              <mat-checkbox
                #moduleRebuild
                [disabled]="bundleLCC.checked"
                class="kv-checkbox"
                id="chooseRebuildCosts"
                name="chooseRebuildCosts"
                value="REBUILD_COSTS"
                (change)="fetchBilling($event)"
                >{{ t("register.form.module.rebuild-costs") }}
              </mat-checkbox>
              <!-- <div style='text-align: right'>
                  <input type="checkbox" class="form-check-input" name="chooseRebuildCosts" id="chooseRebuildCosts"
                    ng-model="vm.chooseRebuildCosts">
                </div> -->
            </div>
            <div class="check-wrapper">
              <mat-checkbox
                #moduleOperating
                [disabled]="bundleLCC.checked"
                class="kv-checkbox"
                id="chooseOperatingCosts"
                name="chooseOperatingCosts"
                value="OPERATING_COSTS"
                (change)="fetchBilling($event)"
                >{{ t("register.form.module.operating-costs") }}
              </mat-checkbox>
              <!-- <div style='text-align: right'>
                  <input type="checkbox" class="form-check-input" name="chooseOperatingCosts" id="chooseOperatingCosts"
                    ng-model="vm.chooseOperatingCosts">
                </div> -->
            </div>
            <div class="check-wrapper">
              <mat-checkbox
                #moduleOperating
                [disabled]="true"
                class="kv-checkbox"
                id="chooseLCC"
                name="chooseLCC"
                title="{{ t('billing.module.module-only-by-bundle') }}"
                value="LCC"
                >{{ t("register.form.module.lcc") }}
              </mat-checkbox>
              <!-- <div style='text-align: right'>
                  <input type="checkbox" class="form-check-input" name="chooseOperatingCosts" id="chooseOperatingCosts"
                    ng-model="vm.chooseOperatingCosts">
                </div> -->
            </div>
          </div>
        </div>
      </div>
      <div>
        <p data-translate="register.form.module.info">
          Sie können die Modulauswahl während der Gratiswoche noch ändern
        </p>
      </div>
    </div>
  </div>

  <!--  *ngIf="license" -->
  <div class="" *ngIf="license">
    <div
      class="centeredBox flexBoxOuter rowBackgroundWhite spacing"
      ng-if="
        vm.hasStartFreeMonth && vm.checkSuccess && vm.organisation.numEmployees < 51 && vm.price.yearlyTotalInclVAT > 0
      "
    >
      <div class="centeredBoxText" ng-if="!vm.success">
        <h3 data-translate="register.form.license.title-1">{{ t("register.form.license.title-1") }}</h3>
      </div>

      <div class="flexBoxOuter">
        <div class="flexBoxInner">
          {{ t("register.form.license.text-1-0") }}
          <span data-translate="register.form.license.text-1-1" [innerHtml]="t('register.form.license.text-1-1')">
          </span>
        </div>
      </div>

      <!-- Subform specific license -->
      <div class="centeredBox flexBoxOuter rowBackgroundWhite" *ngIf="billingResponse">
        <div class="centeredBoxText" ng-if="vm.baseLicense">
          <h4 data-translate="register.form.license.title-2">{{ t("register.form.license.title-2") }}</h4>
          <div class="flexBoxOuter">
            <!-- <price-table organisation="vm.organisation" selected-withdrawal-product-types="vm.selectedWithdrawalProductTypes"
            num-additional-users="vm.numAdditionalUsers" base-license="vm.baseLicense" price="vm.price">
          </price-table> -->

            <div class="license-table-box mt-10">
              <table id="priceTable" class="table flexBoxInner">
                <tbody>
                  <tr class="solidBottomBorder">
                    <td class="text-align">{{ t("prices.price.yearly") }}</td>
                    <td class="text-align">{{ billingResponse.yearlyPriceBaseLicense / 100 | number: "1.2-2" }} CHF</td>
                  </tr>
                  <tr *ngIf="billingResponse.yearlyModule2Discount > 0">
                    <td class="text-align">{{ t("prices.discount.module-2") }}</td>
                    <td class="text-align">
                      - {{ billingResponse.yearlyModule2Discount / 100 | number: "1.2-2" }} CHF
                    </td>
                  </tr>
                  <tr *ngIf="billingResponse.yearlyModule3Discount > 0">
                    <td class="text-align">{{ t("prices.discount.module-3") }}</td>
                    <td class="text-align">
                      - {{ billingResponse.yearlyModule3Discount / 100 | number: "1.2-2" }} CHF
                    </td>
                  </tr>
                  <tr
                    *ngIf="billingResponse.yearlyModule4Discount != null && billingResponse.yearlyModule4Discount > 0"
                  >
                    <td class="text-align">{{ t("prices.discount.module-4") }}</td>
                    <td>
                      <div class="text-align">
                        - {{ billingResponse.yearlyModule4Discount / 100 | number: "1.2-2" }} CHF
                      </div>
                    </td>
                  </tr>
                  <ng-container *ngIf="isSiaIndividualMember">
                    <tr>
                      <td class="text-align">
                        {{ billingResponse.discountInPercentSiaIndividual | number: "1.0-0"
                        }}{{ t("prices.discount.sia-single") }}
                      </td>
                      <td class="text-align">- {{ billingResponse.yearlySiaDiscount / 100 | number: "1.2-2" }} CHF</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="isSiaCorporateMember">
                    <tr>
                      <td class="text-align">
                        {{ billingResponse.discountInPercentSiaCorporate | number: "1.0-0"
                        }}{{ t("prices.discount.sia-company") }}
                      </td>
                      <td class="text-align">- {{ billingResponse.yearlySiaDiscount / 100 }} CHF</td>
                    </tr>
                  </ng-container>
                  <tr>
                    <td class="text-align">{{ t("billing.table.pricing.price-no-vat") }}</td>
                    <td class="text-align">{{ billingResponse.yearlyTotalExclVAT / 100 | number: "1.2-2" }} CHF</td>
                  </tr>
                  <tr class="solidBottomBorder">
                    <td class="text-align">
                      {{ billingResponse.vat | number: "1.2-2" }}<span>{{ t("prices.mwst") }}</span>
                    </td>
                    <td class="text-align">{{ billingResponse.yearlyEffectiveVAT / 100 | number: "1.2-2" }} CHF</td>
                  </tr>
                  <tr class="solidBottomBorder">
                    <td class="text-align">
                      <b>{{ t("prices.licence.total-yearly-vat") }}</b>
                    </td>
                    <td class="text-align">
                      <b>{{ billingResponse.yearlyTotalInclVAT / 100 | number: "1.2-2" }} CHF</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="centeredBoxText" smooth-scroll data-duration="250" data-offset="0" *ngIf="registerComplete">
            <h3 data-translate="register.form.license.end-1">{{ t("register.form.license.end-1") }}</h3>
          </div>
          <div class="flexBoxOuter">
            <div class="centeredBoxText flexBoxInner mb-3">
              <p
                class="alert kv-attention"
                data-translate="register.form.abo-convert.text-1"
                [innerHtml]="t('register.form.abo-convert.text-1')"
              ></p>
            </div>
          </div>
          <div class="centeredBoxText mb-3">
            <div style="margin-bottom: 15px">
              <span data-translate="">{{ t("register.form.abo-convert.text-2") }}</span>
              <a href="mailto:{{ t('products.about.text-2-2') }}">{{ t("products.about.text-2-2") }}</a>
            </div>
          </div>
          <div class="centeredBoxText mt-3 mb-3" *ngIf="!registerComplete">
            <button mat-button color="primary" class="btn btn-elegant-black mb-3" (click)="register()">
              {{ t("register.form.finish") }}
            </button>
          </div>

          <div class="flexBoxOuter" *ngIf="registerComplete">
            <div
              class="alert kw-attention centeredBoxText flexBoxInner"
              *ngIf="registerComplete"
              [innerHtml]="t('register.messages.success')"
            ></div>
          </div>

          <!-- <div class="flexBoxOuter">
          <div class="alert alert-danger centeredBoxText flexBoxInner"
            ng-show="vm.errorPaymentFormError && !vm.errorPaymentFormAbort && !vm.errorPaymentFormSystemError"
            data-translate="register.messages.error.paymentformerror">
            <strong>Registration failed!</strong> Please try again later.
          </div>
        </div> -->

          <!-- <div class="flexBoxOuter">
          <div class="alert alert-danger centeredBoxText flexBoxInner"
            ng-show="vm.errorPaymentFormError && vm.errorPaymentFormAbort"
            data-translate="register.messages.error.paymentformabort">
            <strong>Registration aborted!</strong>
          </div>
        </div> -->

          <!-- <div class="flexBoxOuter">
          <div class="alert alert-danger centeredBoxText flexBoxInner"
            ng-show="vm.errorPaymentFormError && vm.errorPaymentFormSystemError"
            data-translate="register.messages.error.paymentformsystemerror">
            <strong>System not available!</strong>
          </div>
        </div> -->

          <!-- <div class="flexBoxOuter">
          <div class="alert kw-attention centeredBoxText flexBoxInner" ng-show="vm.success"
            data-translate="register.messages.success">
            <strong>Registration saved!</strong> Please check your email for confirmation.
          </div>
        </div> -->

          <!-- <div class="flexBoxOuter" ng-if="!vm.success && !vm.errorPaymentFormError" ng-show="vm.checkSuccess">
          <div class="flexBoxInner" style="margin-top:-10px;">
          </div>
        </div> -->
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="hasStartFreeMonth && checkSuccess && numEmp > 50" class="mb-20">
    <div class="flexBoxOuter pb-20">
      <div class="flexBoxInner txt" style="width: 450px">
        <div class="" smooth-scroll data-duration="250" data-offset="66">
          <h4 data-translate="register.form.to-big.title">{{ t("register.form.to-big.title") }}</h4>
          {{ t("register.form.to-big.text") }}
        </div>
        <div class="kv-inner-wrapper">
          <div class="cLabel md-block">
            <mat-form-field [floatLabel]="'always'" class="example-full-width" appearance="outline">
              <textarea
                #txtarea
                matInput
                cdkTextareaAutosize
                placeholder="{{ t('register.form.to-big.contact') }}"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                [(ngModel)]="message"
                [maxLength]="300"
                cdkAutosizeMaxRows="6"
              >
              </textarea>
              <mat-hint align="end">{{ txtarea.value.length || 0 }}/300</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="centeredBoxText" style="margin-top: 20px">
          <button
            mat-button
            type="button"
            color="primary"
            *ngIf="!contactSuccess"
            class="btn btn-elegant-orange"
            ng-disabled="vm.disableContactButton"
            style="padding-top: 0"
            (click)="sendContact()"
          >
            {{ t("global.request.send") }}
          </button>
          <p *ngIf="contactSuccess" class="alert kv-attention">
            {{ t("global.request.send.success") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
