import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

import { Logger } from '@shared';
import { CredentialsService } from './credentials.service';
import { AppRoute } from '../../generated/extended';
import { AuthenticationService } from '@app/auth/authentication.service';
import { map, Observable, of, skipWhile } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserDTO } from '../../generated/generatedEntities';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const routerData = route as AppRoute;
    const roles = routerData.data?.roles;
    return this.authenticationService.getAuthenticationState().pipe(
      skipWhile((value) => value == null),
      map((data: UserDTO | null) => {
        if (roles == null && data != null) {
          return true;
        }
        if (roles != null && this.authenticationService.hasAnyAuthority(roles)) {
          return true;
        }
        this.router.navigate(['/'], { replaceUrl: true });
        return false;
      }),
      catchError((err, caught) => {
        log.error('AuthGuard error catch', err);
        this.router.navigate(['/'], { replaceUrl: true });
        return of(false);
      }),
    );
  }
  canLoad(
    route: AppRoute,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routerData = route as AppRoute;
    const roles = routerData.data?.roles;
    return this.authenticationService.getAuthenticationState().pipe(
      skipWhile((value) => value == null),
      map((data: UserDTO | null) => {
        if (roles == null && data != null) {
          return true;
        }
        if (roles != null && this.authenticationService.hasAnyAuthority(roles)) {
          return true;
        }
        this.router.navigate(['/'], { replaceUrl: true });
        return false;
      }),
      catchError((err, caught) => {
        log.error('AuthGuard error catch', err);
        this.router.navigate(['/'], { replaceUrl: true });
        return of(false);
      }),
    );
  }
}
