import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable, ReplaySubject } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { RegisterDTO, UserDTO, WithdrawalProductType } from '../../generated/generatedEntities';
import { I18nService } from '@app/i18n';
import { Logger } from '@shared';
import { IframeOrgApiKey, RoleTypes } from '../../generated/extended';
import _ from 'lodash';
import { WptFreeTrackingService } from '@app/core/services/billing/wpt-free-tracking/wpt-free-tracking.service';
import { take, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

const log = new Logger('Auth');

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface JwtToken {
  id_token: string;
}

export interface PublicJwtToken {
  id_token: string;
  username: string;
}

export class Login {
  constructor(
    public username: string,
    public password: string,
    public rememberMe: boolean,
  ) {}
}

/**
 * Provides a base for authentication workflow.
 * It fetches the user information from the backend and stores it in the browser's local storage
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _account: UserDTO | null = null;
  private authenticated = false;
  private cache$: ReplaySubject<UserDTO | null> = new ReplaySubject<UserDTO | null>(1);

  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private i18nService: I18nService,
    private wptFreeTrackingService: WptFreeTrackingService,
  ) {
    log.debug('init');
    //check if we can get user.
    if (this.credentialsService.isAuthenticated()) {
      this.identity();
    }
    this.cache$.asObservable().subscribe({
      next: (value) => {
        if (value != null) {
          this.wptFreeTrackingService.queryByOrg(value.organisationId).subscribe({
            next: (value1) => {},
          });
        }
      },
    });
  }

  login(credentials: Login): Observable<void> {
    return this.http.post<JwtToken>(environment.serverUrl + '/api/authenticate', credentials).pipe(
      map((response) => {
        this.credentialsService.setCredentials(
          { username: credentials.username, token: response.id_token },
          credentials.rememberMe,
        );
        this.identity(true);
      }),
    );
  }

  getOrgApiKey(requestId: string) {
    return this.http.get<IframeOrgApiKey>(
      `${environment.serverUrl}/api/public/iframe/org_api_key?requestId=${requestId}`,
    );
    //return this.http.get<IframeOrgApiKey>(`/gateway-public/iframe_org_api_key?requestId=${requestId}`);
    // return this.http.get<OutRealEstateDTO>(`${environment.apiGatewayPublicUrl}/iframe_output?requestId=${id}`);
  }

  loginByApiToken(requestId: string): Observable<PublicJwtToken> {
    return this.http.get<PublicJwtToken>(`${environment.serverUrl}/api/public-authenticate/${requestId}`).pipe(
      take(1),
      tap((response) => {
        if (response) {
          this.credentialsService.setCredentials({ username: response.username, token: response.id_token }, false);
          this.identity(true);
        }
      }),
    );
  }

  logout(): Observable<void> {
    this._account = null;
    this.credentialsService.setCredentials();
    this.cache$.next(null);
    this.authenticated = false;
    return new Observable((observer) => {
      observer.complete();
    });
  }

  /**
   * It fetches the user information from the backend.
   * @param {boolean} [force] - boolean - If true, the cache will be ignored and the server will be queried.
   * @returns Observable<UserDTO>
   */
  identity(force?: boolean): Observable<UserDTO | null> {
    if (force || !this.authenticated) {
      this.cache$.next(null);
      this.fetch().subscribe({
        next: (user) => {
          log.info(user);
          this._account = user;
          if (this._account) {
            this.authenticated = true;
            if (this._account?.langKey) {
              const langKey = this._account.langKey;

              //this is needed so that the language is not changed when the user is logged in. if he follows the url keevalue.ch/fr.
              if (localStorage.getItem('block-lang-change') !== 'true') {
                //TODO remove '-CH' when backend delivers -CH.
                this.i18nService.language = langKey + '-CH';
              }
            }
            Sentry.setUser({ email: user.email, id: user.id });
            Sentry.setTag('page_locale', user.langKey);
            this.cache$.next(user);
          } else {
            log.info('user is null');
            Sentry.setUser({ email: '', id: '' });
            this.logout();
          }
        },
        error: (err) => {
          log.error(err);
        },
      });
    }
    return this.cache$.asObservable();
  }

  /**
   * @return True, if there is a current user and current user is authenticated, False otherwise.
   */
  isAuthenticated(): boolean {
    return !(this._account == null || !this.authenticated || !this._account || !this._account.authorities);
  }

  /**
   * If the user is not authenticated, or the user has no authorities, then return false. Otherwise, if the authorities
   * parameter is not an array, convert it to an array. Then, return true if the user has any of the authorities in the
   * array
   * @param {string[] | string} authorities - string[] | string
   * @returns A boolean value.
   */
  hasAnyAuthority(authorities: RoleTypes[] | RoleTypes): boolean {
    if (!this.isAuthenticated()) return false;

    return [authorities].flat().some((authority: string) => _.includes(this._account?.authorities, authority));
  }

  /**
   * It checks if the user has access to a module or any of the given modules
   * @param {WithdrawalProductType | WithdrawalProductType[]} modules - WithdrawalProductType | WithdrawalProductType[]
   * @returns A boolean value.
   */
  hasAnyModule(modules: WithdrawalProductType | WithdrawalProductType[]): boolean {
    if (!this.isAuthenticated()) return false;
    return [modules]
      .flat()
      .some((module: WithdrawalProductType) => _.includes(this._account?.withdrawalProductTypes, module));
  }

  /**
   * It checks if the user has access to a module or all given modules
   * Must have access to all given modules!
   * @param {WithdrawalProductType | WithdrawalProductType[]} modules - WithdrawalProductType | WithdrawalProductType[]
   * @returns A boolean value.
   */
  hasAllModule(modules: WithdrawalProductType | WithdrawalProductType[]): boolean {
    if (!this.isAuthenticated()) return false;

    return [modules]
      .flat()
      .every((module: WithdrawalProductType) => _.includes(this._account?.withdrawalProductTypes, module));
  }

  getAuthenticationState(): Observable<UserDTO | null> {
    return this.cache$.asObservable();
  }

  /**
   * get user data from backend
   * @private
   */
  private fetch(): Observable<UserDTO> {
    return this.http.get<UserDTO>(environment.serverUrl + '/api/account');
  }

  resetformDetails(resetForm: any): Observable<any> {
    return this.http.post(environment.serverUrl + '/api/account/reset_password/init', resetForm, {
      responseType: 'text',
    });
  }

  resetPasswordFinish(resetForm: any): Observable<any> {
    return this.http.post(environment.serverUrl + '/api/account/reset_password/finish', resetForm, {
      responseType: 'text',
    });
  }

  checkRegDetails(registerDTO: RegisterDTO): Observable<any> {
    return this.http.post(environment.serverUrl + '/api/register/check', registerDTO, { responseType: 'text' });
  }

  createAccount(registerDTO: RegisterDTO): Observable<any> {
    return this.http.post(environment.serverUrl + '/api/register', registerDTO, { responseType: 'text' });
  }

  save(user: UserDTO): Observable<string> {
    return this.http.post(environment.serverUrl + '/api/account', user, { responseType: 'text' });
  }

  savePassword(user: UserDTO): Observable<string> {
    return this.http.post(environment.serverUrl + '/api/account/change_password', user, { responseType: 'text' });
  }

  updateAccount(account: any, callback?: any) {
    // var cb = callback || angular.noop;
    // api inside
    return this.save(account);
    // function () {
    //  // return cb(account);
    // },
  }

  changePassword(newPassword: any, callback?: any) {
    // var cb = callback || angular.noop;

    return this.savePassword(newPassword);
    // function (err) {
    //     return cb(err);
    // }).$promise;
  }

  //return type is any on purpose otherwise it might not work properly (causing success and afterwards error in the same call).
  activate(key: string): Observable<HttpResponse<any>> {
    const params = new HttpParams().set('key', key);
    return this.http.get(environment.serverUrl + '/api/activate', {
      params,
      observe: 'response',
      responseType: 'json',
    });
  }
}
