<div *transloco="let t">
  <table id="differentialPriceTable" class="priceTable table flexBoxInner">
    <colgroup>
      <col style="width: 67%" />
      <col style="width: 33%" />
    </colgroup>
    <tbody>
      <tr *ngIf="additionalAccounts && additionalAccounts > 0">
        <td>{{ t("billing.table.new-users") }}</td>
        <td style="text-align: right">
          <div style="width: 90px">
            <span>{{ additionalAccounts }}</span>
          </div>
        </td>
      </tr>
      <tr *ngIf="baseLicenceDTO">
        <td style="text-align: left">
          {{
            t("prices.licence.base-licence-partial", {
              durationInMonths: differentialPrice.durationInMonths | number: "1.2-3"
            })
          }}
        </td>
        <td>
          <ng-template *ngIf="additionalWpts.indexOf('LCC_PACKAGE') > -1; then thenBlock; else elseBlock"></ng-template>
          <ng-template #thenBlock style="text-align: right">
            <div style="text-align: right">
              {{
                (differentialPrice.durationInMonths * differentialPrice.monthlyTotal) / 100 -
                  (activeWp.numMaxUsers != null && activeWp.numMaxUsers > 1
                    ? (differentialPrice.durationInMonths * differentialPrice.monthlyAdditionalAccounts) / 100
                    : 0) | number: "1.2-2"
              }}
              CHF
            </div>
          </ng-template>
          <ng-template #elseBlock>
            <div style="text-align: right" *ngIf="baseLicenceDTO">
              {{ (differentialPrice.durationInMonths * baseLicenceDTO.monthlyBaseFee) / 100 | number: "1.2-2" }} CHF
            </div>
          </ng-template>
        </td>
      </tr>

      <tr *ngIf="activeWp.numMaxUsers != null && activeWp.numMaxUsers > 1">
        <td style="text-align: left">
          {{ t("billing.formAdditionalAccounts.number.placeholder") }}: {{ activeWp.numMaxUsers - 1 }}
        </td>
        <td style="text-align: right">
          {{
            (differentialPrice.durationInMonths * differentialPrice.monthlyAdditionalAccounts) / 100 | number: "1.2-2"
          }}
          CHF
        </td>
      </tr>

      <tr class="solidBottomBorder">
        <td style="text-align: left">
          {{ t("billing.table.pricing.price-no-vat") }} ({{ licenseDurationInDays }}/365)
        </td>
        <td style="text-align: right">{{ differentialPrice.yearlyPriceBaseLicense / 100 | number: "1.2-2" }} CHF</td>
      </tr>

      <tr *ngIf="differentialPrice.yearlyModule2Discount > 0">
        <td style="text-align: left">{{ t("prices.discount.module-2") }}</td>
        <td>
          <div style="text-align: right">
            - {{ differentialPrice.yearlyModule2Discount / 100 | number: "1.2-2" }} CHF
          </div>
        </td>
      </tr>
      <tr *ngIf="differentialPrice.yearlyModule3Discount > 0">
        <td style="text-align: left">{{ t("prices.discount.module-3") }}</td>
        <td>
          <div style="text-align: right">
            - {{ differentialPrice.yearlyModule3Discount / 100 | number: "1.2-2" }} CHF
          </div>
        </td>
      </tr>
      <tr *ngIf="differentialPrice.yearlyModule4Discount > 0">
        <td style="text-align: left">{{ t("prices.discount.module-4") }}</td>
        <td>
          <div style="text-align: right">
            - {{ differentialPrice.yearlyModule4Discount / 100 | number: "1.2-2" }} CHF
          </div>
        </td>
      </tr>
      <!--      if yearlySiaDiscount is 0 then the user already used the whole discount on the already running license.-->
      <tr *ngIf="organisation.siaIndividualMember && differentialPrice.yearlySiaDiscount > 0">
        <td style="text-align: left">
          {{ differentialPrice.discountInPercentSiaIndividual | number: "1.2-2"
          }}<span>{{ t("prices.discount.sia-single") }}</span>
        </td>
        <td>
          <div style="text-align: right">- {{ differentialPrice.yearlySiaDiscount / 100 | number: "1.2-2" }} CHF</div>
        </td>
      </tr>
      <tr *ngIf="organisation.siaCorporateMember && differentialPrice.yearlySiaDiscount > 0">
        <td style="text-align: left">
          {{ differentialPrice.discountInPercentSiaCorporate | number: "1.2-2"
          }}<span>{{ t("prices.discount.sia-company") }}</span>
        </td>
        <td>
          <div style="text-align: right">- {{ differentialPrice.yearlySiaDiscount / 100 | number: "1.2-2" }} CHF</div>
        </td>
      </tr>

      <tr>
        <td style="text-align: left">{{ t("prices.licence.subtotal") }}</td>
        <td style="text-align: right">
          {{
            (differentialPrice.yearlyTotalExclVAT +
              (differentialPrice.yearlyTotalExclVAT2 != null && differentialPrice.yearlyTotalExclVAT2 > 0
                ? differentialPrice.yearlyTotalExclVAT2
                : 0)) /
              100 | number: "1.2-2"
          }}
          CHF
        </td>
      </tr>

      <tr>
        <td style="text-align: left">
          {{ differentialPrice.vat | number: "1.2-2" }}<span>{{ t("prices.mwst") }}</span>
          <!--<span
            *ngIf="
              differentialPrice.vat2 != null &&
              differentialPrice.vat2 > 0 &&
              differentialPrice.daysVat != null &&
              differentialPrice.daysVat2 != null
            "
            >({{ differentialPrice.daysVat }}/{{ differentialPrice.daysVat + differentialPrice.daysVat2 }})</span
          >-->
        </td>
        <td>
          <div style="text-align: right">{{ differentialPrice.yearlyEffectiveVAT / 100 | number: "1.2-2" }} CHF</div>
        </td>
      </tr>
      <tr
        *ngIf="
          differentialPrice.vat2 != null && differentialPrice.vat2 > 0 && differentialPrice.yearlyEffectiveVAT2 != null
        "
      >
        <td style="text-align: left">
          {{ differentialPrice.vat2 | number: "1.2-2" }}<span>{{ t("prices.mwst") }}</span>
          <!-- <span
            *ngIf="
              differentialPrice.vat2 != null &&
              differentialPrice.vat2 > 0 &&
              differentialPrice.daysVat != null &&
              differentialPrice.daysVat2 != null
            "
            >({{ differentialPrice.daysVat2 }}/{{ differentialPrice.daysVat + differentialPrice.daysVat2 }})</span
          >-->
        </td>
        <td>
          <div style="text-align: right">{{ differentialPrice.yearlyEffectiveVAT2 / 100 | number: "1.2-2" }} CHF</div>
        </td>
      </tr>
      <tr class="solidTopBorder solidBottomBorder">
        <td>
          <b data-translate="billing.table.pricing.price-vat">{{ t("billing.table.pricing.price-vat") }}</b>
        </td>
        <td>
          <div style="text-align: right">
            <b
              *ngIf="
                differentialPrice.vat2 != null &&
                  differentialPrice.vat2 > 0 &&
                  differentialPrice.yearlyTotalFlooredInclVAT;
                then thenBlock;
                else elseBlock
              "
            >
            </b>
            <!--          ! at yearlyTotalFlooredInclVAT is needed as compiler doesn't see it connected to the ngif above. -->
            <ng-template #thenBlock
              >{{ differentialPrice.yearlyTotalFlooredInclVAT! / 100 | number: "1.2-2" }} CHF</ng-template
            >
            <ng-template #elseBlock>{{ differentialPrice.yearlyTotalInclVAT / 100 | number: "1.2-2" }} CHF</ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
