<table class="moduleTable table flexBoxInner" style="margin-bottom: 10px; max-width: 610px">
  <thead>
    <tr>
      <th style="text-align: left"></th>
      <th style="text-align: left"></th>
      <th style="text-align: left"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td style="width: 50%; text-align: left" ng-switch="numAdditionalUsers === 1">
        <span>Anzahl Abfragen</span>
      </td>
      <td class="priceTd2">
        <div style="text-align: left">
          {{ activeWp.numMaxEstimations - 1 }}
        </div>
      </td>
      <td class="priceTd3"></td>
    </tr>
    <tr>
      <td style="width: 50%; text-align: left" ng-switch="numAdditionalUsers === 1">
        <span>Zusätzliche Accounts</span>
      </td>
      <td class="priceTd2">
        <div style="text-align: left">
          {{ activeWp.numMaxUsers - 1 }}
        </div>
      </td>
      <td class="priceTd3"></td>
    </tr>

    <tr>
      <td style="width: 50%; text-align: left">Jährliche Gebühr exkl. MWST</td>
      <td class="priceTd2">
        <div style="text-align: left">{{ price.yearlyTotalExclVAT / 100 | number: "1.2-2" }} CHF</div>
      </td>
      <td class="priceTd3"></td>
    </tr>
    <tr>
      <td style="width: 50%; text-align: left">{{ price.vat | number: "2" }}% MWST</td>
      <td class="priceTd2">
        <div style="text-align: left">{{ price.yearlyEffectiveVAT / 100 | number: "1.2-2" }}CHF</div>
      </td>
      <td class="priceTd3"></td>
    </tr>
    <tr>
      <td style="width: 50%; text-align: left">
        <b>Jährliche Gebühr inkl. MWST</b>
      </td>
      <td class="priceTd2">
        <div style="text-align: left">
          <b>{{ price.yearlyTotalInclVAT / 100 | number: "1.2-2" }} CHF</b>
        </div>
      </td>
      <td class="priceTd3"></td>
    </tr>
  </tbody>
</table>
