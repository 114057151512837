// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// also add env in angular.json file (search for production) in there are the builds defined
import { env } from './.env';

export const environment = {
  production: true,
  version: env['npm_package_version'],
  serverUrl: 'https://feature.backend.keevalue.ch',
  gisMicroserviceUrl: 'https://kv-gis-dev-hugrivdvhq-oa.a.run.app',
  apiGatewayPublicUrl: 'https://dev-v1.api.keevalue.ch',
  kvPlotService: 'https://kv-plot-renderer-test--1-0-0-hugrivdvhq-oa.a.run.app',
  defaultLanguage: 'de-CH',
  supportedLanguages: ['de-CH', 'fr-CH', 'it-CH'],
  sentry_dsn: '',
  profile: 'feature',
};
