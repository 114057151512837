import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { WithdrawalProductType } from '../../generated/generatedEntities';
import { AuthenticationService } from '@app/auth/authentication.service';

@Directive({
  selector: '[kvHasModule]',
})
export class HasModuleDirective {
  private modules: WithdrawalProductType[];

  constructor(
    private authenticationService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.modules = [];
  }

  @Input()
  set kvHasModule(value: WithdrawalProductType | WithdrawalProductType[]) {
    if (Array.isArray(value)) {
      this.modules = value;
    } else {
      this.modules = [value];
    }
    this.updateView();
    // Get notified each time authentication state changes.
    this.authenticationService.getAuthenticationState().subscribe((user) => this.updateView());
  }

  private updateView(): void {
    const hasAnyModule = this.authenticationService.hasAnyModule(this.modules);
    this.viewContainerRef.clear();
    if (hasAnyModule) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
