import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';
import {
  AdditionEntryDTO,
  AreaAndVolume416Dto,
  FavoriteDto,
  OutRealEstateDTO,
  RealEstateDTO,
  RealEstateReferenceDTO,
  RealEstatesPageableDTO,
  RealEstateUsageEmbeddable,
  RebuildEstateDTO,
  WithdrawalProductType,
  WithdrawDTO,
} from 'src/generated/generatedEntities';
import { Logger } from '@shared';
import { IframeCallBack, IframeInput, NullableRealEstateDTO, PartialNullable } from '@generated/extended';
import { SharedControllerFnService } from '../shared/shared-controller-fn.service';
import locationJson from '../../../../assets/resources_generic/location.json';
import _ from 'lodash';

export interface TotalEstimatedProjects {
  cnt: number;
}

export interface ZipCodeTown {
  location: string;
  plz: number;
  canton: string;
  gmd_nr: number;
}

const log = new Logger('RealEstateService');

@Injectable({
  providedIn: 'root',
})
export class RealEstateService {
  isDataLoadedResponse$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  // public realEstateContainer = {} as RealEstateDTO;
  private _realEstateContainer: RealEstateDTO | RebuildEstateDTO | null = null;

  set realEstateContainer(value: RealEstateDTO | RebuildEstateDTO | null) {
    console.log(value, this._realEstateContainer);
    this._realEstateContainer = value;
  }

  getRealEstate() {
    return this._realEstateContainer;
  }

  constructor(
    private http: HttpClient,
    private SharedControllerFnService: SharedControllerFnService,
  ) {}

  getEmptyRealEstate(wtp: WithdrawalProductType): Observable<RealEstateDTO> {
    return this.http
      .post<RealEstateDTO>(`${environment.serverUrl}/api/realestates/empty`, {
        parentRealEstateId: null,
        name: null,
        withdrawalProductType: wtp == null ? 'BUILDING_COSTS_AND_DATES' : wtp,
      })
      .pipe(
        map((value, index) => {
          this._realEstateContainer = value;
          this.prepareRealEstateEmptyObject(value, wtp);
          return this._realEstateContainer;
        }),
        // map((value: RealEstateDTO) => {
        //   // this.realEstateContainer = value;
        // })
      );
  }

  getRealEstateById(id: number): Observable<OutRealEstateDTO> {
    return this.http.get<OutRealEstateDTO>(`${environment.serverUrl}/api/realestates/${id}`);
  }

  deleteRealEstateById(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${environment.serverUrl}/api/realestates/${id}`, { observe: 'response' });
  }

  /**
   * Sample payload: {
   *       admin: false,
   *       favorites: ['NO_FAVORITE'],
   *       filterUsage: "",
   *       isReference: false,
   *       isSearchRequest: false,
   *       page: 0,
   *       searchName: "",
   *       showDataSameOrg: false,
   *       showPublicObject: false,
   *       size: 10,
   *       sort: ['LAST_MODIFIED_DATE;desc'],
   *       withdrawalProductTypes: ['BUILDING_COSTS_AND_DATES']
   *     } as PartialNullable<RealEstatesPageableDTO>
   * @param page
   */
  getRealEstatesList(page: PartialNullable<RealEstatesPageableDTO>): Observable<HttpResponse<OutRealEstateDTO[]>> {
    const cast = page as RealEstatesPageableDTO;
    const options = this.createRequestOption(cast);
    return this.http.get<OutRealEstateDTO[]>(`${environment.serverUrl}/api/realestates`, {
      params: options,
      observe: 'response',
    });
  }

  getRealEstatesReferenceList(
    page: PartialNullable<RealEstatesPageableDTO>,
  ): Observable<HttpResponse<OutRealEstateDTO[]>> {
    const cast = page as RealEstatesPageableDTO;
    const options = this.createRequestOption(cast);
    return this.http.get<OutRealEstateDTO[]>(`${environment.serverUrl}/api/realestates-reference`, {
      params: options,
      observe: 'response',
    });
  }

  setFavorite(favoriteDto: FavoriteDto): Observable<FavoriteDto> {
    return this.http.post<FavoriteDto>(`${environment.serverUrl}/api/realestates/set_favorite`, favoriteDto);
  }

  checkRealEstate(realEstate: NullableRealEstateDTO | RealEstateDTO | RealEstateReferenceDTO): Observable<string> {
    // Need to review
    return this.http.post<string>(
      `${environment.serverUrl}/api/withdrawal/building_metrics_estimation/check_realestate`,
      realEstate,
    );
  }

  estimateBuildingMetrics(realEstate: NullableRealEstateDTO | RealEstateDTO): Observable<WithdrawDTO> {
    return this.http.post<WithdrawDTO>(
      `${environment.serverUrl}/api/withdrawal/building_metrics_estimation`,
      realEstate,
    );
  }

  estimateAreaOrVolume(realEstate: RealEstateDTO): Observable<AreaAndVolume416Dto> {
    return this.http.post<AreaAndVolume416Dto>(
      `${environment.serverUrl}/api/withdrawal/area_and_volume_416/estimate`,
      realEstate,
    );
  }

  /**
   * gets the current count of calculated projects with keeValue.
   */
  getCounterNumber(): Observable<TotalEstimatedProjects> {
    return this.http.get<TotalEstimatedProjects>(`${environment.serverUrl}/api/realestates/cnt_total_num_objects`);
  }

  getZipCode(): ZipCodeTown[] {
    //return this.http.get<ZipCodeTown[]>('assets/resources_generic/location.json');
    return locationJson;
  }

  /* have to check for these validations before doing the OG calculations :: => STARTS*/
  tryServerValidation(realEstate: any): Observable<string> {
    //before this is called clean DTO then do client side validation
    //then this is called - if all good then call calculate.
    var realEstateContainerCopy = _.cloneDeep(realEstate); // MAKING A COPY OF THE REALESTATE OBJ
    this.SharedControllerFnService.cleanDto(realEstateContainerCopy); // CLEANING THE DTO
    // this.testEstimation!.metaData!.name = `test estimation ${this.genrateRandomNumber(999, 9999)}`;
    return this.checkRealEstate(realEstateContainerCopy);
  }

  /* have to check for these validations before doing the OG calculations :: => ENDS*/

  tryCalculation(realEstat: any): Observable<WithdrawDTO> {
    return this.estimateBuildingMetrics(realEstat);
  }

  createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
      Object.keys(req).forEach((key) => {
        if (key !== 'sort') {
          options = options.set(key, req[key]);
        }
      });
      if (req.sort) {
        req.sort.forEach((val: any) => {
          options = options.append('sort', val);
        });
      }
    }
    return options;
  };

  getCalculateResponse(): Observable<any | null> {
    return this.isDataLoadedResponse$.asObservable();
  }

  endsWith(str: any, suffix: any) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  postProcessForEditRecordById(realEstate: any): Observable<RealEstateDTO | RebuildEstateDTO> {
    if (realEstate.id == null) {
      return of({} as RealEstateDTO | RebuildEstateDTO);
    }
    realEstate.metaData.parentRealEstateId = realEstate.metaData.parentRealEstateId
      ? realEstate.metaData.parentRealEstateId
      : realEstate.id;
    if (this.endsWith(realEstate.metaData.name, ')')) {
      var brBegin = realEstate.metaData.name.lastIndexOf('(');
      if (brBegin >= 0) {
        var txtBetweenBr = realEstate.metaData.name.substring(brBegin + 1, realEstate.metaData.name.length - 1);
        var parsedNumBetweenBr = parseInt(txtBetweenBr);
        if (parsedNumBetweenBr) {
          realEstate.metaData.name =
            realEstate.metaData.name.substring(0, brBegin - 1) + ' (' + (realEstate.metaData.numEstimations + 1) + ')';
        }
      }
    } else {
      realEstate.metaData.name = realEstate.metaData.name + ' (' + (realEstate.metaData.numEstimations + 1) + ')';
    }
    realEstate.id = null;
    if (realEstate.additions.length === 0) {
      realEstate.additions = [];
      realEstate.additions.push({});
    }
    if (realEstate.investments.length === 0) {
      realEstate.investments = [];
      realEstate.investments.push({});
    }
    this._realEstateContainer = realEstate;
    return of(this._realEstateContainer as RealEstateDTO | RebuildEstateDTO).pipe(take(1));
  }

  update(realEstateContainer: any): Observable<any> {
    return this.http.put(`${environment.serverUrl}/api/realestates`, realEstateContainer);
  }

  prepareRealEstateEmptyObject(realEstateObj: RealEstateDTO, wtp: WithdrawalProductType) {
    //this.rebuildEstate = JSON.parse(JSON.stringify(createdRebuildEstate));
    // rebuildObj.usages.push({
    //   type: null,
    //   percentage: null,
    //   standard: null,
    //   numFuUsage: null,
    // } as RealEstateUsageEmbeddable);

    switch (wtp) {
      case 'OPERATING_COSTS':
        realEstateObj.additions.length = 0;
        realEstateObj.additions.push({} as AdditionEntryDTO);
        break;

      case 'INSURANCE_VALUES_GVBS':
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: null,
        } as AdditionEntryDTO);
        break;

      default:
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: 'BKP_6_RESERVE',
        } as AdditionEntryDTO);
        realEstateObj.additions.push({
          label: '',
          bkp: null,
        } as AdditionEntryDTO);
    }
    if (realEstateObj.usages == null) {
      realEstateObj.usages = [];
    }
    if (realEstateObj.usages.length === 0) {
      realEstateObj.usages.push({
        type: null,
        standard: null,
        percentage: null,
        numFuUsage: null,
      } as RealEstateUsageEmbeddable);
    }
  }

  getRealEstateByRequestId(requestId: string) {
    //return this.http.get<IframeInput>(`/gateway-public/iframe_output?requestId=${id}`);
    return this.http
      .get<IframeInput>(`${environment.serverUrl}/api/public/iframe/retrieve_input?requestId=${requestId}`)
      .pipe(
        map((value, index) => {
          this.prepareRealEstateEmptyObject(value, value.module);
          return value;
        }),
      );
    // return this.http.get<OutRealEstateDTO>(`${environment.apiGatewayPublicUrl}/iframe_output?requestId=${id}`);
  }

  sendResultBack(result: IframeCallBack) {
    return this.http.post<IframeInput>(`${environment.serverUrl}/api/public/iframe/send_result_to_callback`, result);
    //return this.http.post<void>(url, result);
  }
}
