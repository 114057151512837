import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-license-table-tpl',
  templateUrl: './license-table-tpl.component.html',
  styleUrls: ['./license-table-tpl.component.scss'],
})
export class LicenseTableTplComponent implements OnInit {
  @Input() activewp: any;
  @Input() basecontainer: any;
  @Input() baselicense: any;
  @Input() freewpt: any;
  @Input() organisation: any;
  @Input() price: any;
  @Input() wptfreetracking: any;
  activeWpts: any;
  constructor() {}

  ngOnInit(): void {
    this.activeWpts = this.fetchActiveWpts();
  }

  fetchActiveWpts() {
    // exclude inactive (but probably still usable) modules
    var inactiveWpts = this.basecontainer.wptFreeTracking
      .filter((track: any) => {
        return !!track.dateTimeInactive;
      })
      .map((track: any) => {
        return track.wpt;
      });
    let ret = this.basecontainer.activeWp.filter((x: any) => !inactiveWpts.includes(x));
    //console.log('inactiveWpts',difference)
    // var ret = _.difference(this.basecontainer.activeWp.withdrawalProductTypes - inactiveWpts);
    return ret;
  }
}
