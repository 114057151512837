import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { MaterialModule } from '@app/material.module';
import { LanguageSelectorComponent } from './language-selector.component';
import { CurrentLanguagePipe } from './current-language.pipe';

@NgModule({
  imports: [CommonModule, TranslocoModule, MaterialModule],
  declarations: [LanguageSelectorComponent, CurrentLanguagePipe],
  exports: [LanguageSelectorComponent, CurrentLanguagePipe],
})
export class I18nModule {}
