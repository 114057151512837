import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WithdrawalProductDTO } from '@generated/generatedEntities';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalProductService {
  constructor(private _http: HttpClient) {}

  update(): Observable<any> {
    // TBC
    return this._http.put(environment.serverUrl + '/api/withdrawal_products', {});
  }

  access(id: any): Observable<any> {
    // TBC
    return this._http.get(environment.serverUrl + '/api/withdrawal_products/' + id);
  }

  adminUpdate(orgId: any): Observable<WithdrawalProductDTO> {
    return this._http.put<WithdrawalProductDTO>(environment.serverUrl + '/api/withdrawal_products/admin_update', orgId);
  }

  actives_for_admin(userLogin: any): Observable<WithdrawalProductDTO[]> {
    return this._http.get<WithdrawalProductDTO[]>(
      environment.serverUrl + '/api/withdrawal_products/actives_for_admin/' + userLogin,
    );
  }

  current_for_admin(orgId: any): Observable<WithdrawalProductDTO> {
    return this._http.get<WithdrawalProductDTO>(
      environment.serverUrl + '/api/withdrawal_products/current_for_admin' + '?orgId=' + orgId.orgId,
    );
  }

  pending_for_admin(orgId: any): Observable<WithdrawalProductDTO> {
    // TBC
    return this._http.get<WithdrawalProductDTO>(
      environment.serverUrl + '/api/withdrawal_products/pending_for_admin' + '?orgId=' + orgId.orgId,
    );
  }

  current_for_user(): Observable<WithdrawalProductDTO> {
    return this._http.get<WithdrawalProductDTO>(environment.serverUrl + '/api/withdrawal_products/current_for_user');
  }

  current_pending_for_user(): Observable<WithdrawalProductDTO> {
    return this._http.get<WithdrawalProductDTO>(
      environment.serverUrl + '/api/withdrawal_products/current_pending_for_user',
    );
  }

  query_by_user(userLogin: any): Observable<WithdrawalProductDTO[]> {
    return this._http.get<WithdrawalProductDTO[]>(
      environment.serverUrl + '/api/withdrawal_products/query_by_user/' + userLogin,
    );
  }

  query_pending(): Observable<WithdrawalProductDTO> {
    return this._http.get<WithdrawalProductDTO>(environment.serverUrl + '/api/withdrawal_products/pending');
  }

  query_pending_by_user(userLogin: any): Observable<WithdrawalProductDTO> {
    return this._http.get<WithdrawalProductDTO>(
      environment.serverUrl + '/api/withdrawal_products/pending_by_user/' + userLogin,
    );
  }
}
