import { Component, Input, OnInit } from '@angular/core';
import {
  BaseLicenseDTO,
  BillingPriceDTO,
  OrganisationDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
} from '@generated/generatedEntities';
import * as date_fns from 'date-fns';
import { take } from 'rxjs';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';

@Component({
  selector: 'app-differential-price-table',
  templateUrl: './differential-price-table.component.html',
  styleUrls: ['./differential-price-table.component.scss'],
})
export class DifferentialPriceTableComponent implements OnInit {
  @Input() organisation!: OrganisationDTO;
  @Input() isDueNow!: boolean;
  @Input() activeWp!: WithdrawalProductDTO;
  @Input() additionalWpts!: WithdrawalProductType[];
  @Input() numberOfCurrentWpts!: number;
  @Input() additionalAccounts: any;
  @Input() differentialPrice!: BillingPriceDTO;

  licenseDurationInDays!: number;

  baseLicenceDTO?: BaseLicenseDTO;
  constructor(private baseLicenceService: BaseLicenseService) {}

  ngOnInit(): void {
    this.initializeData();
    this.licenseDurationInDays = date_fns.differenceInDays(
      date_fns.parseISO(new Date(this.activeWp.dateTimeValidUntil).toISOString()),
      date_fns.parseISO(new Date().toISOString()),
    );
  }

  initializeData() {
    this.baseLicenceService
      .fetchBaseLicenseDetails({ id: this.activeWp.baseLicenseId })
      .pipe(take(1))
      .subscribe({
        next: (baseLicensesPrice) => {
          if (baseLicensesPrice) {
            this.baseLicenceDTO = baseLicensesPrice;
          } else {
            this.baseLicenceDTO = undefined;
          }
        },
      });
  }
}
