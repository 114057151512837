import { Component, OnInit } from '@angular/core';
import { PaymentPdfService } from '@app/core/services/billing/payment-pdf/payment-pdf.service';
import { PaymentService } from '@app/core/services/billing/payment/payment.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as FileSaver from 'file-saver';
@UntilDestroy()
@Component({
  selector: 'app-billing-history-free',
  templateUrl: './billing-history-free.component.html',
  styleUrls: ['./billing-history-free.component.scss'],
})
export class BillingHistoryFreeComponent implements OnInit {
  payments: any | null;
  constructor(
    private translateService: TranslocoService,
    private paymentPdfService: PaymentPdfService,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.paymentService
      .query()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (response) {
            response.forEach((p: any) => {
              if (this.payments == null) {
                this.payments = [];
              }
              p.translatedStatus = this.translateStatus(p.status);
              this.payments.push(p);
            });
          }
        },
        error: (error) => {
          console.error('Failed to receive payments', error);
        },
      });
  }

  downloadPaymentPdf(payment: any) {
    var namePrefix: string = '';
    if ('INVOICE' === payment.paymentMeans) {
      namePrefix = 'kennwerte Rechnung ';
    } else {
      namePrefix = 'kennwerte Kaufbeleg ';
    }
    this.paymentPdfService
      .downloadPaymentPdf(payment.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          let pdfName = namePrefix + payment.confirmationNr + '.pdf';
          var data = new Blob([response.data], { type: 'application/pdf;charset=UTF-8' });
          FileSaver.saveAs(data, pdfName);
        },
      });
  }

  translateStatus(status: any) {
    try {
      return this.translateService.translate('billing.history.transactionStatus.' + status);
    } catch (e) {
      console.warn('transactionStatus', e);
      return;
    }
  }
}
