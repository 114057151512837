import { Injectable, Optional, SkipSelf } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, of, skipWhile } from 'rxjs';
import { AuthenticationService } from '@app/auth/authentication.service';
import { Logger } from '@shared';
import { AppRoute } from 'src/generated/extended';

const log = new Logger('HasModuleGuard');

@Injectable({
  providedIn: 'root',
})
export class HasModuleGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    @Optional() @SkipSelf() parent?: HasModuleGuard,
  ) {
    // log.error('init');
    if (parent) {
      throw Error(
        `[GuardedSingletonService]: trying to create multiple instances,
        but this service should be a singleton.`,
      );
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routerData = route as AppRoute;
    return this.hasModule(routerData);
  }

  canLoad(
    route: AppRoute,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasModule(route);
  }

  private hasModule(route: AppRoute) {
    const routerData = route as AppRoute;
    if (routerData.data?.module != null || routerData.data?.modules != null) {
      return this.authenticationService.getAuthenticationState().pipe(
        skipWhile((value) => value == null),
        // debounceTime(0),
        // take(1),
        map((value) => {
          if (value != null) {
            log.warn('access to module?: ', this.authenticationService.hasAnyModule(routerData.data!.module!), value);
            if (routerData.data?.module != null) {
              return this.authenticationService.hasAnyModule(routerData.data!.module!);
            } else {
              log.warn(
                'access to modules?: ',
                this.authenticationService.hasAnyModule(routerData.data!.modules!),
                value,
              );
              return this.authenticationService.hasAnyModule(routerData.data!.modules!);
            }
          } else {
            log.warn('no access to module: ', value, routerData!.data!.module);
            return false;
          }
        }),
        // tap({
        //   next: (value) => {
        //     if (!value) {
        //       return of(this.authenticationService.hasAnyModule(routerData.data!.module!));
        //     } else {
        //       return of(true);
        //     }
        //   },
        //   error: (error) => {
        //     return of(false);
        //   },
        // })
      );
    } else {
      log.error('no modules for access given');
      // this.router.navigate([''], { replaceUrl: true });
      return of(false);
    }
  }
}
