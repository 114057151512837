<div *transloco="let t">
  <div class="pageId_Billing">
    <app-dynamic-image [mode]="'BOOTSTRAP'">
      <div class="bootstrap-container">
        <div class="centeredBoxText bannerTextBox">
          <h1 class="bannerTitle" style="white-space: nowrap" translate="billing.title" style="white-space: nowrap">
            {{ t("global.menu.account.billing") }}
          </h1>
        </div>
      </div>
    </app-dynamic-image>

    <ng-container *ngIf="licenseState === 'FREE'">
      <app-billing-free (licenceChanged)="fetchWithdrawalProduct()"></app-billing-free>
    </ng-container>
    <ng-container *ngIf="licenseState === 'PAYED'">
      <app-billing-payed (licenceChanged)="fetchWithdrawalProduct()"></app-billing-payed>
    </ng-container>
    <ng-container *ngIf="licenseState === 'PAYMENT_UNSUCCESSFUL'">
      <app-billing-payment-unsuccessful (licenceChanged)="fetchWithdrawalProduct()"></app-billing-payment-unsuccessful>
    </ng-container>
    <ng-container *ngIf="licenseState === 'INVALID'"> <app-billing-invalid></app-billing-invalid> </ng-container>

    <br />
    <br />
  </div>
</div>
