import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WithdrawalProductType } from '@generated/generatedEntities';
@UntilDestroy()
@Component({
  selector: 'app-bewk',
  templateUrl: './bewk.component.html',
  styleUrls: ['./bewk.component.scss'],
})
export class BewkComponent implements OnInit {
  @Input() isAuthenticated!: boolean;
  showBuildingCosts: boolean = true;
  showInsuranceValues: boolean = false;
  isAdmin: boolean = false;
  @Input() hasOperationalCosts: boolean = false;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  constructor(
    private videoDetailService: VideoDetailsService,
    private router: Router,
    public credentialsService: CredentialsService,
  ) {}

  ngOnInit(): void {}

  openModal() {
    this.openBillingModal.emit('OPERATING_COSTS');
  }

  openVideoPom() {
    this.videoDetailService.getTeaserVideoPom();
  }

  goToOperationalForm() {
    this.router.navigate(['/operational-form/input']);
  }
}
