import { Injectable } from '@angular/core';
import {
  BaseLicenseDTO,
  OrganisationDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  activeWp: BehaviorSubject<WithdrawalProductDTO | null> = new BehaviorSubject<WithdrawalProductDTO | null>(null);
  organisation: BehaviorSubject<OrganisationDTO | null> = new BehaviorSubject<OrganisationDTO | null>(null);
  wptFreeTracking: BehaviorSubject<WptFreeTrackingDTO[] | null> = new BehaviorSubject<WptFreeTrackingDTO[] | null>(
    null,
  );
  freeWtp: BehaviorSubject<WithdrawalProductType[] | null> = new BehaviorSubject<WithdrawalProductType[] | null>(null);
  baseLicense: BehaviorSubject<BaseLicenseDTO | null> = new BehaviorSubject<BaseLicenseDTO | null>(null);
  price: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  pendingWp: BehaviorSubject<WithdrawalProductDTO | null> = new BehaviorSubject<WithdrawalProductDTO | null>(null);
  pendingBaseLicense: BehaviorSubject<BaseLicenseDTO | null> = new BehaviorSubject<BaseLicenseDTO | null>(null);
  pendingPrice: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  isSchoolLicense: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  isBlankLicense: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  isFreeMode: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  // isFutureDiffPayment:  BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);
  getDateTimeValidUntilForFutureDiffPayment: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  onManipulateLicenseSuccessFn: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  constructor() {}

  getActiveWp(): Observable<WithdrawalProductDTO | null> {
    return this.activeWp.asObservable();
  }

  getOrganisation(): Observable<OrganisationDTO | null> {
    return this.organisation.asObservable();
  }

  getWptFreeTracking(): Observable<WptFreeTrackingDTO[] | null> {
    return this.wptFreeTracking.asObservable();
  }

  getFreeWtp(): Observable<WithdrawalProductType[] | null> {
    return this.freeWtp.asObservable();
  }

  getBaseLicense(): Observable<BaseLicenseDTO | null> {
    return this.baseLicense.asObservable();
  }

  getPrice(): Observable<any> {
    return this.price.asObservable();
  }

  getPendingWp(): Observable<WithdrawalProductDTO | null> {
    return this.pendingWp.asObservable();
  }

  getPendingBaseLicense(): Observable<BaseLicenseDTO | null> {
    return this.pendingBaseLicense.asObservable();
  }

  getPendingPrice(): Observable<any> {
    return this.pendingPrice.asObservable();
  }

  getIsSchoolLicense(): Observable<any> {
    return this.isSchoolLicense.asObservable();
  }

  getIsBlankLicense(): Observable<any> {
    return this.isBlankLicense.asObservable();
  }

  getIsFreeMode(): Observable<any> {
    return this.isFreeMode.asObservable();
  }

  // setisFutureDiffPayment(track:any) {
  //   return this.isFutureDiffPayment.next(track);
  // }

  // getisFutureDiffPayment(){
  //   return this.isFutureDiffPayment.asObservable();
  // }

  getGetDateTimeValidUntilForFutureDiffPayment(): Observable<any> {
    return this.getDateTimeValidUntilForFutureDiffPayment.asObservable();
  }

  getOnManipulateLicenseSuccessFn(): Observable<any> {
    return this.onManipulateLicenseSuccessFn.asObservable();
  }
}
