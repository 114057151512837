import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-invalid',
  templateUrl: './billing-invalid.component.html',
  styleUrls: ['./billing-invalid.component.scss'],
})
export class BillingInvalidComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
