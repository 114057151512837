import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BillingModalComponent } from '@app/account/billings/billing-modal/billing-modal.component';
import { AuthenticationService } from '@app/auth';
import { RealEstateDTO, WithdrawalProductType } from '@generated/generatedEntities';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingModalService {
  moduleNames$: BehaviorSubject<WithdrawalProductType[] | null> = new BehaviorSubject<WithdrawalProductType[] | null>(
    null,
  );
  constructor(
    private _dialog: MatDialog,
    private _authService: AuthenticationService,
  ) {}

  openBillingModal(
    moduleNames: WithdrawalProductType | WithdrawalProductType[],
    realEstateContainer?: RealEstateDTO,
  ): Observable<any> {
    const dialogRef = this._dialog.open(BillingModalComponent, {
      // width: '100%',
      width: 'auto',
      closeOnNavigation: true,
      // backdropClass: 'static',
      data: {
        moduleNames: moduleNames,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (realEstateContainer) {
          this.refreshPOM(realEstateContainer, moduleNames);
        } else {
          this._authService.identity(true);
        }
      });
    return dialogRef.afterClosed();
  }

  refreshPOM(realEstateContainer: RealEstateDTO, moduleNames: WithdrawalProductType | WithdrawalProductType[]) {
    this._authService.identity(true); // force refresh of rights
    realEstateContainer.pom.hasPomData = this._authService.hasAnyModule(
      moduleNames[0] as WithdrawalProductType | WithdrawalProductType[],
    );
  }

  getModuleNamesList(): Observable<WithdrawalProductType | WithdrawalProductType[] | null> {
    return this.moduleNames$.asObservable();
  }
}
