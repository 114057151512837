import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ResetFinishComponent } from './reset-finish/reset-finish.component';
import { ResetPasswordComponent } from './reset-password.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  // path: 'reset/finish/:requestId',
  { path: '', component: ResetPasswordComponent, data: { title: 'reset password' } },
  {
    path: 'reset/finish',
    component: ResetFinishComponent,
    // data: { title: 'seo.contact.pageTitle' } //commenting because translation issue
    data: { title: 'Reset Password | KeeValue ag' },
  },

  // {
  //   path: '',
  //   component: ResetPasswordCompnent,
  //   // data: { title: 'seo.contact.pageTitle' } //commenting because translation issue
  //   data: { title: 'Reset Password | KeeValue ag' },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslocoModule],
  exports: [RouterModule],
  providers: [],
})
export class ResetPasswordRoutingModule {}
