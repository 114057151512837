import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountService } from '@app/account/account.service';
import {
  OrganisationDTO,
  PaymentMeans,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing-edit-license',
  templateUrl: './billing-edit-license.component.html',
  styleUrls: ['./billing-edit-license.component.scss'],
})
export class BillingEditLicenseComponent implements OnInit {
  activeWp!: WithdrawalProductDTO;
  organisation: OrganisationDTO = {} as OrganisationDTO;
  wptFreeTracking: WptFreeTrackingDTO[] = [];
  freeWtp: WithdrawalProductType[] = [];
  baseLicense: any;
  paymentMeans!: PaymentMeans;
  freeMode: boolean = false;

  @Output() licenceChanged = new EventEmitter<void>();

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService
      .getActiveWp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (activeWp) => {
          //console.log('activeWp',activeWp);
          if (activeWp != null) {
            this.activeWp = activeWp;
            this.paymentMeans = this.activeWp.paymentMeans;
            this.freeMode = this.activeWp.licenseState === 'FREE';
          }
        },
      });
    this.accountService
      .getOrganisation()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (organisation: OrganisationDTO | null) => {
          //console.log('organisation',organisation);
          if (organisation != null) {
            this.organisation = organisation;
          } else {
            this.organisation = {} as OrganisationDTO;
          }
        },
      });
    this.accountService
      .getWptFreeTracking()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (wptFreeTracking: WptFreeTrackingDTO[] | null) => {
          //console.log('wptFreeTracking',wptFreeTracking);
          if (wptFreeTracking != null) {
            this.wptFreeTracking = wptFreeTracking;
          } else {
            this.wptFreeTracking = [];
          }
        },
      });
    this.accountService
      .getFreeWtp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (freeWtp) => {
          //console.log('freeWtp',freeWtp);
          if (freeWtp != null) {
            this.freeWtp = freeWtp;
          } else {
            this.freeWtp = [];
          }
        },
      });
    this.accountService
      .getBaseLicense()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (baseLicense) => {
          //console.log('baseLicense',baseLicense);
          this.baseLicense = baseLicense;
        },
      });
  }

  isFutureDiffPayment(track: any) {
    //this.accountService.setisFutureDiffPayment(track);
    return new Date().toUTCString() < track.dateTimeFreeUntil && !track.dateTimeInactive;
  }
}
