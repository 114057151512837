<div class="mat-typography bg-white flex-col" *transloco="let t">
  <div class="header-dimension">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <h4 class="modal-title" translate>{{ videoTitle }}</h4>
  </div>

  <div class="login-container flex-row">
    <mat-card class="login-box flex-1">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          width="1920"
          height="1080"
          class="embed-responsive-item"
          [src]="videoUrl"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
    </mat-card>
  </div>
</div>
