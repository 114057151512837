import { Route } from '@angular/router';
import {
  HeatDistribution,
  HeatGeneration,
  HeatingType,
  OutRealEstateDTO,
  RealEstateDTO,
  RebuildEstateDTO,
  UsageStandard,
  UsageTypeKv,
  WithdrawalProductType,
  WithdrawDTO,
} from './generatedEntities';

export type RouteData = {
  title?: string;
  permission?: string[];
  roles?: RoleTypes;
  module?: WithdrawalProductType;
  modules?: WithdrawalProductType[];
};

export type RoleTypes = 'ROLE_USER' | 'ROLE_ADMIN' | 'ROLE_PROTOTYPE';

export interface AppRoute extends Route {
  data?: RouteData;
}

/**
 * Creates a new Type of <T> with all fields optional (recursively)
 */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type PartialNullable<T> = T extends object ? { [K in keyof T]?: PartialNullable<T[K]> | null } : T;

/**
 * Special Type that all fields are nullable and also Partial - e.g., optional (can be undefined).
 */
export type NullableRealEstateDTO = PartialNullable<RealEstateDTO>;
export type NullableRebuildEstateDTO = PartialNullable<RebuildEstateDTO>;
/**
 * this might not be needed Lfon 30.08.2022
 */
export class Page<T> {
  content: T;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  size: number;
  totalElements: number;
  totalPages: number;

  constructor(
    content: T,
    first: boolean,
    last: boolean,
    numberOfElements: number,
    size: number,
    totalElements: number,
    totalPages: number,
  ) {
    this.content = content;
    this.first = first;
    this.last = last;
    this.numberOfElements = numberOfElements;
    this.size = size;
    this.totalElements = totalElements;
    this.totalPages = totalPages;
  }
}

export interface KvBoxplotData extends IndexSignature {
  key: number;
  quartile: number[];
  whiskers: number[];
  outliers: number[];
  max: number;

  gap?: number[];
}

export interface IndexSignature {
  [key: string]: any;
}

export interface YearContainer {
  yearPlaceholder: number;
  yearUserInput: number;
  year: number;
}

export interface WebinarContainer {
  url: string;
  type: WithdrawalProductType;
  date: string;
}

export interface ItemContainer {
  id: string;
  title: string;
  parent: boolean;
  translated: string;
}

export const mergeArrays = <T>(a: T | T[], b: T | T[]): T[] => {
  const toArray = (x: T | T[]): T[] => (Array.isArray(x) ? x : [x]);
  const unique = (arr: T[]): T[] => [...new Set(arr)];

  return unique([...toArray(a), ...toArray(b)]);
};

export interface IframeInput extends OutRealEstateDTO {
  requestId: string;
  apiKey: string;
  orgApiKey: string;
  module: WithdrawalProductType;
  callBackUrl: string;
}

export class IframeCallBack {
  callBackUrl: string;
  result: IframeResult;

  constructor(callBackUrl: string, result: IframeResult) {
    this.result = result;
    this.callBackUrl = callBackUrl;
  }
}

export class IframeResult {
  requestId: string;
  result: WithdrawDTO;
  validInput: RealEstateDTO | RebuildEstateDTO;

  constructor(requestId: string, result: WithdrawDTO, validInput: RealEstateDTO | RebuildEstateDTO) {
    this.requestId = requestId;
    this.result = result;
    this.validInput = validInput;
  }
}

export interface IframeOrgApiKey {
  key: string;
}

export interface CountourLcaGeneric<A, B, C> {
  base: A[];
  isolation: B[];
  surface: C[];
}

/**
 * A special container that only allows the following heatingGeneration types: (for DISTRICT_HEATING)
 * 'WE_13'
 * 'WE_14'
 * 'WE_15'
 * 'WE_16'
 * 'WE_17'
 *
 */
export interface HeatingContainer {
  heatingType: HeatingType | null;
  percentage: number;
  fossileAmount: Extract<HeatGeneration, 'WE_13' | 'WE_14' | 'WE_15' | 'WE_16' | 'WE_17'> | null;
  distribution: HeatDistribution;
}

export type InputFieldsType =
  | 'usages'
  | 'totalFloorArea416'
  | 'totalVolume416'
  | 'ratioVolumeUnderTerrain'
  | 'builtUpArea'
  | 'buildings'
  | 'overgroundFloorsUndergroundFloors'
  | 'undergroundParkingSpaces'
  | 'roofTypes'
  | 'facadeType'
  | 'heatingTypes'
  | 'ventilation'
  | 'chiller'
  | 'elevatorVertical'
  | 'qualitaetGrundstueck'
  | 'qualitaetvorbereitungsarbeiten'
  | 'qualitaetVorfertigung'
  | 'qualitaetDerGebaeudeform2'
  | 'fensterAnteil'
  | 'qualitaetElektroAnlage'
  | 'qualitaetSanitaeranlage'
  | 'qualitaetAusbau1'
  | 'qualitaetAusbau2'
  | 'qualitaetUmgebung';

export type InputFieldsAdditionalType = 'numberOfQuantity' | 'numberOfQuality';

export type UsageStandardUsed = Extract<UsageStandard, 'LOW' | 'MEDIUM' | 'HIGH'>;

export type UsageTypeKvUsed = Extract<
  UsageTypeKv,
  | 'WOHNBAUTEN__TITLE'
  | 'SCHULBAUTEN__TITLE'
  | 'LAGERBAUTEN__TITLE'
  | 'LAGER_PRODUKTION__PRODUKTION'
  | 'LANDWIRTSCHAFT__TITLE'
  | 'BUERO_HANDELSBAUTEN__HANDELSBAU'
  | 'BUERO_HANDELSBAUTEN__BVB'
  | 'JUSTIZBAUTEN__TITLE'
  | 'GESUNDHEITSBAUTEN__TITLE'
  | 'KULTUS__TITLE'
  | 'KULTUR__TITLE'
  | 'GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE'
  | 'FREIZEIT_SPORT_ERHOLUNGSBAUTEN'
  | 'VERKEHRSBAUTEN__TITLE'
  | 'SCHUTZBAUTEN__TITLE'
  | 'WOHNBAUTEN__MFH'
  | 'WOHNBAUTEN__TERRASSENHAEUSER'
  | 'WOHNBAUTEN__EFH_REIHEN'
  | 'WOHNBAUTEN__ALTERSWOHNUNGEN'
  | 'WOHNBAUTEN__ALTERSHEIME'
  | 'WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME'
  | 'WOHNBAUTEN__STUDENTENWOHNHAEUSER'
  | 'WOHNBAUTEN__PERSONALHAEUSER'
  | 'SCHULBAUTEN__KINDERGAERTEN_HORTE'
  | 'SCHULBAUTEN__VOLKSSCHULEN'
  | 'SCHULBAUTEN__BERUFSSCHULEN__BERUF'
  | 'SCHULBAUTEN__BERUFSSCHULEN__GYM'
  | 'SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN'
  | 'SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN'
  | 'SCHULBAUTEN__BIBLIOTHEKEN'
  | 'SCHULBAUTEN__FORSCHUNGSBAUTEN'
  | 'SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR'
  | 'SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR'
  | 'LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN'
  | 'LAGERBAUTEN_MEHRSTOECKIGE'
  | 'LAGERBAUTEN__VERTEILZENTRALEN'
  | 'LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN'
  | 'LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN'
  | 'LANDWIRTSCHAFT__SCHEUNEN'
  | 'LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG'
  | 'BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU'
  | 'BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM'
  | 'BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE'
  | 'BUERO_HANDELSBAUTEN__BVB__BUERO'
  | 'BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK'
  | 'BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG'
  | 'BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER'
  | 'BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG'
  | 'BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU'
  | 'BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU'
  | 'JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG'
  | 'GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL'
  | 'GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK'
  | 'GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM'
  | 'KULTUS__KIRCHGEMEINDEHAEUSER'
  | 'KULTUR__AUSSTELLUNGEN'
  | 'KULTUR__MUSEEN'
  | 'KULTUR__KULTUR_GEMEINSCHAFTSZENTREN'
  | 'KULTUR__SAAL'
  | 'GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION'
  | 'GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL'
  | 'GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT'
  | 'GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE'
  | 'FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE'
  | 'FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE'
  | 'FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD'
  | 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS'
  | 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE'
  | 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE'
  | 'VERKEHRSBAUTEN__WERKHOEFE'
  | 'VERKEHRSBAUTEN__ZOLL'
  | 'SCHUTZBAUTEN__FEUERWEHR'
>;
