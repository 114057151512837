<div *transloco="let t">
  <div style="margin-bottom: 20px">
    <div *ngIf="successMessage !== ''" class="alert alert-kennwerte-info" [ngSwitch]="successMessage">
      <span *ngSwitchCase="'MODULE_ACTIVATED'">{{ t("billing.module.state-activated") }}</span>
      <span *ngSwitchCase="'MODULE_CANCELED'">{{ t("billing.module.state-canceled") }}</span>
      <span *ngSwitchCase="'MODULE_ORDERED'">{{ t("billing.module.state-ordered") }}</span>
      <span *ngSwitchCase="'MODULE_BOUGHT'">{{ t("billing.module.state-bought") }}</span>
      <span *ngSwitchDefault>{{ successMessage }}</span>
    </div>

    <div class="flexBoxInner">
      <table id="modulTable" class="table" style="margin-bottom: 10px; width: 100%">
        <thead>
          <tr>
            <th style="width: 5%"></th>
            <th style="width: 35%"></th>
            <th style="width: 30%"></th>
            <th style="width: 30%"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wpt of modules" height="45px">
            <ng-template #wptType="init" [appInit]="wpt === 'LCC_PACKAGE' ? 'bundle' : 'module'"></ng-template>
            <ng-template #wptName>
              <span>{{ t("billing." + wptType.value + ".label") }}</span>
              {{ t("prices.module." + wpt) }}
            </ng-template>
            <ng-template #wptDescription>
              <div *ngIf="wptType.value == 'bundle'" style="font-size: 13px">{{ t("billing.bundle.description") }}</div>
            </ng-template>
            <ng-container *ngIf="checkFilter(wpt)">
              <!-- Filter out modules configured to not show -->
              <ng-container
                *ngIf="
                  isParentPackageActive(wpt) || isParentPackageSelected(wpt);
                  then parentModuleActiveOrSelected;
                  else parentModuleNotActiveOrSelected
                "
              ></ng-container>
              <ng-template #parentModuleActiveOrSelected>
                <td><input style="vertical-align: bottom" type="checkbox" checked="true" disabled /></td>
                <td style="vertical-align: middle">
                  <ng-container *ngTemplateOutlet="wptName"></ng-container>
                  <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                </td>
                <td colspan="2" style="text-align: right">
                  <ng-container
                    *ngIf="isParentPackageActive(wpt); then parentModuleActive; else parentModuleSelected"
                  ></ng-container>
                  <ng-template #parentModuleActive>{{ t("billing.module.access-by-bundle") }}</ng-template>
                  <ng-template #parentModuleSelected>{{ t("billing.module.access-will-be-by-bundle") }}</ng-template>
                </td>
              </ng-template>
              <ng-template #parentModuleNotActiveOrSelected>
                <ng-container *ngIf="wpt !== 'LCC'">
                  <ng-template #wptFreeTracking="init" [appInit]="findWptFreeTracking(wpt)"></ng-template>
                  <ng-container *ngIf="wptFreeTracking.value !== null">
                    <!-- Case 1: Module is in wptFreeTracking, it was already tested before -->
                    <ng-container *ngIf="checkNonFreeAndActiveWpt(wptFreeTracking.value) && wpt as activeNonFreeWpt">
                      <!-- Case 1.a: Module is not free (anymore) and active -->
                      <td><input style="vertical-align: bottom" type="checkbox" checked="true" disabled /></td>
                      <td style="vertical-align: middle">
                        <ng-container *ngTemplateOutlet="wptName"></ng-container>
                        <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                      </td>
                      <td>
                        <ng-container>
                          <span style="width: 100%">
                            <span>{{ t("billing." + wptType.value + ".renewal") }}</span>
                            {{ activeWp.dateTimeValidUntil | date: "dd/MM/yyyy" }}
                          </span>
                        </ng-container>
                      </td>
                      <td>
                        <ng-container>
                          <button
                            style="width: 100%"
                            class="btn-slim btn btn-elegant-black"
                            ng-class="{ active: selectedWptToCancel.wpt === activeNonFreeWpt }"
                            (click)="togglePrepareCancelModul(activeNonFreeWpt, false)"
                          >
                            <span *ngIf="selectedWptToCancel === activeNonFreeWpt">{{
                              t("billing." + wptType.value + ".action-cancel-stop")
                            }}</span>
                            <span *ngIf="selectedWptToCancel !== activeNonFreeWpt">{{
                              t("billing." + wptType.value + ".action-cancel")
                            }}</span>
                          </button>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="checkFreeAndActiveWpt(wptFreeTracking.value) && wpt as activeFreeWpt">
                      <!-- Case 1.b: Module is active and still free to use -->
                      <td><input style="vertical-align: bottom" type="checkbox" checked="true" disabled /></td>
                      <td style="vertical-align: middle">
                        <ng-container *ngTemplateOutlet="wptName"></ng-container>
                        <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                      </td>
                      <td>
                        <span style="width: 100%">
                          <span data-translate="billing.freeDate">{{ t("billing.freeDate") }}</span>
                          {{ wptFreeTracking.value.dateTimeFreeUntil | date: "dd/MM/yyyy" }}
                        </span>
                      </td>
                      <td>
                        <button
                          style="width: 100%"
                          class="btn-slim btn btn-elegant-black"
                          ng-class="{ active: selectedFreeWptToCancel.wpt === activeFreeWpt }"
                          (click)="togglePrepareCancelModul(activeFreeWpt, true)"
                        >
                          <span *ngIf="selectedFreeWptToCancel === activeFreeWpt">{{
                            t("billing." + wptType.value + ".action-cancel-stop")
                          }}</span>
                          <span *ngIf="selectedFreeWptToCancel !== activeFreeWpt">{{
                            t("billing." + wptType.value + ".action-cancel")
                          }}</span>
                        </button>
                      </td>
                      <td></td>
                    </ng-container>
                    <ng-container
                      *ngIf="checkNonFreeAndInactiveWpt(wptFreeTracking.value) && wpt as inactiveNonFreeWpt"
                    >
                      <!-- Case 1.c: Module is inactive and not free (anymore) -->
                      <td>
                        <input
                          *ngIf="!isContainedInActiveWpts(inactiveNonFreeWpt)"
                          type="checkbox"
                          (click)="toggleModuleToBuy(inactiveNonFreeWpt, activeWp.licenseState)"
                          [checked]="selectedWithdrawalProductTypes.includes(inactiveNonFreeWpt)"
                        />
                        <input
                          style="vertical-align: bottom"
                          *ngIf="isContainedInActiveWpts(inactiveNonFreeWpt)"
                          type="checkbox"
                          disabled
                        />
                      </td>
                      <td style="vertical-align: middle">
                        <ng-container *ngTemplateOutlet="wptName"></ng-container>
                        <i
                          >(<span>{{ t("billing." + wptType.value + ".canceled") }}</span
                          >)</i
                        >
                        <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                      </td>
                      <td style="vertical-align: middle">
                        <span style="width: 100%">
                          <ng-container
                            *ngIf="activeWp.licenseStillValid && isContainedInActiveWpts(inactiveNonFreeWpt)"
                          >
                            <span>{{ t("billing." + wptType.value + ".usable") }}</span>
                            {{ activeWp.dateTimeValidUntil | date: "dd/MM/yyyy" }}
                          </ng-container>
                        </span>
                      </td>
                      <td>
                        <ng-template
                          *ngIf="!isContainedInActiveWpts(inactiveNonFreeWpt); then buyButton; else activateButton"
                        ></ng-template>
                        <ng-template #buyButton>
                          <button
                            style="width: 100%"
                            class="btn-slim btn btn-elegant-black"
                            [ngClass]="{ active: selectedWithdrawalProductTypes.includes(inactiveNonFreeWpt) }"
                            (click)="toggleModuleToBuy(inactiveNonFreeWpt, activeWp.licenseState)"
                          >
                            <span
                              *ngIf="
                                selectedWithdrawalProductTypes.includes(inactiveNonFreeWpt);
                                then deselectActionText;
                                else selectActionText
                              "
                            ></span>
                            <ng-template #deselectActionText>{{
                              t("billing." + wptType.value + ".select")
                            }}</ng-template>
                            <ng-template #selectActionText>{{
                              t("billing." + wptType.value + ".action-select-buy")
                            }}</ng-template>
                          </button>
                        </ng-template>
                        <ng-template #activateButton>
                          <button
                            style="width: 100%"
                            class="btn-slim btn btn-elegant-black"
                            [ngClass]="{ active: selectedWithdrawalProductTypes.includes(inactiveNonFreeWpt) }"
                            (click)="reactivateFreeModule(inactiveNonFreeWpt)"
                          >
                            {{ t("billing." + wptType.value + ".action-activate") }}
                          </button>
                        </ng-template>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="checkFreeAndInactiveWpt(wptFreeTracking.value) && wpt as inactiveFreeWpt">
                      <!-- Case 1.d: Module is inactive, but would still be free -->
                      <td><input style="vertical-align: bottom" type="checkbox" disabled /></td>
                      <td style="vertical-align: middle">
                        <ng-container *ngTemplateOutlet="wptName"></ng-container>
                        <i
                          >(<span>{{ t("billing." + wptType.value + ".canceled") }}</span
                          >)</i
                        >
                        <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                      </td>
                      <td>
                        <span style="width: 100%" class="">
                          <span>{{ t("billing.freeDate") }}</span>
                          {{ wptFreeTracking.value.dateTimeFreeUntil | date: "dd/MM/yyyy" }}
                        </span>
                      </td>
                      <td>
                        <button
                          style="width: 100%"
                          class="btn-slim btn btn-elegant-black"
                          (click)="reactivateFreeModule(inactiveFreeWpt)"
                        >
                          {{ t("billing." + wptType.value + ".action-activate") }}
                        </button>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="freeWpt?.includes(wpt)">
                    <!-- Case 2: Module is in freeWpt, it was not already tested before. -->
                    <td>
                      <input
                        style="vertical-align: sub"
                        type="checkbox"
                        (click)="selectFreeWpt(wpt)"
                        [checked]="selectedFreeWithdrawalProductTypes.includes(wpt)"
                      />
                    </td>
                    <td style="vertical-align: middle">
                      <ng-container *ngTemplateOutlet="wptName"></ng-container>
                      <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                    </td>
                    <td style="width: 25%"></td>
                    <td style="width: 25%">
                      <button
                        style="width: 100%"
                        class="btn-slim btn btn-elegant-black"
                        [ngClass]="{ active: selectedFreeWithdrawalProductTypes.includes(wpt) }"
                        (click)="selectFreeWpt(wpt)"
                      >
                        <span
                          *ngIf="
                            selectedFreeWithdrawalProductTypes.includes(wpt);
                            then deselectFreeActionText;
                            else selectFreeActionText
                          "
                        ></span>
                        <ng-template #deselectFreeActionText>{{
                          t("billing." + wptType.value + ".select")
                        }}</ng-template>
                        <ng-template #selectFreeActionText>{{
                          t("billing." + wptType.value + ".free_week")
                        }}</ng-template>
                      </button>
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="wpt === 'LCC'">
                  <td>
                    <input style="vertical-align: bottom" type="checkbox" disabled />
                  </td>
                  <td style="vertical-align: middle">
                    <ng-container *ngTemplateOutlet="wptName"></ng-container>
                    <ng-container *ngTemplateOutlet="wptDescription"></ng-container>
                  </td>
                  <td style="width: 50%; vertical-align: middle" colspan="2">
                    {{ t("billing.module.module-only-by-bundle") }}
                  </td>
                </ng-container>
              </ng-template>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flexBoxInner" *ngIf="priceLoaded">
      <app-modul-buy
        [hasFreeWeek]="selectedFreeWithdrawalProductTypes.length > 0"
        [currentLicenceValidUntil]="activeWp.dateTimeValidUntil"
        [newLicencePrice]="priceFree"
        [differentialPrice]="additionalPrice"
        [organisation]="organisation"
        [activeWp]="activeWp"
        [productTypes]="
          selectedFreeWithdrawalProductTypes.length > 0
            ? selectedFreeWithdrawalProductTypes
            : selectedWithdrawalProductTypes
        "
        [numAdditionalUsers]="activeWp.numMaxUsers"
        [enableNumAddUsers]="activeWp.numMaxUsers > 1"
        [numberOfCurrentWpts]="activeWp.withdrawalProductTypes.length"
        (orderProductsEvent)="orderProducts($event)"
        (orderFreeProductsEvent)="orderFreeProducts($event)"
      >
      </app-modul-buy>
    </div>

    <!-- Modul kündigen -->
    <div>
      <ng-template
        #wptType="init"
        [appInit]="selectedFreeWptToCancel === 'LCC_PACKAGE' ? 'bundle' : 'module'"
      ></ng-template>
      <ng-container *ngIf="selectedFreeWptToCancel !== null">
        <h4>{{ t("billing." + wptType.value + ".cancel") }}</h4>
        <span>
          <span>{{ t("billing." + wptType.value + ".cancel-1") }}</span
          >{{ t("prices.module." + selectedFreeWptToCancel)
          }}<span>{{ t("billing." + wptType.value + ".cancel-2") }} </span>
          <span>{{ t("billing." + wptType.value + ".cancel-3") }}</span>
          {{ findWptFreeTracking(selectedFreeWptToCancel)!.dateTimeFreeUntil | date: "dd/MM/yyyy" }}
          <span>{{ t("billing." + wptType.value + ".cancel-3-1") }}</span>
          <span>{{ t("billing." + wptType.value + ".cancel-4") }}</span></span
        >
        <div>
          <button class="btn-slim btn btn-elegant-black" (click)="cancelModul(selectedFreeWptToCancel)">
            <span>{{ t("billing." + wptType.value + ".cancel-module") }}</span
            >{{ t("prices.module." + selectedFreeWptToCancel)
            }}<span>{{ t("billing." + wptType.value + ".confirm") }}</span>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedWptToCancel !== null">
        <h4>
          {{ t("billing." + wptType.value + ".cancel") }}
        </h4>
        <span>
          <span>{{ t("billing.module.cancel-1") }}</span
          >{{ t("prices.module." + selectedWptToCancel) }}<span>{{ t("billing.module.cancel-2b") }}</span>
          {{ activeWp.dateTimeValidUntil | date: "dd/MM/yyyy" }}<span> {{ t("billing.module.cancel-3-1b") }}</span>
        </span>
        <div>
          <button class="btn-slim btn btn-elegant-black" (click)="cancelModul(selectedWptToCancel)">
            <span>{{ t("billing." + wptType.value + ".cancel-module") }}</span> "{{
              t("prices.module." + selectedWptToCancel)
            }}" <span>{{ t("billing." + wptType.value + ".confirm") }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
