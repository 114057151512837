import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '@app/auth';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { pipe } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [
        'prices',
        'global',
        'register',
        'settings',
        'process_form',
        'model_geometry',
        'model_realEstate',
        'realestates',
        'model_complexity',
        'model_quality',
      ],
    },
  ],
})
export class VideoDialogComponent implements OnInit {
  videoUrl!: any;
  safeURL: any;
  videoTitle: any;

  constructor(
    private authService: AuthenticationService,
    private _sanitizer: DomSanitizer,
    private videoDetailsService: VideoDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: { url: string; title: string },
  ) {
    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(data.url);
    this.videoTitle = data.title;
    console.log('data of video', data);
  }

  ngOnInit(): void {
    // this.videoDetailsService.getTeaserVideo()
    // .pipe(untilDestroyed(this))
    //   .subscribe({
    //     next: (data) => {
    //       if (data) {
    //         console.log("response url", data);
    //       }
    //     },
    //     error: (error) => {
    //       console.warn('error in video url  handle it' + error);
    //     },
    //   });
    // this.videoUrl = 'https://www.youtube.com/embed/2Zm-uxpVjXE?VQ=HD1080';
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }
}
