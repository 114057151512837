<div *transloco="let t">
  <div class="centeredBox flexBoxOuter rowBackgroundGray" style="padding-top: 20px; padding-bottom: 33px; width: 100%">
    <div class="flexBoxInner">
      <h3 data-translate="billing.history.header">{{ t("billing.history.header") }}</h3>
    </div>
    <div *ngIf="!payments">
      <div>
        <div class="flexBoxInner" style="margin-top: 20px" data-translate="billing.history.no-invoices">
          {{ t("billing.history.no-invoices") }}
        </div>
      </div>
    </div>
    <div *ngIf="payments" class="">
      <div class="flexBoxInner" style="margin-top: 20px">
        <div class="table-responsive" style="overflow: visible">
          <table class="table table-striped table-striped-kw">
            <thead>
              <th>
                <div data-translate="billing.table.order">
                  {{ t("billing.table.order") }}
                </div>
              </th>
              <th>
                <div data-translate="billing.table.date">
                  {{ t("billing.table.date") }}
                </div>
              </th>
              <th>
                <div data-translate="billing.table.amount">
                  {{ t("billing.table.amount") }}
                </div>
              </th>
              <th>
                <div data-translate="billing.table.state">{{ t("billing.table.state") }}</div>
              </th>
              <th>
                <div data-translate="billing.table.download-invoice">{{ t("billing.table.download-invoice") }}</div>
              </th>
            </thead>
            <tbody>
              <tr *ngFor="let p of payments">
                <ng-container *ngIf="p.status !== 'FAIL'">
                  <td>{{ p.confirmationId }}</td>
                  <td>{{ p.date | date: "dd/MM/yy" }}</td>
                  <td>{{ p.amount / 100 | number: "1.2-2" }} CHF</td>
                  <td>{{ translateStatus(p.status) }}</td>
                  <td>
                    <button
                      class="btn btn-sm"
                      (click)="downloadPaymentPdf(p)"
                      style="border: none; background-color: Transparent; background-repeat: no-repeat"
                    >
                      <!--                      <mat-icon aria-hidden="false" class="material-symbols-outlined">download</mat-icon>-->
                      <!--                      <mat-icon aria-hidden="false" class="material-symbols-outlined"> download_for_offline </mat-icon>-->
                      <!--                      <mat-icon aria-hidden="false" aria-label="material-symbols-outlined" style="vertical-align: sub"-->
                      <!--                        >forward_to_inbox</mat-icon-->
                      <!--                      >-->
                      <i class="material-icons">file_download</i>
                    </button>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
