import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@shared';
import { environment } from '@env/environment';
import { BookDemoDTO, ContactMeDTO, RegisterDTO } from '../../generated/generatedEntities';
import { Observable } from 'rxjs';
const log = new Logger('Register');
@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  check(registerDTO: RegisterDTO): Observable<Object> {
    return this.http.post(environment.serverUrl + '/api/check', registerDTO, { responseType: 'text' });
  }

  create(registerDTO: RegisterDTO): Observable<Object> {
    //error: "invalid email" if emails starts with org# or ends with @org
    // error: "organisation name already in use" if the organisation name already exists.
    // error: ""email address already in use" if email exists already in the system.

    return this.http.post(environment.serverUrl + '/api/regigster', registerDTO, { responseType: 'text' });
  }

  contactUs(contactMeDTO: ContactMeDTO): Observable<string> {
    return this.http.post(environment.serverUrl + '/api/account/contactme', contactMeDTO, { responseType: 'text' });
  }

  bookDemo(requestDemo: BookDemoDTO): Observable<string> {
    return this.http.post(environment.serverUrl + `/api/account/bookdemo`, requestDemo, { responseType: 'text' });
  }
}
