import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicImageComponent } from './dynamic-image.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@app/material.module';
import { DynamicImageRoutingModule } from './dynamic-image-routing.module';

@NgModule({
  declarations: [DynamicImageComponent],
  imports: [CommonModule, TranslocoModule, MaterialModule, DynamicImageRoutingModule],
  exports: [DynamicImageComponent],
})
export class DynamicImageModule {}
