import { environment } from '@env/environment';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, ErrorHandler, isDevMode, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ApiPrefixInterceptor, ErrorHandlerInterceptor, RouteReusableStrategy, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslocoRootModule } from './transloco-root.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { AnimatedDigitModule } from './animated/animated-digit/animated-digit.module';
import { RegistrationModule } from './registration/registration.module';
import { AuthInterceptor } from '@app/auth/interceptor/auth.interceptor';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { DynamicImageModule } from './dynamic-image/dynamic-image.module';
import { MatDialogModule } from '@angular/material/dialog';
import { WebsiteFragmentsModule } from './website_fragments/website-fragments.module';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { enableElfProdMode } from '@ngneat/elf';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { realEstatesFeature } from '@app/core/services/store/real-estate.state';
import { EffectsModule } from '@ngrx/effects';
import { RealEstateEffects } from '@app/core/services/store/real-estate.effects';

// export function initElfDevTools(actions: Actions) {
//   return () => {
//     devTools({
//       name: 'Sample Application',
//       actionsDispatcher: actions
//     })
//   };
// }

if (!environment.production) {
  devTools();
  // elfHooks.registerPreStoreUpdate((currentState, nextState, storeName) => {
  //   return deepFreeze(nextState);
  // });
} else {
  enableElfProdMode();
}

Sentry.init({
  dsn: environment.sentry_dsn,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    // new Sentry.BrowserTracing({
    //   routingInstrumentation: Sentry.routingInstrumentation,
    // }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    //FIXME currently disabled due to performance issues - might have to do with ngDoCheck?
    // new Sentry.Replay({
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
  environment: environment.profile,
  release: environment.version ?? '',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.3 : 0.1,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

@NgModule({
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('./safe-worker.js', { enabled: false }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslocoRootModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    ShellModule,
    LandingPageModule,
    AuthModule,
    AnimatedDigitModule,
    RegistrationModule,
    // RegisterFormModule,
    ResetPasswordModule,
    DynamicImageModule,
    MatDialogModule,
    WebsiteFragmentsModule,

    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    // EffectsNgModule.forRoot([SampleEffects]),
    //  StoreModule.forRoot(reducers),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    /*    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true, // If set to true, the connection is established within the Angular zone
    }),*/
    StoreModule.forFeature(realEstatesFeature),
    StoreModule.forRoot(realEstatesFeature.reducer, {
      // metaReducers: realEstatesFeature.reducer,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(RealEstateEffects),
    EffectsModule.forFeature(RealEstateEffects),
    StoreDevtoolsModule.instrument({ maxAge: 50, logOnly: !isDevMode(), name: 'keeValue' }),
    // StoreRouterConnectingModule.forRoot(),
    // must be imported as the last module as it contains the fallback route
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  providers: [
    // provideStore(),
    // {
    //   provide: APP_INITIALIZER,
    //   multi: true,
    //   useFactory: initElfDevTools,
    //   deps: [Actions]
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    provideZxvbnServiceForPSM(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((moduleRef) => {
    devTools({
      maxAge: 100,
      logTrace: true,
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  });
