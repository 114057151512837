import { Component, OnDestroy, OnInit } from '@angular/core';
import { CredentialsService } from '@app/auth';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-dynamic-webinar',
  templateUrl: './dynamic-webinar.component.html',
  styleUrls: ['./dynamic-webinar.component.scss'],
})
export class DynamicWebinarComponent implements OnInit, OnDestroy {
  authenticated: boolean = false;
  langKey: string = '';

  constructor(
    private translocoSrvice: TranslocoService,
    public credentialsService: CredentialsService,
  ) {}

  ngOnDestroy(): void {
    this.translateSuccess();
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.updateLangKey();
    this.authenticated = this.credentialsService.isAuthenticated();
  }

  updateLangKey() {
    this.translocoSrvice.langChanges$.pipe(untilDestroyed(this)).subscribe({
      next: (res) => {
        this.langKey = res;
      },
      error: (error) => {},
    });
  }

  translateSuccess() {
    this.updateLangKey();
  }
}
