import { LocationStrategy } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-update-license-additionals-modal',
  templateUrl: './update-license-additionals-modal.component.html',
  styleUrls: ['./update-license-additionals-modal.component.scss'],
})
export class UpdateLicenseAdditionalsModalComponent implements OnInit {
  errorPaymentFormError: string | null = null;
  errorPaymentFormSystemError = null;
  numAdditionalUsers: number = 0;
  currentNumMaxUsers: number = 0;
  freeMode: string = '';
  licenseState: string = '';
  validUntil: string = '';
  isAuthorized: boolean = false;
  isClicked: boolean = false;

  priceForCurrentWindow!: number;
  priceForCurrentWindowInclVat!: number;
  vat!: number;
  effectiveVat!: number;
  priceForNextWindow!: number;
  invalidCard: any;
  formAdditionalAccounts!: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<UpdateLicenseAdditionalsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public activeWp: any,
    private _formBuilder: UntypedFormBuilder,
    private billingService: BillingService,
    private locationStrategy: LocationStrategy,
  ) {}

  ngOnInit(): void {
    this.createformAdditionalAccounts();
    this.freeMode = this.activeWp.stateParam.freeMode;
    this.licenseState = this.activeWp.stateParam.licenseState;
    this.numAdditionalUsers = this.activeWp.stateParam.numAccountsToAdd;
    this.validUntil = this.activeWp.stateParam.dateTimeValidUntil;
    this.billingService
      .fetchAdditionalPrice(this.activeWp.stateParam.numAccountsToAdd, [], 0)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (ret) => {
          this.priceForCurrentWindow = ret.priceForCurrentWindow;
          this.priceForCurrentWindowInclVat = ret.priceForCurrentWindowInclVat;
          this.vat = ret.vat;
          this.effectiveVat = ret.effectiveVat;
          this.priceForNextWindow = ret.priceForNextWindow;
        },
      });
  }

  createformAdditionalAccounts() {
    this.formAdditionalAccounts = this._formBuilder.group({
      numAccountsToAdd: [this.activeWp.stateParam.numAccountsToAdd, Validators.required],
    });
  }

  authorizeUpdatePayment() {
    this.buyAdditionalUsers();
  }

  cancel() {
    this.dialogRef.close({
      response: {
        paymentMeansSuccess: null,
        paymentFormSuccess: null,
      },
    });
  }

  buyAdditionalUsers() {
    if (this.isClicked) {
      return;
    }
    this.isClicked = true;

    // var n = $location.$$absUrl.lastIndexOf($location.$$url);
    // var contextPath = $location.$$absUrl.slice(0, n);
    var n = this.locationStrategy.getBaseHref().lastIndexOf(this.locationStrategy.path());
    var contextPath = this.locationStrategy.getBaseHref().slice(0, n);
    var dto = {
      successUrl: contextPath + '/saferpay-success',
      failUrl: contextPath + '/saferpay-error',
      abortUrl: contextPath + '/saferpay-abort',
      payerLanguageCode: 'DE',
      numAdditionalUsers: this.numAdditionalUsers,
      currentNumMaxUsers: this.currentNumMaxUsers,
    };

    this.billingService
      .updateLicense(dto)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.isAuthorized = response.authorized;
          this.invalidCard = response.invalidCard;
          if (!this.isAuthorized) {
            this.errorPaymentFormError = null;
          } else {
            // SUCCESS
            this.errorPaymentFormError = null;
            setTimeout(() => {
              this.cancel();
            }, 2000);
          }
        },
        error: (error) => {
          this.errorPaymentFormError = 'ERROR';
          this.isClicked = false;
        },
      });
  }
}
