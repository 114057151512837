<div *transloco="let t">
  <div
    class="centeredBox flexBoxOuter rowBackgroundBlue"
    *ngIf="
      activeWp &&
      organisation &&
      activeWp.licenseStillValid &&
      !activeWp.dateTimeInactive &&
      organisation &&
      !activeWp.yearlyFixPrice
    "
    style="padding-top: 20px; padding-bottom: 33px"
  >
    <div [ngSwitch]="paymentMeans === 'CREDIT_CARD' || freeMode" class="flexBoxInner">
      <div class="flexBoxInner" style="text-align: left">
        <h3 data-translate="billing.module.edit">{{ t("billing.module.edit") }}</h3>
      </div>

      <app-module-overview
        [activeWpt]="activeWp.withdrawalProductTypes"
        [activeWp]="activeWp"
        [organisation]="organisation"
        [wptFreeTracking]="wptFreeTracking"
        [freeWpt]="freeWtp"
        (licenceChanged)="this.licenceChanged.emit()"
      >
      </app-module-overview>
    </div>
  </div>
</div>

<!-- [on-manipulate-license-success-fn]="onManipulateLicenseSuccessFn()" -->
