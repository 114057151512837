<div *transloco="let t">
  <div class="flexBoxInner" *ngIf="productTypes.length > 0">
    <h3 data-translate="billing.module.order.title">{{ t("billing.module.order.title") }}</h3>
    <div id="module_buy_overview">
      <div class="module-buy-block" *ngIf="hasFreeWeek">
        <div class="row">
          <!-- free week -->
          <div
            class="col-sm-5 module-buy-step module-buy-step-name"
            data-translate="billing.module.order.free_week.title"
          >
            {{ t("billing.module.order.free_week.title") }}
          </div>
          <div class="col-sm-5 module-buy-step" data-translate="billing.module.order.from_till">
            {{
              t("billing.module.order.from_till", {
                from: freeWeekFrom | date: "dd/MM/yy",
                till: freeWeekUntil | date: "dd/MM/yy"
              })
            }}
          </div>
          <div class="col-sm-2 module-buy-step" style="text-align: right">{{ 0.0 | number: "1.2-2" }} CHF</div>
        </div>
        <div class="flexBoxOuter1">
          <div class="centeredBoxText flexBoxInner" style="margin-top: 10px">
            <p
              class="alert kw-attention"
              data-translate="register.form.abo-convert.text-1"
              innerHTML="{{ t('register.form.abo-convert.text-1') }}"
            >
              <!-- rending translation inside innerHTML  -->
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="differentialPrice !== null && activeWp.licenseState !== 'INVALID'" class="module-buy-block">
        <!-- Differencial Rechnung -->
        <div class="row module-buy-header-row">
          <div
            class="col-sm-5 module-buy-step module-buy-step-name"
            data-translate="billing.module.order.licence_upgrade.title"
          >
            {{ t("billing.module.order.licence_upgrade.title") }}
          </div>
          <div class="col-sm-5 module-buy-step">
            {{
              t("billing.module.order.from_till", {
                from: currentLicenceValidFrom | date: "dd/MM/yy",
                till: currentLicenceValidUntil | date: "dd/MM/yy"
              })
            }}
          </div>
          <div class="col-sm-2 module-buy-step" style="text-align: right">
            <!-- {{differentialPrice.priceForCurrentWindowInclVat / 100 | currency:'CHF ':2}} -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <app-differential-price-table
              [organisation]="organisation"
              [isDueNow]="true"
              [activeWp]="activeWp"
              [additionalWpts]="productTypes"
              [numberOfCurrentWpts]="numberOfCurrentWpts"
              [differentialPrice]="differentialPrice.pricesForCurrentWindow"
            >
            </app-differential-price-table>
          </div>
        </div>
      </div>
      <div *ngIf="differentialPrice != null && activeWp.licenseState == 'INVALID'" class="module-buy-block">
        <!-- Differencial Rechnung -->
        <div class="row module-buy-header-row">
          <div
            class="col-sm-5 module-buy-step module-buy-step-name"
            data-translate="billing.module.order.licence_upgrade.title"
          >
            {{ t("billing.module.order.licence_upgrade.title") }}
          </div>
          <div class="col-sm-5 module-buy-step">
            {{
              t("billing.module.order.from_till", {
                from: currentLicenceValidFrom | date: "dd/MM/yy",
                till: currentLicenceValidUntil | date: "dd/MM/yy"
              })
            }}
          </div>
          <div class="col-sm-2 module-buy-step" style="text-align: right">
            <!-- {{differentialPrice.priceForCurrentWindowInclVat / 100 | currency:'CHF ':2}} -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <app-price-table
              [organisation]="organisation"
              [selected_withdrawal_product_types]="productTypes"
              [activeWp]="activeWp"
              [num_additional_users]="numAdditionalUsers"
              [enable_num_add_users]="enableNumAddUsers"
              [price]="differentialPrice.pricesForNextWindow"
            >
            </app-price-table>
          </div>
        </div>
      </div>

      <div *ngIf="newLicencePrice !== null" class="module-buy-block">
        <!-- Jahreslizenz -->
        <div class="row module-buy-header-row">
          <div class="col-sm-5 module-buy-step module-buy-step-name">
            <span *ngIf="numberOfCurrentWpts === 0; then thenBlock; else elseBlock"></span>
            <ng-template #thenBlock>
              {{ t("billing.module.order.new_licence.title") }}
            </ng-template>
            <ng-template #elseBlock>
              {{ t("billing.module.order.additional_licence.title") }}
            </ng-template>
          </div>
          <div class="col-sm-5 module-buy-step">
            {{
              t("billing.module.order.from_till", {
                from: newLicenceValidFrom | date: "dd/MM/yy",
                till: newLicenceValidUntil | date: "dd/MM/yy"
              })
            }}
          </div>
          <div class="col-sm-2 module-buy-step" style="text-align: right">
            <!-- {{newLicencePrice.yearlyTotalInclVAT / 100 | currency:'CHF ':2}} -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-top: 10px">
            <app-price-table
              [organisation]="organisation"
              [selected_withdrawal_product_types]="productTypes"
              [activeWp]="activeWp"
              [num_additional_users]="numAdditionalUsers"
              [enable_num_add_users]="enableNumAddUsers"
              [price]="newLicencePrice"
            >
            </app-price-table>
          </div>
        </div>
      </div>
      <div class="module-buy-block">
        <div class="row">
          <div class="col-md-12">
            <span data-translate="register.form.abo-convert.text-2">{{ t("register.form.abo-convert.text-2") }}</span>
            <a href="mailto:{{ t('products.about.text-2-2') }}">{{ t("products.about.text-2-2") }}</a>
          </div>
          <div class="col-md-12" style="margin-top: 10px">
            <span *ngIf="hasFreeWeek; then orderFreeProductsButton; else orderProductsButton"></span>
            <ng-template #orderFreeProductsButton>
              <button class="btn-elegant-orange btn-lg" (click)="onOrderFreeClick()">
                {{ t("billing.module.order.free_week.button_text") }}
              </button>
            </ng-template>
            <ng-template #orderProductsButton>
              <button class="btn-elegant-orange btn-lg" (click)="onOrderClick()">
                {{ t("billing.module.order.button_text") }}
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
