<!-- <p>license-table-tpl works!</p> -->
<app-price-table
  [organisation]="organisation"
  [selected_withdrawal_product_types]="activeWpts"
  [enable_num_add_users]="activewp.numMaxUsers > 1"
  [activeWp]="activewp"
  [price]="price"
>
</app-price-table>

<!-- [num_additional_users]="numAdditionalUsers" -->
