import { Injectable } from '@angular/core';
import {
  RealEstateDTO,
  RealEstateUsageEmbeddable,
  RebuildEstateDTO,
  TargetAdditionStoryDTO,
  TargetAnnexDTO,
  UsageTypeKv,
} from '@generated/generatedEntities';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SharedControllerFnService {
  constructor() {}

  usagesWithChiller: string[] = [
    'SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN',
    'SCHULBAUTEN__SPEZIELLE_FORSCHUNGSBAUTEN',
    'LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN',
    'LAGERBAUTEN_MEHRSTOECKIGE',
    'LAGERBAUTEN__VERTEILZENTRALEN',
    'LAGERBAUTEN__INDUSTRIEHALLEN',
    'LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN',
    'LAGERBAUTEN_PRODUKTIONSBAUTEN',
    'LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN',
    'LADENBAUTEN_HANDELSBAUTEN__EINZELHANDEL',
    'BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL',
    'LADENBAUTEN_HANDELSBAUTEN__GROSSHANDEL',
    'BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM',
    'GESUNDHEITSBAUTEN__KRANKENHAEUSER',
    'GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIKEN',
    'GESUNDHEIT__PFLEGEHEIME',
    'GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM',
  ];

  normalizeRebuildUsagesInProgress: boolean = false;

  countUsages(usages: any) {
    if (usages === undefined) return 0;
    return usages.length;
  }

  /**
   * Removes Teilusages which don't have a type. (e.g. empty usages)
   */
  cleanRealEstateContainerUsages(realEstateContainer: RebuildEstateDTO | RealEstateDTO | any) {
    // adding | RealEstateDTO | any so that we can reuse it in all 6 forms  ::FYI ANIKET
    //clean usage if there is no type set.
    if (realEstateContainer.usages != null && Array.isArray(realEstateContainer.usages)) {
      realEstateContainer.usages = this.cleanUsages(realEstateContainer.usages);
    }
    if (
      realEstateContainer.metaData.withdrawalProductType === 'SMALL_BUILDING_AGV' ||
      realEstateContainer.metaData.tasks.indexOf('SMALL_BUILDING_AGV') >= 0
    ) {
      realEstateContainer.agv.usages = this.cleanUsages(realEstateContainer.agv.usages);
    }
    if (
      realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS' ||
      realEstateContainer.metaData.tasks.indexOf('REBUILD_COSTS') >= 0 ||
      realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      realEstateContainer.metaData.tasks.indexOf('DAMAGE_COSTS') >= 0
    ) {
      if (realEstateContainer.usages != null && Array.isArray(realEstateContainer.usages)) {
        //realEstateContainer.usages.sort(sortUsages);
        realEstateContainer.usages = this.cleanUsages(realEstateContainer.usages);
      }
      //realEstateContainer.targetOverhaul.usages.sort(sortUsages);
      realEstateContainer.targetOverhaul.usages = this.cleanUsages(realEstateContainer.targetOverhaul.usages);
      // realEstateContainer.usages = this.cleanUsages(realEstateContainer.usages);
      if (
        realEstateContainer.targetAdditionStory != null &&
        realEstateContainer.selectedTemplates.indexOf('ADDITION_STORY') > -1
      ) {
        //realEstateContainer.targetAdditionStory.usages.sort(sortUsages);
        realEstateContainer.targetAdditionStory.usages = this.cleanUsages(
          realEstateContainer.targetAdditionStory.usages,
        );
      } else {
        if (realEstateContainer.targetAdditionStory == null) {
          realEstateContainer.targetAdditionStory = {} as TargetAdditionStoryDTO;
        }
        realEstateContainer.targetAdditionStory.usages = [];
      }
      if (realEstateContainer.targetAnnex != null && realEstateContainer.selectedTemplates.indexOf('ANNEX') > -1) {
        //realEstateContainer.targetAnnex.usages.sort(sortUsages);
        realEstateContainer.targetAnnex.usages = this.cleanUsages(realEstateContainer.targetAnnex.usages);
      } else {
        if (realEstateContainer.targetAnnex == null) {
          realEstateContainer.targetAnnex = {} as TargetAnnexDTO;
        }
        realEstateContainer.targetAnnex.usages = [];
      }
    }
    return realEstateContainer;
  }

  /**
   * Removes Teilusages which don't have a type. (e.g. empty usages)
   */
  cleanUsages(usages: any[]): any[] {
    //clean usage if there is no type set.
    try {
      for (var i = usages.length - 1; i >= 0; i--) {
        //if type is null,undefined or '' we remove the usage. https://dorey.github.io/JavaScript-Equality-Table/
        if (!usages[i].type) {
          usages.splice(i, 1);
        }
      }
      if (usages.length === 1) {
        // remove percent
        usages[0].percentage = null;
      }
    } catch (e) {
      console.warn(e);
    }
    return usages;
  }

  sortUsages(a: RealEstateUsageEmbeddable, b: RealEstateUsageEmbeddable) {
    // equal items sort equally
    if (a.type === b.type) {
      return 0;
    }
    // nulls sort after anything else
    else if (a.type === null) {
      return 1;
    } else if (b.type === null) {
      return -1;
    }
    if (!!a.percentage && !!b.percentage && a.percentage > b.percentage) {
      return -1;
    } else if (!!a.percentage && !b.percentage) {
      return -1;
    } else {
      return 1;
    }
  }

  isLastUsageTypeEmpty(usages: RealEstateUsageEmbeddable[]) {
    if (usages === undefined) return true;
    var obj = usages[usages.length - 1].type;
    return obj === null || typeof obj === 'undefined';
  }

  countInvestments(realEstateContainer: RealEstateDTO) {
    if (realEstateContainer === undefined) return 0;
    return realEstateContainer.investments.length;
  }

  isLastInvestmentEmpty(realEstateContainer: RealEstateDTO) {
    //CHECK THIS
    if (
      realEstateContainer === undefined ||
      realEstateContainer.investments[realEstateContainer.investments.length - 1] === undefined
    )
      return true;
    var obj = realEstateContainer.investments[realEstateContainer.investments.length - 1].value;
    return obj === null || typeof obj === 'undefined' || obj === '';
  }

  cleanDto(realEstateContainer: RealEstateDTO) {
    try {
      //if we are in new building calculator we make sure that we dont have the construction year set.
      if (realEstateContainer.metaData.withdrawalProductType === 'BUILDING_COSTS_AND_DATES') {
        realEstateContainer.constructionYear = undefined;
      }

      if (
        !realEstateContainer.metaData.tasks.includes('OPERATING_COSTS') &&
        !realEstateContainer.metaData.tasks.includes('BUILDING_INSURANCE_VALUES') &&
        realEstateContainer.metaData.tasks.includes('BUILDING_COSTS_AND_DATES')
      ) {
        realEstateContainer.constructionYear = undefined;
        //delete realEstateContainer.quality.roofType;
        //delete realEstateContainer.quality.pv;
        realEstateContainer.pom = { securityType: null, operationalComponents: [] };
        //delete realEstateContainer.quality.minergieStandard;
        realEstateContainer.quality.hasVentilation = false;
        //NOR => ( Y = !(A+B)
        // if (!(!_.includes(realEstateContainer.metaData.tasks, 'REBUILD_COSTS') || !_.includes(realEstateContainer.metaData.tasks, 'DAMAGE_COSTS'))) {
        //   realEstateContainer.quality.heatingTypes = null;
        // }
      }
      //TODO ALSO REMOVE INVESTMENTS?
    } catch (e) {
      console.warn(e);
    }
  }

  cleanRebuildDto(realEstateContainer: RebuildEstateDTO) {
    if (
      realEstateContainer.targetAdditionStory != null &&
      !this.hasUsageArrayWithChiller(realEstateContainer.targetAdditionStory.usages)
    ) {
      realEstateContainer.targetAdditionStory.chillerType = null;
      console.info('AdditionStory chillerType will be set to null.');
    }
    if (
      realEstateContainer.targetAnnex != null &&
      !this.hasUsageArrayWithChiller(realEstateContainer.targetAnnex.usages)
    ) {
      realEstateContainer.targetAnnex.chillerType = null;
      console.info('Annex chillerType will be set to null.');
    }
    if (
      realEstateContainer.targetOverhaul != null &&
      !this.hasUsageArrayWithChiller(_.concat(realEstateContainer.usages, realEstateContainer.targetOverhaul.usages))
    ) {
      realEstateContainer.quality.chillerType = null;
      realEstateContainer.targetOverhaul.chillerType = null;
      console.info('Quality and TargetOverhaul chillerType will be set to null.');
    }
  }

  /**
   * checks if object has the given property on first hit returns.
   * @param obj if null returns false.
   * @param prop should be a string.
   * @returns {boolean|*}
   */
  hasOwnDeepProperty(obj: any, prop: any) {
    if (typeof obj === 'object' && obj !== null) {
      // only performs property checks on objects (taking care of the corner case for null as well)
      if (obj.hasOwnProperty(prop)) {
        // if this object already contains the property, we are done
        return obj[prop];
      }
      for (var p in obj) {
        // otherwise iterate on all the properties of this object.
        if (
          obj.hasOwnProperty(p) && // and as soon as you find the property you are looking for, return true
          this.hasOwnDeepProperty(obj[p], prop)
        ) {
          return obj[p];
        }
      }
    }
    return false;
  }

  hasUsageArrayWithChiller(usages: RealEstateUsageEmbeddable[] | null | undefined) {
    var ret = false;
    if (usages != null && usages.length > 0) {
      usages.forEach((usage: RealEstateUsageEmbeddable) => {
        if (usage != null && usage.type != null && this.usagesWithChiller.includes(usage.type)) {
          ret = true;
          return ret;
        }
        return ret;
      });
    }
    return ret;
  }

  hasUsageWithChiller(realEstate: RealEstateDTO) {
    try {
      if (realEstate === undefined) return false;
      return this.hasUsageArrayWithChiller(
        realEstate.usages.concat(realEstate.usages, realEstate.usages, realEstate.usages),
      );
    } catch (e) {
      console.info(e);
      return false;
    }
  }

  /**
   * normalizes array length of usages to match the largest array. As we display the usages in matrix.
   * @param realEstateContainer
   */
  // to be reviewd and compare with old code
  normalizeRebuildUsages(realEstateContainer: RebuildEstateDTO) {
    if (!this.normalizeRebuildUsagesInProgress) {
      this.normalizeRebuildUsagesInProgress = true;
      var max = Math.max(
        ...[
          realEstateContainer.usages.length,
          realEstateContainer.targetOverhaul.usages.length,
          realEstateContainer.targetAnnex != null ? realEstateContainer.targetAnnex.usages.length : 0,
          realEstateContainer.targetAdditionStory != null ? realEstateContainer.targetAdditionStory.usages.length : 0,
        ],
      );
      // console.warn(max);
      if (max != null) {
        while (max > realEstateContainer.usages.length) {
          realEstateContainer.usages.push({
            type: null,
            percentage: null,
            standard: null,
            numFuUsage: null,
            comfortClass: null,
          });
        }
        while (max > realEstateContainer.targetOverhaul.usages.length) {
          realEstateContainer.targetOverhaul.usages.push({
            type: null,
            percentage: null,
            standard: null,
            numFuUsage: null,
            comfortClass: null,
          });
        }
        if (realEstateContainer.targetAnnex == null) {
          realEstateContainer.targetAnnex = {} as TargetAnnexDTO;
        }
        if (realEstateContainer.targetAnnex.usages == null) {
          realEstateContainer.targetAnnex.usages = [];
        }
        while (max > realEstateContainer.targetAnnex.usages.length) {
          realEstateContainer.targetAnnex.usages.push({
            type: null,
            percentage: null,
            standard: null,
            numFuUsage: null,
            comfortClass: null,
          });
        }
        if (realEstateContainer.targetAdditionStory == null) {
          realEstateContainer.targetAdditionStory = {} as TargetAdditionStoryDTO;
        }
        if (realEstateContainer.targetAdditionStory.usages == null) {
          realEstateContainer.targetAdditionStory.usages = [];
        }
        while (max > realEstateContainer.targetAdditionStory.usages.length) {
          realEstateContainer.targetAdditionStory.usages.push({
            type: null,
            percentage: null,
            standard: null,
            numFuUsage: null,
            comfortClass: null,
          });
        }
      }
      this.normalizeRebuildUsagesInProgress = false;
    }
  }
}
