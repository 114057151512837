<!-- <ng-container> -->
<div *transloco="let t">
  <div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()">&times;</button>
    <h4 class="modal-title ng-binding ng-scope" data-translate="billing.modal.title">
      <!-- Modul {{'module.' + moduleName | translate}} freischalten -->
      {{ t("billing.modal.title", { module: t("prices.module." + moduleName) }) }}
    </h4>
  </div>
  <div class="modal-body">
    <p
      class="brightfont"
      style="margin-top: 20px; display: block; font-weight: bold"
      data-translate="billing.modal.subtitle"
    >
      {{ t("billing.modal.subtitle", { module: t("prices.module." + moduleName) }) }}
    </p>
    <p
      class="brightfont"
      style="margin-bottom: 10px"
      data-translate="billing.modal.text"
      [innerHTML]="t('billing.modal.text', { module: t('prices.module.' + moduleName) })"
    >
      <!-- Sie können den {{'module.' + moduleName | translate}} mit einer Testlizenz einmalig für <strong>7 Tage gratis</strong> nutzen oder eine Lizenz erwerben. -->
      <!-- {{ t('billing.modal.text', { module: t('module.' + moduleName) }) }} -->
    </p>
    <!-- <ng-container ng-controller="BillingController as billingController"> -->
    <app-module-overview
      [modules]="moduleName"
      [activeWpt]="_billingComponent.activeWp.withdrawalProductTypes"
      [activeWp]="_billingComponent.activeWp"
      [organisation]="_billingComponent.organisation"
      [wptFreeTracking]="_billingComponent.wptFreeTracking"
      [freeWpt]="_billingComponent.freeWtp"
      [freeWpt]="freeWtp"
    >
    </app-module-overview>
    <!-- </ng-container> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-elegant-black" data-dismiss="modal" (click)="clear()">
      <span data-translate="entity.action.back">{{ t("global.entity.action.back") }}</span>
    </button>
  </div>
</div>
<!-- </ng-container> -->
