import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Logger } from '@shared';
const log = new Logger('Dynamic Image');

export type STATIC_IMAGE = 'AARAU' | 'BASEL';

@Component({
  selector: 'app-dynamic-image',
  templateUrl: './dynamic-image.component.html',
  styleUrls: ['./dynamic-image.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings', 'model_realEstate'],
    },
  ],
})
export class DynamicImageComponent implements OnInit {
  @Input() mode: string = '';
  @Input() defaultImage?: STATIC_IMAGE;
  data: any = [];
  defaultData: any = [];
  agvData: any = [];

  isDefaultData: boolean = true;
  isAgv: boolean = false;

  displayImage: any;
  displayIcon: any;
  displayLogo: any;
  displayPosition: any;
  displayRepeat: any;
  displaySize: any;
  public getScreenWidth: any;
  public getScreenHeight: any;
  color: any;

  origWidth = 1920;
  origHeight = 513;
  minHeight = 375;
  diffText1 = 160;
  diffText2 = 185;

  height: any;
  width: any;
  marginTopContentRow: any;
  @ViewChild('bannerImg') private bannerImg!: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.color = 'black';
    this.setUpData();
    this.setUpDefaultImages();
    const data = this.getDataSource();
    this.getRandomImage(data);
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.onResize();
  }

  setUpData() {
    var bellwald = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/bellwald/background1_descr.svg), url(../assets/images/background/architecture/bellwald/logo_1.png),url(../assets/images/background/architecture/bellwald/background1_1.jpg)',
          'background-position': 'right -20px bottom 20px,right 20px bottom 57px, center',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '17%, 13%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
      ],
    };
    var freia = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/freia/background2_descr.png), url(../assets/images/background/architecture/freia/logo_1.png),url(../assets/images/background/architecture/freia/background2.jpg)',
          'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center bottom 30%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/freia/background3_descr.png), url(../assets/images/background/architecture/freia/logo_1.png),url(../assets/images/background/architecture/freia/background3.jpg)',
          'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center top',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
        {
          // "color":"black"
        },
      ],
    };

    var leimg = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/leimg/background1_descr.svg), url(../assets/images/background/architecture/leimg/logo_1.svg),url(../assets/images/background/architecture/leimg/background1.jpg)',
          'background-position': 'right 20px bottom 20px, right 23px bottom 57px, center top 34%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
      ],
      headerStyle: [],
    };

    var mba = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/mba/background1_descr.png),url(../assets/images/background/architecture/mba/logo_1.png), url(../assets/images/background/architecture/mba/background1.jpg)',
          'background-position': 'right 20px bottom 20px,right 20px bottom 57px, center bottom',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '5%, 5%, cover',
        },
      ],
      headerStyle: [
        {
          // "color": "black"
        },
      ],
    };
    var m3 = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/m3/background1_descr.svg), url(../assets/images/background/architecture/m3/logo_1.svg),url(../assets/images/background/architecture/m3/background1.jpg)',
          'background-position': 'right -75px bottom 20px,right 20px bottom 51px, center bottom 10%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '12%, 10%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/m3/background2_descr.svg), url(../assets/images/background/architecture/m3/logo_1.svg),url(../assets/images/background/architecture/m3/background2.jpg)',
          'background-position': 'right -25px bottom 20px,right 20px bottom 51px, center bottom 12%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
        {
          // "color":"black"
        },
      ],
    };

    var rapp = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/rapp/background1_descr.svg), url(../assets/images/background/architecture/rapp/logo_1.svg),url(../assets/images/background/architecture/rapp/background1.jpg)',
          'background-position': 'right 0px bottom 20px,right 22px bottom 40px, center top 34%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 5%, cover',
        },
      ],
      headerStyle: [],
    };
    var shb = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/shb/background1_descr.svg), url(../assets/images/background/architecture/shb/logo_1.svg),url(../assets/images/background/architecture/shb/background1.jpg)',
          'background-position': 'right 0px bottom 20px,right 9px bottom 44px, center top 34%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '7%, 10%, cover',
        },
      ],
      headerStyle: [],
    };
    var ssa = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/ssa/background3_descr.svg), url(../assets/images/background/architecture/ssa/logo_1.svg),url(../assets/images/background/architecture/ssa/background3_1.jpg)',
          'background-position': 'right -16px bottom 20px,right 15px bottom 55px, center bottom',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '9%, 10%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/ssa/background4_descr.svg), url(../assets/images/background/architecture/ssa/logo_1.svg),url(../assets/images/background/architecture/ssa/background4_1.jpg)',
          'background-position': 'right 8px bottom 20px,right 15px bottom 55px, center bottom',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '15%, 10%, cover',
        },
      ],
      headerStyle: [],
    };
    var steigerconcept = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/sc/background1_descr.svg), url(../assets/images/background/architecture/sc/logo_1.png),url(../assets/images/background/architecture/sc/background1_6.jpg)',
          'background-position': 'right 15px bottom 20px,right 19px bottom 52px, center bottom',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '5% , 10% ,cover',
        },
      ],
      headerStyle: [],
    };

    var unit = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/unit/background1_descr.svg), url(../assets/images/background/architecture/unit/logo1.png),url(../assets/images/background/architecture/unit/background1.jpg)',
          'background-position': 'right -5px bottom 20px,right 19px bottom 52px, center bottom',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '5%, 10%, cover',
        },
      ],
      headerStyle: [],
    };

    var undend = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/undend/background1_descr.svg), url(../assets/images/background/architecture/undend/logo1.svg),url(../assets/images/background/architecture/undend/background1.jpg)',
          'background-position': 'right -5px bottom 20px,right 6px bottom 7px, center bottom 0%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 5%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/undend/background1_descr.svg), url(../assets/images/background/architecture/undend/logo1.svg),url(../assets/images/background/architecture/undend/background2.jpg)',
          'background-position': 'right -5px bottom 20px,right 6px bottom 7px, center bottom 0%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 5%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
        {
          // "color":"black"
        },
      ],
    };

    var walker = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/walker/background1_descr.svg), url(../assets/images/background/architecture/walker/logo1.svg),url(../assets/images/background/architecture/walker/background1.jpg)',
          'background-position': 'right -25px bottom 20px,right -10px bottom 10px, center bottom 5%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 5%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
      ],
    };

    var weberbrunner = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/weberbrunner/background1_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg),url(../assets/images/background/architecture/weberbrunner/background1.jpg)',
          'background-position': 'right -15px bottom 20px,right +20px bottom 8%, center bottom 5%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/weberbrunner/background2_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg),url(../assets/images/background/architecture/weberbrunner/background2.jpg)',
          'background-position': 'right 10px bottom 20px,right 20px bottom 8%, center bottom 5%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '5%, 10%, cover',
        },
        {
          'background-image':
            'url(../assets/images/background/architecture/weberbrunner/background3_descr.svg), url(../assets/images/background/architecture/weberbrunner/logo1.svg), url(../assets/images/background/architecture/weberbrunner/background3.jpg)',
          'background-position': 'right -18px bottom 20px,right 20px bottom 8%, center bottom 5%',
          'background-repeat': 'no-repeat, no-repeat, no-repeat',
          'background-size': '10%, 10%, cover',
        },
      ],
      headerStyle: [
        {
          opacity: '0.8',
        },
        {
          opacity: '0.8',
        },
        {
          opacity: '1',
        },
      ],
    };

    var znz = {
      images: [
        {
          'background-image':
            'url(../assets/images/background/architecture/znz/background1_descr.png), url(../assets/images/background/architecture/znz/logo_1.png), url(../assets/images/background/architecture/znz/background1.jpg)',
          'background-position': 'right 20px bottom 20px, right 20px bottom 57px, bottom center',
          'background-repeat': 'no-repeat, no-repeat,no-repeat',
          'background-size': '15%, 5%, cover',
        },
      ],
      headerStyle: [
        {
          // "color":"black"
        },
      ],
    };

    //this.data.push(unit);
    this.data.push(bellwald, freia, mba, m3, rapp, shb, ssa, steigerconcept, undend, unit, walker, weberbrunner, znz);
  }

  setUpDefaultImages() {
    // var cityImages = {
    //   'images': [
    //     {
    //       'background': 'url(\'../assets/images/background/zurich_chain_12.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     },
    //     {
    //       'background': 'url(\'../assets/images/background/background_genf_2.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     },
    //     {
    //       'background': 'url(\'../assets/images/background/background_bern_2.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     },
    //     {
    //       'background': 'url(\'../assets/images/background/background_locarno_2.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     },
    //     {
    //       'background': 'url(\'../assets/images/background/background_brugg_2.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     },

    //     {
    //       'background': 'url(\'../assets/images/background/background_neuchatel_2.jpg\') no-repeat center center',
    //       'background-size': 'cover'
    //     }
    //   ],
    //   'headerStyle': [
    //     {
    //       // "color":"black"
    //     },
    //     {
    //       // "color":"black"
    //     },
    //     {
    //       // "color": "black"
    //     }
    //     ,
    //     {
    //       // "color": "black"
    //     },
    //     {
    //       // "color": "black"
    //     },
    //     {
    //       // "color": "black"
    //     }
    //   ]

    // };
    // this.defaultData.push(cityImages);
    if (sessionStorage.getItem('bannerImg') == null) {
      console.log('sessionStrogae is null');
      var bannerImg = {
        lastArchIndex: 4,
        lastImgIndex: 0,
      };
      sessionStorage.setItem('bannerImg', JSON.stringify(bannerImg));
    }
  }

  getDataSource() {
    switch (this.defaultImage) {
      case 'AARAU':
        const data = [
          {
            images: [
              {
                'background-image': 'url(../assets/images/background/aarau.png)',
                'background-position': 'center bottom 15%',
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
              },
            ],
            headerStyle: [
              {
                // "color":"black"
              },
            ],
          },
        ];
        return data;
      case 'BASEL':
        const baselData = [
          {
            images: [
              {
                'background-image': 'url(../assets/images/background/background_basel.jpg)',
                'background-position': 'center bottom 15%',
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
              },
            ],
            headerStyle: [
              {
                // "color":"black"
              },
            ],
          },
        ];
        return baselData;
      default:
        return this.data;
    }
  }

  getARchData() {
    if (this.router.url.toLowerCase() !== '/landing_page') {
      this.isAgv = true;
      return this.agvData;
    }
    // if (Principal.showBuildingCosts()) {
    this.isDefaultData = false;
    return this.data;
    // } else {
    //     isDefaultData = true;
    //     return defaultData;
    // }
  }

  getRandomImage(data: any[]) {
    let randomNumber = Math.floor(Math.random() * data.length);
    let lastArch = JSON.parse(JSON.stringify(sessionStorage.getItem('bannerImg')));
    var wasSameOffice = randomNumber === lastArch.lastArchIndex;
    while (!this.isDefaultData && data.length > 1 && randomNumber === lastArch.lastArchIndex) {
      randomNumber = Math.floor(Math.random() * data.length);
    }
    var selectedOffice = data[randomNumber];
    var randomImageNumber = Math.floor(Math.random() * selectedOffice.images.length);

    while (
      selectedOffice.images.length > 1 &&
      wasSameOffice &&
      randomImageNumber === lastArch.lastArchIndex &&
      data.length > 1
    ) {
      randomImageNumber = Math.floor(Math.random() * selectedOffice.images.length);
    }

    var bannerImg = {
      lastArchIndex: randomNumber,
      lastImgIndex: randomImageNumber,
    };

    sessionStorage.setItem('bannerImg', JSON.stringify(bannerImg));

    this.displayImage = selectedOffice.images[randomImageNumber]['background-image'];
    this.displayPosition = selectedOffice.images[randomImageNumber]['background-position'];
    this.displayRepeat = selectedOffice.images[randomImageNumber]['background-repeat'];
    this.displaySize = selectedOffice.images[randomImageNumber]['background-size'];

    return {
      image: selectedOffice.images[randomImageNumber],
      headerStyle: selectedOffice.headerStyle[randomImageNumber],
    };
  }

  heightValueFun() {
    let imgWidth = this.bannerImg?.nativeElement?.offsetWidth;
    let wRatio = imgWidth / this.origWidth;
    return Math.max(this.minHeight, this.origHeight * wRatio);
  }

  onResize() {
    //FIXME review error handling.
    try {
      this.height = this.minHeight + 'px';
      let height = this.heightValueFun();
      this.height = height + 'px';
      this.width = this.bannerImg?.nativeElement?.offsetWidth;
      this.marginTopContentRow = this.width > 767 ? height - this.diffText1 + 'px' : height - this.diffText2 + 'px';
    } catch (e) {
      log.warn(e);
    }
  }
}
