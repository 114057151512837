<div *transloco="let t">
  <div class="rowBackgroundBlue">
    <div class="flexBoxOuter">
      <div class="flexBoxInner" style="margin-top: 33px; margin-bottom: 33px">
        <h3>{{ t("prices.license.invalid.title") }}</h3>
        <p>{{ t("prices.license.invalid.text-1") }}</p>
        <p>
          <span>{{ t("prices.license.invalid.text-2") }}</span>
          <b>{{ t("prices.telefon") }}</b> <span>{{ t("prices.license.call-us-1") }}</span>
          <a href="mailto:{{ t('products.about.text-2-2') }}">{{ t("products.about.text-2-2") }}</a>
        </p>
      </div>
    </div>
  </div>
</div>
