import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@app/i18n/i18n.service';

@Pipe({
  name: 'currentLanguage',
  pure: false,
})
export class CurrentLanguagePipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: string): string {
    return this.i18nService.language.endsWith('-CH')
      ? this.i18nService.language.split('-')[0]
      : this.i18nService.language;
  }
}
