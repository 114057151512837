<table *transloco="let t" id="priceTable" class="priceTable table flexBoxInner">
  <colgroup>
    <col style="width: 67%" />
    <col style="width: 33%; text-align: right" />
  </colgroup>
  <tbody>
    <tr *ngIf="baseLicenceDTO">
      <td style="text-align: left">{{ t("prices.licence.base-licence-yearly") }}</td>
      <td>
        <ng-template
          *ngIf="selected_withdrawal_product_types.indexOf('LCC_PACKAGE') > -1; then thenBlock; else elseBlock"
        ></ng-template>
        <ng-template #thenBlock style="text-align: right">
          <div style="text-align: right">
            {{
              (12 *
                (baseLicenceDTO.monthlyBaseFee +
                  baseLicenceDTO.monthlyBaseFee +
                  baseLicenceDTO.monthlyBaseFee +
                  baseLicenceDTO.monthlyBaseFee)) /
                100 | number: "1.2-2"
            }}
            CHF
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <div style="text-align: right">{{ (12 * baseLicenceDTO.monthlyBaseFee) / 100 | number: "1.2-2" }} CHF</div>
        </ng-template>
      </td>
    </tr>
    <tr *ngIf="num_additional_users != null && num_additional_users > 1">
      <td style="text-align: left">
        {{ t("billing.formAdditionalAccounts.number.placeholder") }}: {{ num_additional_users }}
      </td>
      <td style="text-align: right">{{ (12 * price.monthlyAdditionalAccounts) / 100 | number: "1.2-2" }} CHF</td>
    </tr>
    <tr class="solidBottomBorder">
      <td style="text-align: left">{{ t("prices.price.yearly") }}</td>
      <td>
        <div style="text-align: right">{{ price.yearlyPriceBaseLicense / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="price.yearlyModule2Discount > 0">
      <td style="text-align: left">{{ t("prices.discount.module-2") }}</td>
      <td>
        <div style="text-align: right">- {{ price.yearlyModule2Discount / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="price.yearlyModule3Discount > 0">
      <td style="text-align: left">{{ t("prices.discount.module-3") }}</td>
      <td>
        <div style="text-align: right">- {{ price.yearlyModule3Discount / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="price.yearlyModule4Discount > 0">
      <td style="text-align: left">{{ t("prices.discount.module-4") }}</td>
      <td>
        <div style="text-align: right">- {{ price.yearlyModule4Discount / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="organisation.siaIndividualMember">
      <td style="text-align: left">
        {{ price.discountInPercentSiaIndividual | number: "1.2-2" }}<span>{{ t("prices.discount.sia-single") }}</span>
      </td>
      <td>
        <div style="text-align: right">- {{ price.yearlySiaDiscount / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="organisation.siaCorporateMember">
      <td style="text-align: left">
        {{ price.discountInPercentSiaCorporate | number: "1.2-2" }}<span>{{ t("prices.discount.sia-company") }}</span>
      </td>
      <td>
        <div style="text-align: right">- {{ price.yearlySiaDiscount / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr>
      <td style="text-align: left">
        {{ price.vat | number: "1.2-2" }}<span>{{ t("prices.mwst") }}</span>
        <span *ngIf="price.vat2 != null && price.vat2 > 0 && price.daysVat != null && price.daysVat2 != null"
          >({{ price.daysVat }}/{{ price.daysVat + price.daysVat2 }})</span
        >
      </td>
      <td>
        <div style="text-align: right">{{ price.yearlyEffectiveVAT / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>
    <tr *ngIf="price.vat2 != null && price.vat2 > 0 && price.yearlyEffectiveVAT2 != null">
      <td style="text-align: left">
        {{ price.vat2 | number: "1.2-2" }}<span>{{ t("prices.mwst") }}</span>
        <span *ngIf="price.vat2 != null && price.vat2 > 0 && price.daysVat != null && price.daysVat2 != null"
          >({{ price.daysVat2 }}/{{ price.daysVat + price.daysVat2 }})</span
        >
      </td>
      <td>
        <div style="text-align: right">{{ price.yearlyEffectiveVAT2 / 100 | number: "1.2-2" }} CHF</div>
      </td>
    </tr>

    <tr class="solidTopBorder solidBottomBorder">
      <td style="text-align: left">
        <b>{{ t("prices.licence.total-yearly-vat") }}</b>
      </td>
      <td>
        <div style="text-align: right">
          <b
            *ngIf="
              price.vat2 != null && price.vat2 > 0 && price.yearlyTotalFlooredInclVAT;
              then thenBlock;
              else elseBlock
            "
          >
          </b>
          <!--          ! at yearlyTotalFlooredInclVAT is needed as compiler doesn't see it connected to the ngif above. -->
          <ng-template #thenBlock>{{ price.yearlyTotalFlooredInclVAT! / 100 | number: "1.2-2" }} CHF</ng-template>
          <ng-template #elseBlock>{{ price.yearlyTotalInclVAT / 100 | number: "1.2-2" }} CHF</ng-template>
        </div>
      </td>
    </tr>
  </tbody>
</table>
