import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //fetching i18n from static files not from api backend.
    if (
      (/(assets\/i18n)/.test(request.url) || /(assets\/resources_generic)/.test(request.url)) &&
      /(?:json)$/.test(request.url)
    ) {
      const reqUrl = `${location.origin}${request.url}`;
      request = request.clone({ url: reqUrl });
      return next.handle(request);
    }

    // FIXME: Lukas check API for reference objects. As we need to create new Mapping.
    if (!/^(http|https):/i.test(request.url)) {
      let reqUrl;
      if (request.url.startsWith('/')) {
        reqUrl = environment.serverUrl + request.url;
      } else {
        reqUrl = environment.serverUrl + '/' + request.url;
      }
      request = request.clone({ url: reqUrl });
    }

    return next.handle(request);
  }
}
