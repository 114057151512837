import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AdditionalPriceDTO, BillingPriceDTO, RegisterDTO, WithdrawalProductType } from '@generated/generatedEntities';

const log = new Logger('Billing');

//FIXME this service is not needed in root scope?
@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private http: HttpClient) {}

  fetchTentativePrice(registerDTO: RegisterDTO): Observable<BillingPriceDTO> {
    return this.http.post<BillingPriceDTO>(environment.serverUrl + '/api/billing/fetch_tentative_price', registerDTO);
  }
  fetchTentativePriceWithOrg(registerDTO: RegisterDTO): Observable<BillingPriceDTO> {
    return this.http.post<BillingPriceDTO>(
      environment.serverUrl + '/api/billing/fetch_tentative_price_org',
      registerDTO,
    );
  }

  authorizeCard(billingDTO: any): Observable<any> {
    return this.http.post('/api/billing/authorize_card', billingDTO);
  }

  authorizeCardAndPay(billingDTO: any): Observable<any> {
    return this.http.post('/api/billing/authorize_card_and_pay', billingDTO);
  }

  insertAlias(initTransactionDTO: any): Observable<any> {
    return this.http.post('/api/billing/insert_alias', initTransactionDTO);
  }

  assertAlias(assertAliasDTO: any): Observable<any> {
    return this.http.post('/api/billing/assert_alias', assertAliasDTO);
  }

  initTransaction(initTransactionDTO: any): Observable<any> {
    return this.http.post('/api/billing/init_transaction', initTransactionDTO);
  }
  removeLicenseRenewal(): Observable<any> {
    return this.http.post('/api/billing/remove_license_renewal', {});
  }

  enableLicenseRenewal(): Observable<any> {
    return this.http.post('/api/billing/enable_license_renewal', {});
  }

  updateLicense(updateLicenseReqDTO: any): Observable<any> {
    return this.http.post('/api/billing/update_license', updateLicenseReqDTO);
  }
  directPaymentFullModule2020(updateLicenseReqDTO: any): Observable<any> {
    return this.http.post('/api/billing/direct_payment_full_module_2020', updateLicenseReqDTO);
  }
  adminUpdateLicenseUsers(updateLicenseReqDTO: any, orgId: number): Observable<any> {
    return this.http.post('/api/billing/admin_update_license_users/' + orgId, updateLicenseReqDTO);
  }
  adminUpdateLicenseModules(updateLicenseReqDTO: any, orgId: string): Observable<any> {
    return this.http.post('/api/billing/admin_update_license_modules/' + orgId, updateLicenseReqDTO);
  }
  fetchAdditionalPrice(
    numAdditionalUsers: number,
    additionalWpts: WithdrawalProductType[],
    freeDays: number,
  ): Observable<AdditionalPriceDTO> {
    return this.http.post<AdditionalPriceDTO>('/api/billing/fetch_additional_price', {
      numAdditionalUsers: numAdditionalUsers,
      additionalWpts: additionalWpts,
      freeDays: freeDays,
    });
  }

  fetchAdditionalPriceForeign(
    wpBefore: any,
    freePairsBefore: any,
    wpAfter: any,
    freePairsAfter: any,
    validUntilDate: any,
    mutationDate: any,
  ): Observable<any> {
    return this.http.post('/api/billing/fetch_additional_price_foreign', {
      wpBefore: wpBefore,
      freePairsBefore: freePairsBefore,
      wpAfter: wpAfter,
      freePairsAfter: freePairsAfter,
      validUntilDate: validUntilDate,
      mutationDate: mutationDate,
    });
  }

  fetchPriceRelief(numUsersToRemove: any): Observable<any> {
    return this.http.post('/api/billing/fetch_price_relief', { numUsersToRemove: numUsersToRemove });
  }

  fetchPrice(wpId: any): Observable<any> {
    return this.http.get('/api/billing/fetch_price?wpId=' + wpId);
  }

  isAuthorizedAndNotExpired(): Observable<any> {
    return this.http.get('/api/billing/is_authorized_and_not_expired');
  }

  licenseState(): Observable<any> {
    return this.http.get('/api/billing/license_state');
  }

  billLicenses(): Observable<any> {
    return this.http.get('/api/billing/bill_licenses');
  }
}
