import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { RoleTypes } from '../../generated/extended';

@Directive({
  selector: '[kvHasAnyAuthority]',
})
export class HasAnyAuthorityDirective {
  private authorities: RoleTypes[];

  constructor(
    private authenticationService: AuthenticationService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.authorities = [];
  }

  @Input()
  set kvHasAnyAuthority(value: RoleTypes | RoleTypes[]) {
    if (Array.isArray(value)) {
      this.authorities = value;
    } else {
      this.authorities = [value];
    }
    this.updateView();
    // Get notified each time authentication state changes.
    this.authenticationService.getAuthenticationState().subscribe({
      next: (user) => {
        this.updateView();
      },
    });
  }

  private updateView(): void {
    const hasAnyAuthority = this.authenticationService.hasAnyAuthority(this.authorities);
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
