import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-license-table-fix-price',
  templateUrl: './license-table-fix-price.component.html',
  styleUrls: ['./license-table-fix-price.component.scss'],
})
export class LicenseTableFixPriceComponent implements OnInit {
  @Input() activeWp: any;
  @Input() basecontainer: any;
  @Input() baselicense: any;
  @Input() freewpt: any;
  @Input() organisation: any;
  @Input() price: any;
  @Input() wptfreetracking: any;

  constructor() {}

  ngOnInit(): void {}
}
