import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { WithdrawalProductType, WptFreeTrackingDTO } from '@generated/generatedEntities';
import { BehaviorSubject, firstValueFrom, Observable, tap, timer } from 'rxjs';
import { AuthenticationService } from '@app/auth';
import { Logger } from '@shared';
import { isPast, parseISO } from 'date-fns';

const log = new Logger('WptService');
@Injectable({
  providedIn: 'root',
})
export class WptFreeTrackingService {
  private baseUrl: string = '/api/wpt_free_tracking';

  private _cache$ = new BehaviorSubject<WptFreeTrackingDTO[]>([]);

  public usedWptFreeTracking: WithdrawalProductType[] = [];

  static instance?: WptFreeTrackingService;

  constructor(private _http: HttpClient) {
    if (WptFreeTrackingService.instance !== undefined) {
      log.debug('WptFreeTrackingService already running.');
    }
    log.debug('service started');
    WptFreeTrackingService.instance = this;
  }

  getWptFreeTrackingCache(): Observable<WptFreeTrackingDTO[]> {
    return this._cache$.asObservable();
  }

  queryByOrg(orgId: number): Observable<WptFreeTrackingDTO[]> {
    return this._http
      .get<WptFreeTrackingDTO[]>(`${environment.serverUrl}/api/wpt_free_tracking/query_by_org/${orgId}`)
      .pipe(
        tap((value) => {
          this._cache$.next(value);
          this.usedWptFreeTracking = value
            .filter((value1) => isPast(new Date(value1.dateTimeFreeUntil)))
            .map((value1) => value1.wpt);
        }),
      );
  }

  queryAllWptsWithFreeMonth(): Observable<any> {
    return this._http.get(`${environment.serverUrl}/api/wpt_free_tracking/query_all_wpts_with_free_month`);
  }

  activateModule(wpt: WithdrawalProductType): Promise<WptFreeTrackingDTO> {
    return firstValueFrom(
      this._http.post<WptFreeTrackingDTO>(`${environment.serverUrl}/api/wpt_free_tracking/activate_module`, {
        wpt: wpt,
      }),
    );
  }

  /**
   * It takes a WithdrawalProductType object as a parameter and returns an Observable of type string
   * @param {WithdrawalProductType} wpt - WithdrawalProductType
   * @returns {"hey": "ok"}
   */
  cancelModule(wpt: WithdrawalProductType): Promise<string> {
    return firstValueFrom(
      this._http.post<string>(environment.serverUrl + '/api/wpt_free_tracking/cancel_module', { wpt: wpt }),
    );
  }

  selectModuleToBuy(orgId: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/wpt_free_tracking/select_module_to_buy', orgId);
  }

  deselectModuleToBuy(orgId: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/deselect_module_to_buy', orgId);
  }

  save(id: string | number | any, obj: any): Observable<any> {
    return this._http.post(environment.serverUrl + this.baseUrl, obj);
  }

  update(id: string | number | any, obj: any): Observable<any> {
    return this._http.put(environment.serverUrl + this.baseUrl, obj);
  }

  delete(id: string | number | any): Observable<any> {
    return this._http.delete(environment.serverUrl + this.baseUrl + '/' + id);
  }
}
