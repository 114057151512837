import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OutRealEstateDTO, RebuildEstimationOutputDTO, WithdrawalProductType } from '@generated/generatedEntities';

export const RealEstateActions = createActionGroup({
  source: 'RealEstate/API',
  events: {
    'Get Empty RealEstate': props<{ wpt: WithdrawalProductType; tasks: WithdrawalProductType[] }>(),
    'Load RealEstate': emptyProps(),
    'Load RealEstate Success': (data: OutRealEstateDTO) => data,
    'Update RealEstate': (data: OutRealEstateDTO) => data,
    'Load RealEstate Failure': props<{ error: unknown }>(),
    'Estimate LCA Energy Needs': emptyProps(),
    'Estimate LCA Energy Needs Success': (data: RebuildEstimationOutputDTO) => data,
    'Estimate LCA Energy Needs Failure': props<{ error: unknown }>(),
    'Estimate LCA Energy Performance': emptyProps(),
    'Estimate LCA Energy Performance Success': (data: RebuildEstimationOutputDTO) => data,
    'Estimate LCA Energy Performance Failure': props<{ error: unknown }>(),
    'Get LCA Plot CRREM CO2': (data: any) => data,
    'Get LCA Plot CRREM CO2 Success': props<{ base64: string }>(),
    // 'Get LCA Plot CRREM CO2 Success': (data: any) => data,
    'Get LCA Plot CRREM CO2 Error': props<{ error: unknown }>(),
  },
});
