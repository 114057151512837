<div class="kv-login mat-typography bg-white flex-col" *transloco="let t">
  <div class="header-login flex-row flex-1">
    <h4 style="align-self: center; margin-bottom: 0; margin-left: 1em" class="flex-grow-9">{{ t("login.title") }}</h4>
    <button class="flex-grow-0" style="background-color: transparent" mat-icon-button [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="login-container flex-row flex-1 justify-center">
    <mat-card class="login-box" style="width: 80%">
      <h1 class="login-title">{{ t("login.title") }}</h1>
      <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
        <div
          class="login-error alert kw-attention"
          [hidden]="!error || isLoading"
          [innerHtml]="t('login.messages.error.authentication')"
        ></div>
        <br />
        <div class="login-fields flex-col">
          <mat-form-field [hideRequiredMarker]="true" style="display: inline-block; height: 38px" appearance="fill">
            <mat-label>{{ t("global.form.email") }}</mat-label>
            <input type="text" matInput formControlName="username" autocomplete="username" required />
            <!--            <mat-error *ngIf="loginForm.controls['username'].invalid && loginForm.controls['username'].touched">-->
            <!--              <span >Username is required</span>-->
            <!--            </mat-error>-->
          </mat-form-field>
          <mat-form-field [hideRequiredMarker]="true" style="display: inline-block; height: 38px" appearance="fill">
            <mat-label>{{ t("login.form.password") }}</mat-label>
            <input type="password" matInput formControlName="password" autocomplete="current-password" required />
            <!--            <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">-->
            <!--              <span >Password is required</span>-->
            <!--            </mat-error>-->
          </mat-form-field>
          <mat-slide-toggle color="primary" formControlName="rememberMe">{{
            t("login.form.rememberme")
          }}</mat-slide-toggle>
          <br />
          <button
            mat-button
            color="primary"
            type="submit"
            [disabled]="loginForm.invalid || isLoading"
            class="submit-btn"
          >
            <!-- <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader> -->
            <span>{{ t("login.form.button") }}</span>
          </button>
          <div style="margin-top: 20px">
            <a routerLink="reset-password">{{ t("login.password.forgot") }}</a>
          </div>
          <div>
            {{ t("global.messages.info.register") }}
            <a routerLink="register" routerLinkActive="active" (click)="dialogRef.close()">{{
              t("global.messages.info.register2")
            }}</a>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
