import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';

import { TranslocoService, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ErrorStateMatcher } from '@angular/material/core';
import { Meta } from '@angular/platform-browser';
import { AuthenticationService } from '@app/auth';

export interface PeriodicElement {
  name: string;
  Unternehmensgrosse: string;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices', 'global', 'register', 'settings', 'model_realEstate'],
    },
  ],
})
export class RegistrationComponent implements OnInit {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
  registerForm!: UntypedFormGroup;

  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  billingEmail = new UntypedFormControl('', [Validators.required, Validators.email]);

  selected = 'Schweiz';
  billingContactEqualsOrgContact = false;

  constructor(
    private translocoService: TranslocoService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private metaTagService: Meta,
    private authenticationService: AuthenticationService,
  ) {
    this.registerForm = this.formBuilder.group({
      // emailFormControl: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      orgName: ['', Validators.required],
      orgZipcode: ['', Validators.required],
      orgPlace: ['', Validators.required],
      orgCountry: ['', Validators.required],
      orgPhone: ['', Validators.required],
      orgNumEmployees: ['', Validators.required],
      orgSiaIndividualMember: [''],
      orgSiaCorporateMember: [''],
      additionalAccounts: ['', Validators.required],
      billingContactEqualsOrgContact: [''],
      billingOrgName: ['', Validators.required],
      billingFirstName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      billingLastName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50)]],
      billingDepartment: [''],
      billingStreet: ['', Validators.required],
      orgZipcodeBilling: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      orgPlaceBilling: ['', [Validators.required]],
      acceptConditions: [''],
      acceptedNoAnonymous: [''],
    });
  }

  ngOnInit() {
    // console.warn(this.translocoService.getTranslation());
    this.initializeData();
  }

  initializeData() {
    this.invokeMetaTags();
  }

  invokeMetaTags() {
    this.metaTagService.addTags([
      {
        name: 'description',
        content:
          'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.',
      },
      { name: 'Keywords', content: 'Mix' },
      // OpenGraph
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'kennwerte' },
      {
        property: 'og:description',
        content:
          'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.',
      },
      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@kennwerte' },
      { name: 'twitter:title', content: 'kennwerte' },
      {
        name: 'twitter:description',
        content:
          'Jetzt 7 Tage gratis testen. Das Schweizer Tool zur Kostenberechnung für Architekten, Bauherren und Projektentwickler. Einfach, praktisch und transparent.',
      },
    ]);
  }
}
