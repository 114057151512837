<div *transloco="let t">
  <div class="modal-header">
    <button aria-label="Close" data-dismiss="modal" class="close" type="button" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Accounts entfernen</h4>
  </div>
  <div class="modal-body pad" *ngIf="priceRelief">
    <div class="centeredBoxText" style="margin-top: 33px">
      <ul class="list-unstyled">
        <li *ngIf="!freeMode">Die Accounts werden erst ab Erneuerungsdatum Ihrer Lizenz entfernt.</li>
        <li *ngIf="freeMode">Die Accounts werden unverzüglich entfernt.</li>
      </ul>
    </div>
    <form
      [formGroup]="formRemoveAccounts"
      name="formRemoveAccounts"
      role="form"
      novalidate
      (submit)="removeAccounts()"
      style="margin-top: 66px"
    >
      <div class="centeredBox flexBoxOuter">
        <table class="table table-blue-border flexBoxInner">
          <thead>
            <tr>
              <th style="text-align: right"></th>
              <th style="text-align: left"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 50%; text-align: right" *ngIf="!freeMode">
                Senkung der jährlichen Gebühr exkl. MWST nach Lizenzerneurung <br />(ab {{ until | date: "dd/MM/yy" }})
              </td>
              <td style="width: 50%; text-align: right" *ngIf="freeMode">
                Senkung jährliche Gebühr exkl. MWST nach Ablauf der Gratiswoche <br />(ab
                {{ until | date: "dd/MM/yy" }})
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  {{ priceRelief / 100 | number: "1.2-2" }}
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 50%; text-align: right">{{ vat | number: "1.2-2" }}% MWST</td>
              <td style="width: 50%">
                <div style="width: 90px; text-align: right">{{ effectiveVat / 100 | number: "1.2-2" }} CHF</div>
              </td>
            </tr>
            <tr>
              <td style="width: 50%; text-align: right" *ngIf="!freeMode">
                <b
                  >Senkung der jährlichen Gebühr inkl. MWST nach Lizenzerneuerung<br />(ab
                  {{ until | date: "dd/MM/yy" }})</b
                >
              </td>
              <td style="width: 50%; text-align: right" *ngIf="freeMode">
                <b
                  >Senkung jährliche Gebühr inkl. MWST nach Ablauf der Gratiswoche <br />(ab
                  {{ until | date: "dd/MM/yy" }})</b
                >
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  <b> {{ reliefInclVat / 100 | number: "1.2-2" }} CHF </b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="centeredBoxText flexBoxOuter">
        <table class="table table-blue-border flexBoxInner">
          <thead>
            <tr>
              <th style="text-align: right"></th>
              <th style="text-align: left"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width: 50%; text-align: right">Anzahl Accounts zu entfernen</td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  {{ numUsersToRemove | number: "1.2-2" }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="centeredBoxText" style="margin-top: 20px" [hidden]="authorized">
        <button type="submit" [disabled]="formRemoveAccounts.invalid" class="btn btn-elegant-black" *ngIf="!isClicked">
          Jetzt abbestellen
        </button>
      </div>
      <div class="centeredBoxText" style="margin-top: 33px">
        <div
          class="alert kw-attention"
          [hidden]="!errorPaymentFormError"
          data-translate="billing.messages.error.paymentformerror"
        >
          <strong>Entfernung der Accounts fehlgeschlagen!</strong> Bitte versuchen Sie es später nochmals
        </div>
        <div class="alert kw-attention" [hidden]="!invalidCard">
          <strong>Entfernung der Accounts fehlgeschlagen!</strong> Bitte Kreditkarte aktualisieren
        </div>
        <div class="alert kw-attention" [hidden]="!authorized" *ngIf="!freeMode">
          <strong>Auftrag zur Entfernung der Accounts erfolgreich hinterlegt!</strong>
        </div>
        <div class="alert kw-attention" [hidden]="!authorized" *ngIf="freeMode">
          <strong>Entfernung der Accounts erfolgreich!</strong>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-elegant-black" (click)="cancel()" translate="global.navigation.close">
      Close
    </button>
  </div>
</div>
