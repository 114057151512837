import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AccountService } from '@app/account/account.service';
import { WithdrawalProductDTO } from '@generated/generatedEntities';
import { addDays, format } from 'date-fns/fp';
import {
  AdditionalPriceDTO,
  BillingPriceDTO,
  OrganisationDTO,
  WithdrawalProductType,
} from '@generated/generatedEntities';
import * as date_fns from 'date-fns';

@Component({
  selector: 'app-modul-buy',
  templateUrl: './modul-buy.component.html',
  styleUrls: ['./modul-buy.component.scss'],
})
export class ModulBuyComponent implements OnInit {
  @Input() hasFreeWeek: boolean = false;
  @Input() currentLicenceValidUntil!: Date;
  @Input() newLicencePrice: BillingPriceDTO | null = null;
  @Input() differentialPrice: AdditionalPriceDTO | null = null;

  @Input() organisation!: OrganisationDTO;
  @Input() numAdditionalUsers!: number;
  @Input() activeWp!: WithdrawalProductDTO;
  @Input() enableNumAddUsers: boolean = false;
  @Input() productTypes: WithdrawalProductType[] = [];
  @Input() numberOfCurrentWpts: number = 0;

  @Output() orderProductsEvent = new EventEmitter<WithdrawalProductType[]>();
  @Output() orderFreeProductsEvent = new EventEmitter<WithdrawalProductType[]>();

  freeWeekFrom!: Date;
  freeWeekUntil!: Date;
  currentLicenceValidFrom!: Date;
  newLicenceValidFrom!: Date;
  newLicenceValidUntil!: Date;

  currentDate: Date | null = null;

  constructor(private _accountService: AccountService) {}
  ngOnChanges(detectChanges: SimpleChanges): void {}

  ngOnInit(): void {
    const now = date_fns.parseISO(new Date().toISOString());
    this.freeWeekFrom = now;
    this.freeWeekUntil = new Date(new Date(this.freeWeekFrom.getTime()).setDate(this.freeWeekFrom.getDate() + 6));

    this.currentLicenceValidFrom = this.hasFreeWeek ? this.addDays(this.freeWeekUntil, 1) : now;
    this.newLicenceValidFrom = this.hasFreeWeek ? this.addDays(this.freeWeekUntil, 1) : now;

    this.newLicenceValidUntil = this.addDays(this.addYears(this.newLicenceValidFrom, 1), -1);
    this.currentDate = date_fns.parseISO(new Date().toISOString());
    //isLicenceValid
    this.currentLicenceValidUntil = date_fns.parseISO(new Date(this.activeWp.dateTimeValidUntil).toISOString());
    if (this.activeWp.licenseState === 'INVALID') {
      //we need to check the "next licenses instead of the current one"-
      if (date_fns.isBefore(this.currentLicenceValidUntil, now)) {
        this.currentLicenceValidUntil = date_fns.addYears(now, 1);
        this.currentLicenceValidFrom = now;
      }
    }
  }

  private addDays(date: Date, days: number): Date {
    return new Date(new Date(date).setDate(date.getDate() + days));
  }

  private addYears(date: Date, years: number): Date {
    return new Date(new Date(date).setFullYear(date.getFullYear() + years));
  }

  onOrderClick() {
    this.orderProductsEvent.emit(this.productTypes);
  }

  onOrderFreeClick() {
    this.orderFreeProductsEvent.emit(this.productTypes);
  }
}
