import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { PartialNullable } from '@generated/extended';
import {
  InviteRequestDTO,
  OrganisationDTO,
  OrganisationPageableDTO,
  OrganisationRoleDTO,
  UserByOrgInviteDTO,
  UserDTO,
} from '@generated/generatedEntities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(private _http: HttpClient) {}

  current(): Observable<OrganisationDTO> {
    return this._http.get<OrganisationDTO>('/api/organisation/current');
  }

  query_users(id: number): Observable<UserDTO[]> {
    return this._http.get<UserDTO[]>(environment.serverUrl + '/api/organisation/user/' + id);
  }

  query_all(inviteReqDTO: InviteRequestDTO): Observable<HttpResponse<InviteRequestDTO>> {
    const data = this.createRequestOption(inviteReqDTO);
    return this._http.get<InviteRequestDTO>(environment.serverUrl + '/api/organisation/invite_query', {
      params: data,
      observe: 'response',
    });
  }

  get(id: number): Observable<OrganisationDTO> {
    return this._http.get<OrganisationDTO>(environment.serverUrl + '/api/organisation/' + id);
  }

  query(id: number): Observable<OrganisationDTO> {
    return this._http.get<OrganisationDTO>(environment.serverUrl + '/api/organisation/' + id);
  }

  Query(page: PartialNullable<OrganisationPageableDTO>): Observable<HttpResponse<OrganisationDTO[]>> {
    const cast = page as OrganisationPageableDTO;
    const options = this.createRequestParamOption(cast);
    return this._http.get<OrganisationDTO[]>(`${environment.serverUrl}/api/organisation`, {
      params: options,
      observe: 'response',
    });
  }

  /**
   * @param id
   * @param email
   * Add user to Organisation
   */
  save(adminRegisterDTO: any): Observable<OrganisationDTO> {
    return this._http.post<OrganisationDTO>(environment.serverUrl + '/api/organisation', adminRegisterDTO);
  }

  update(id: number, role: OrganisationRoleDTO): Observable<UserDTO> {
    return this._http.put<UserDTO>(environment.serverUrl + '/api/organisation/' + id, role);
  }

  delete(id: number, email: string): Observable<any> {
    const data = this.createRequestOption({ email: email });
    return this._http.delete(environment.serverUrl + '/api/organisation/user/' + id, {
      params: data,
      observe: 'response',
    });
  }

  /**
   *
   * @param id
   * @param email
   * join as user
   */
  joinAsUser(id: number, email: string): Observable<UserDTO> {
    return this._http.post<UserDTO>(environment.serverUrl + '/api/organisation/user/' + id, email);
  }

  changeRole(id: number, role: OrganisationRoleDTO): Observable<UserDTO> {
    return this._http.put<UserDTO>(environment.serverUrl + '/api/organisation/user/' + id, role);
  }

  deleteUser(id: number, email: OrganisationRoleDTO): Observable<any> {
    const data = this.createRequestOption({ email: email });
    return this._http.delete(environment.serverUrl + '/api/organisation/user/' + id, {
      params: data,
      observe: 'response',
    });
  }

  /**
   *
   * @param req
   * Organisation Invite
   */
  invite(id: number, email: OrganisationRoleDTO): Observable<any> {
    const data = this.createRequestOption({ id: id, email: email });
    return this._http.get(environment.serverUrl + '/api/organisation/invite', { params: data, observe: 'response' });
  }

  getInvite(id: number, email: OrganisationRoleDTO): Observable<any> {
    const data = this.createRequestOption({ email: email });
    return this._http.get(environment.serverUrl + '/api/organisation/admin/invite', {
      params: data,
      observe: 'response',
    });
  }

  deleteInvite(id: number, email: InviteRequestDTO): Observable<any> {
    // const data = this.createRequestOption({ id: id, email: email });
    const data = { id: id, email: email };
    return this._http.delete(environment.serverUrl + '/api/organisation/invite', { body: data, observe: 'response' });
  }

  // Send the register accept invite
  registerAccept(userByOrgInviteDTO: UserByOrgInviteDTO) {
    return this._http.post<OrganisationDTO>(environment.serverUrl + '/api/register_by_org_invite', userByOrgInviteDTO);
  }

  /*
   * get api for invite register-accept
   **/
  getInviteKey(id: string): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/invite/' + id);
  }

  getNumOrganisations(): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/organisation/count');
  }

  createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
      // Object.keys(req).forEach((key) => {
      //   if (key !== 'id') {
      //     options = options.set(key, req[key]);
      //   }
      // });
      if (req.id) {
        // req.id.forEach((val: any) => {
        options = options.append('id', req.id);
        // });
      }
      // Object.keys(req).forEach((key) => {
      //   if (key !== 'email') {
      //     options = options.set(key, req[key]);
      //   }
      // });
      if (req.email) {
        // req.email.forEach((val: any) => {
        options = options.append('email', req.email);
        // });
      }
    }
    return options;
  };

  createRequestParamOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
      Object.keys(req).forEach((key) => {
        if (key !== 'sort') {
          options = options.set(key, req[key]);
        }
      });
      if (req.sort) {
        req.sort.forEach((val: any) => {
          options = options.append('sort', val);
        });
      }
    }
    return options;
  };

  /* Admin Apis*/

  adminGet(data: any): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/organisation/admin/' + data.id);
  }

  adminUpdate(orgObj: any, adminUpdateDTO: any): Observable<any> {
    return this._http.put(environment.serverUrl + '/api/organisation/admin/' + orgObj.id, adminUpdateDTO);
  }

  adminUpdateLicense(obj: any, adminUpdateLicense: any): Observable<any> {
    return this._http.put(environment.serverUrl + '/api/organisation/admin/update_license', adminUpdateLicense);
  }

  adminEnableLicenseRenewal(id: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/organisation/admin/enable_license_renewal', id);
  }

  adminDisableLicenseRenewal(id: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/organisation/admin/disable_license_renewal', id);
  }
}
