import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '@app/account/account.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { LoaderUtilService } from '@app/calculators/components/util/loader-util.service';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { WptFreeTrackingService } from '@app/core/services/billing/wpt-free-tracking/wpt-free-tracking.service';
import { ValidationDataService } from '@app/core/services/validation/validation-data.service';
import {
  AdditionalPriceDTO,
  BillingPriceDTO,
  InitOrganisationDTO,
  ManagedUserVM,
  OrganisationDTO,
  RegisterDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addYears, parseJSON, isAfter, isBefore, isValid } from 'date-fns';

export type WithdrawalProductTypeBuyable =
  | 'LCC_PACKAGE'
  | 'BUILDING_COSTS_AND_DATES'
  | 'OPERATING_COSTS'
  | 'REBUILD_COSTS';

@UntilDestroy()
@Component({
  selector: 'app-module-overview',
  templateUrl: './module-overview.component.html',
  styleUrls: ['./module-overview.component.scss'],
})
export class ModuleOverviewComponent implements OnInit {
  private static readonly ALL_MODULES: null = null;

  @Input() activeWp!: WithdrawalProductDTO;
  @Input() activeWpt: WithdrawalProductType[] = [];
  @Input() freeWpt: WithdrawalProductType[] = [];
  @Input() organisation!: OrganisationDTO;
  @Input() wptFreeTracking: WptFreeTrackingDTO[] = [];
  @Input() modules: (WithdrawalProductTypeBuyable | 'LCC')[] = [
    'LCC_PACKAGE',
    'BUILDING_COSTS_AND_DATES',
    'OPERATING_COSTS',
    'REBUILD_COSTS',
    'LCC',
  ];
  @Output() moduleCanceled = new EventEmitter<WithdrawalProductType>();
  @Output() moduleActivated = new EventEmitter<WithdrawalProductType>();
  @Output() modulesOrdered = new EventEmitter<WithdrawalProductType[]>();
  @Output() modulesBought = new EventEmitter<WithdrawalProductType[]>();
  @Output() licenceChanged = new EventEmitter<void>();

  /**
   * Provide a subset of modules that should be showed or null for all modules.
   * Default value: null.
   */
  @Input() filterModules: (WithdrawalProductType | 'LCC')[] | null = ModuleOverviewComponent.ALL_MODULES;

  showModuleSelction: boolean = false;

  selectedWithdrawalProductTypes: WithdrawalProductType[] = [];
  selectedFreeWithdrawalProductTypes: WithdrawalProductType[] = [];

  selectedWptToCancel: WithdrawalProductType | null = null;
  selectedFreeWptToCancel: WithdrawalProductType | null = null;
  successMessage: string = '';
  priceFree: BillingPriceDTO | null = null;
  additionalPrice: AdditionalPriceDTO | null = null;
  priceLoaded: boolean = false;

  constructor(
    private billingService: BillingService,
    private authService: AuthenticationService,
    private credentialService: CredentialsService,
    private wptFreeTrackingService: WptFreeTrackingService,
    public validationDataService: ValidationDataService,
    private loaderUtilService: LoaderUtilService,
    private _accountService: AccountService,
  ) {}
  ngOnInit(): void {
    this.initializeData();
  }
  initializeData() {
    this.getActiveWp();
  }

  getActiveWp() {
    this._accountService.activeWp.pipe(untilDestroyed(this)).subscribe({
      next: (activeWp) => {
        if (activeWp) {
          this.activeWp = activeWp;
          console.log(this.activeWp);
        }
      },
    });
  }

  getNewPriceFree(wpts: WithdrawalProductType[]) {
    let initOrg: InitOrganisationDTO = JSON.parse(JSON.stringify(this.organisation));
    initOrg.withdrawalProductTypes = wpts;
    this.authService
      .identity()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (account) => {
          if (account) {
            let registerDTO: RegisterDTO = {
              account: account as ManagedUserVM,
              organisation: initOrg,
              numAdditionalUsers: this.activeWp.numMaxUsers - 1,
              token: '',
            };
            this.billingService
              .fetchTentativePriceWithOrg(registerDTO)
              .pipe(untilDestroyed(this))
              .subscribe({
                next: (res) => {
                  this.priceFree = res;
                  this.priceLoaded = true;
                },
                error: (error) => {
                  this.priceLoaded = false;
                  console.error('bill: ', error);
                },
              });
          }
        },
      });
  }

  getNewDiffPrice(wpts: WithdrawalProductType[], freeDays: number) {
    let initOrg: InitOrganisationDTO = JSON.parse(JSON.stringify(this.organisation));
    initOrg.withdrawalProductTypes = wpts;
    this.billingService
      .fetchAdditionalPrice(0, wpts, freeDays)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: AdditionalPriceDTO) => {
          //console.log(response);
          this.priceLoaded = true;
          this.additionalPrice = response;
        },
        error: (error) => {
          this.priceLoaded = false;
          console.error('fetch add price: ', error);
        },
      });
  }

  selectFreeWpt(wpt: WithdrawalProductType) {
    this.selectedWptToCancel = null;
    if (!this.selectedFreeWithdrawalProductTypes.includes(wpt)) {
      this.selectedFreeWithdrawalProductTypes.push(wpt);
    } else {
      var index = this.selectedFreeWithdrawalProductTypes.indexOf(wpt);
      this.selectedFreeWithdrawalProductTypes.splice(index, 1);
    }
    this.selectedWptToCancel = null; // remove cancellation (if selected previously)
    this.selectedFreeWptToCancel = null; // remove cancellation (if selected previously)
    this.selectedWithdrawalProductTypes.splice(0, this.selectedWithdrawalProductTypes.length); // remove non-free products (if selected previously)
    //new Set(this.selectedFreeWithdrawalProductTypes);
    let selectedFreeWithdrawalProductTypes: WithdrawalProductType[] = [
      ...new Set(this.selectedFreeWithdrawalProductTypes),
    ];
    if (this.activeWp.licenseState === 'FREE' || this.activeWp.licenseState === 'INVALID') {
      //TODO LCC-67 check if 31 or 7 Beni/Michael/Lukas
      this.getNewPriceFree(selectedFreeWithdrawalProductTypes);
    } else {
      //TODO LCC-67 check if 31 or 7 Beni/Michael/Lukas
      this.getNewDiffPrice(selectedFreeWithdrawalProductTypes, 7);
    }
  }

  checkFilter(wpt: WithdrawalProductType | 'LCC'): boolean {
    if (this.filterModules === ModuleOverviewComponent.ALL_MODULES) return true;
    else return this.filterModules.includes(wpt);
  }

  checkNonFreeWpt(wpt: WithdrawalProductType) {
    let freeWpt: WptFreeTrackingDTO | null = null;
    //var freeWpt = _.find(this.wptFreeTracking, {"wpt": wpt});
    for (let i = 0; i < this.wptFreeTracking.length; i++) {
      if (this.wptFreeTracking[i].wpt === wpt) {
        freeWpt = this.wptFreeTracking[i];
      }
    }
    //console.log('freeWpt',freeWpt)
    if (freeWpt != null) {
      //console.log('(new Date() > new Date(freeWpt.dateTimeFreeUntil))',(new Date() > new Date(freeWpt.dateTimeFreeUntil)))
      //return new Date() > new Date(freeWpt.dateTimeFreeUntil);
      return isAfter(new Date(), new Date(freeWpt.dateTimeFreeUntil));
      //return (moment.utc().isAfter(freeWpt.dateTimeFreeUntil));
    } else {
      return false;
    }
  }

  findWptFreeTracking(wpt: WithdrawalProductType): WptFreeTrackingDTO | null {
    return this.wptFreeTracking.find((x: WptFreeTrackingDTO) => x.wpt == wpt) ?? null;
  }

  isParentPackageActive(wpt: WithdrawalProductTypeBuyable | 'LCC'): boolean {
    switch (wpt) {
      case 'LCC_PACKAGE':
        return false;
      case 'BUILDING_COSTS_AND_DATES':
      case 'OPERATING_COSTS':
      case 'REBUILD_COSTS':
      case 'LCC':
        return this.activeWpt?.includes('LCC_PACKAGE');
    }
  }

  isParentPackageSelected(wpt: WithdrawalProductTypeBuyable | 'LCC'): boolean {
    const selectedWpts = this.selectedWithdrawalProductTypes.concat(this.selectedFreeWithdrawalProductTypes);
    switch (wpt) {
      case 'LCC_PACKAGE':
        return false;
      case 'BUILDING_COSTS_AND_DATES':
      case 'OPERATING_COSTS':
      case 'REBUILD_COSTS':
      case 'LCC':
        return selectedWpts.includes('LCC_PACKAGE');
    }
  }

  checkNonFreeAndActiveWpt(freeTrack: WptFreeTrackingDTO) {
    return (
      this.checkNonFreeWpt(freeTrack.wpt) &&
      !isValid(parseJSON(freeTrack.dateTimeInactive ? freeTrack.dateTimeInactive.toString() : ''))
    );
  }

  checkNonFreeAndInactiveWpt(freeTrack: WptFreeTrackingDTO) {
    // console.log(new Date().toISOString(), freeTrack);
    return (
      isAfter(new Date(), new Date(freeTrack.dateTimeInactive)) &&
      //needed otherwise we get null errors due to dateTimeInactive being null and toString cant be called
      isValid(parseJSON(freeTrack.dateTimeInactive ? freeTrack.dateTimeInactive.toString() : ''))
    );
  }

  checkFreeAndActiveWpt(freeTrack: WptFreeTrackingDTO) {
    return isBefore(new Date(), new Date(freeTrack.dateTimeFreeUntil)) && freeTrack.dateTimeInactive == null;
  }

  checkFreeAndInactiveWpt(freeTrack: WptFreeTrackingDTO) {
    return (
      isBefore(new Date(), new Date(freeTrack.dateTimeFreeUntil)) &&
      //needed otherwise we get null errors due to dateTimeInactive being null and toString cant be called
      isValid(parseJSON(freeTrack.dateTimeInactive ? freeTrack.dateTimeInactive.toString() : ''))
    );
  }

  togglePrepareCancelModul(wpt: WithdrawalProductType, isFree: boolean) {
    this.selectedWithdrawalProductTypes = [];
    this.selectedFreeWithdrawalProductTypes = [];
    this.priceFree = null;
    if (isFree) {
      this.selectedWptToCancel = null;
      if (this.selectedFreeWptToCancel === wpt) {
        this.selectedFreeWptToCancel = null;
      } else {
        this.selectedFreeWptToCancel = wpt;
      }
    } else {
      this.selectedFreeWptToCancel = null;
      if (this.selectedWptToCancel === wpt) {
        this.selectedWptToCancel = null;
      } else {
        this.selectedWptToCancel = wpt;
      }
    }
  }

  cancelModul(wpt: WithdrawalProductType) {
    this.wptFreeTrackingService
      .cancelModule(wpt)
      .then((res) => {
        console.warn(res);
        this.successMessage = 'MODULE_CANCELED';
        this.selectedFreeWptToCancel = null;
        this.selectedWptToCancel = null;
        this.moduleCanceled.emit(wpt);
        this.licenceChanged.emit();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  reactivateFreeModule(wpt: WithdrawalProductType) {
    this.wptFreeTrackingService
      .activateModule(wpt)
      .then((res) => {
        console.warn(res);
        this.successMessage = 'MODULE_ACTIVATED';
        console.log('reactivateFreeModule');
        this.moduleActivated.emit(wpt);
        this.licenceChanged.emit();
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  async orderFreeProducts(wpts: WithdrawalProductType[]) {
    for (const wpt of wpts) {
      await this.wptFreeTrackingService
        .activateModule(wpt)
        .then((value) => {
          this.authService.identity(true);
        })
        .catch((error) => {
          console.warn(error.status + ' ');
        });
    }
    this.successMessage = 'MODULE_ORDERED';
    this.modulesOrdered.emit(wpts);
    this.licenceChanged.emit();
    this.selectedFreeWithdrawalProductTypes = []; // reset, they were ordered
  }

  async orderProducts(wpts: WithdrawalProductType[]) {
    for (const wpt of wpts) {
      await this.wptFreeTrackingService
        .activateModule(wpt)
        .then((value) => {
          this.authService.identity(true);
        })
        .catch((error) => {
          console.warn(error.status + ' ');
        });
    }
    this.successMessage = 'MODULE_BOUGHT';
    this.modulesBought.emit(wpts);
    this.licenceChanged.emit();
    this.selectedWithdrawalProductTypes = []; // reset, they were bought
  }

  toggleModuleToBuy(wpt: any, licence: any) {
    this.selectModuleToBuyOTHER(wpt);
  }

  selectModuleToBuyOTHER(wpt: WithdrawalProductType) {
    // direct payment in states FREE
    // indirect payment in states PAYED, PAYMENT_UNSUCCESSFUL
    this.initPaymentProcess(wpt);
  }

  isContainedInActiveWpts(activeFreeWpt: WithdrawalProductType) {
    return this.activeWp.withdrawalProductTypes.includes(activeFreeWpt);
  }

  initPaymentProcess(wpt: WithdrawalProductType) {
    if (!this.selectedWithdrawalProductTypes.includes(wpt)) {
      this.selectedWithdrawalProductTypes.push(wpt);
    } else {
      var index = this.selectedWithdrawalProductTypes.indexOf(wpt);
      this.selectedWithdrawalProductTypes.splice(index, 1);
    }
    this.selectedWptToCancel = null; // remove cancellation (if selected previously)
    this.selectedFreeWptToCancel = null; // remove cancellation (if selected previously)
    this.selectedFreeWithdrawalProductTypes.splice(0, this.selectedFreeWithdrawalProductTypes.length); // remove free products (if selected previously)
    this.showModuleSelction = true;

    if (this.activeWp.licenseState === 'FREE') {
      //TODO LCC-67 check if 31 or 7 Beni/Michael/Lukas
      this.getNewPriceFree(this.selectedWithdrawalProductTypes);
    } else {
      //TODO LCC-67 check if 31 or 7 Beni/Michael/Lukas
      this.getNewDiffPrice(this.selectedWithdrawalProductTypes, 0);
    }

    // if (this.activeWp.licenseState === 'FREE') {
    //   this.getNewPriceFree(this.selectedWithdrawalProductTypes);
    // } else {
    //   this.getNewDiffPrice(this.selectedWithdrawalProductTypes, 0);
    // }
  }

  resetFunctions() {
    this.validationDataService.resetComplete();
  }
}
