import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from '@app/account/account.service';
import { PaymentMeans, WithdrawalProductDTO } from '@generated/generatedEntities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateLicenseAdditionalsModalComponent } from '../update-license-additionals-modal/update-license-additionals-modal.component';
import { UpdateLicenseRemoveModalComponent } from '../update-license-remove-modal/update-license-remove-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-billing-resign-license',
  templateUrl: './billing-resign-license.component.html',
  styleUrls: ['./billing-resign-license.component.scss'],
})
export class BillingResignLicenseComponent implements OnInit {
  activewp: any;
  numAvailableAccountsToAdd: number = 100;
  numMaxUsersToRemove!: number;
  paymentMeans!: PaymentMeans;
  freeMode: boolean = false;
  numAccountsToAdd: number = 0;
  formAdditionalAccounts!: UntypedFormGroup;
  formRemoveAccounts!: UntypedFormGroup;
  minCount: number = 1;
  // numAccountsToRemove: any;

  errorBlock = {
    maxError: false,
    minError: false,
    isTouched: false,
    isDirty: false,
  };

  constructor(
    private accountService: AccountService,
    private _formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.createformAdditionalAccounts();
    this.createformRemoveAccounts();
    this.accountService
      .getActiveWp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (activeWp: WithdrawalProductDTO | null) => {
          // console.log('activeWp', activeWp);
          if (activeWp != null) {
            this.activewp = activeWp;
            this.paymentMeans = activeWp.paymentMeans;
            this.freeMode = activeWp.licenseState === 'FREE';
          } else {
            console.error('activeWp is null');
          }
        },
      });
  }

  createformAdditionalAccounts() {
    this.formAdditionalAccounts = this._formBuilder.group({
      numAccountsToAdd: ['', Validators.required],
    });
  }

  createformRemoveAccounts() {
    this.formRemoveAccounts = this._formBuilder.group({
      numAccountsToRemove: ['', Validators.required],
    });
  }

  resetErrorBlock() {
    this.errorBlock.maxError = false;
    this.errorBlock.minError = false;
    this.errorBlock.isTouched = false;
    this.errorBlock.isDirty = false;
  }

  AdditionalAccountsValueChanges(event: any) {
    this.resetErrorBlock();
    let value = event.target.value;
    if (value > this.numAvailableAccountsToAdd) {
      this.errorBlock.minError = false;
      this.errorBlock.maxError = true;
    }
    if (value < this.minCount) {
      this.errorBlock.minError = true;
      this.errorBlock.maxError = false;
    }
  }

  RemoveAccountsValueChanges(event: any) {
    this.resetErrorBlock();
    let value = event.target.value;
    if (this.activewp.numMaxUsers) {
      if (parseInt(value.toString()) > parseInt(this.activewp.numMaxUser)) {
        this.errorBlock.minError = false;
        this.errorBlock.maxError = true;
      }
      console.log(' this.errorBlock', this.errorBlock);
      // if (Number(value) < this.minCount) {
      //   this.errorBlock.minError = true;
      //   this.errorBlock.maxError = false;
      // }
    }
  }

  enableBuyAdditionalAccounts() {}

  showUpdateLicenseModal() {
    let prarms = {
      numAccountsToAdd: this.formAdditionalAccounts.get('numAccountsToAdd')?.value,
      array: [],
      activewp: this.activewp,
      freeMode: this.freeMode,
      licenseState: this.activewp.licenseState,
      dateTimeValidUntil: this.activewp.dateTimeValidUntil,
    };

    const dialogRef = this.dialog.open(UpdateLicenseAdditionalsModalComponent, {
      width: '100%',
      closeOnNavigation: true,
      data: {
        stateParam: prarms,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
  enableRemoveAccounts() {}

  showUpdateLicenseRemoveModal() {
    let prarms = {
      numAccountsToRemove: this.formRemoveAccounts.get('numAccountsToRemove')?.value,
      numMaxUsers: this.activewp.numMaxUsers,
      array: [],
      dateTimeFreeUntil: this.activewp.dateTimeFreeUntil,
      dateTimeValidUntil: this.activewp.dateTimeValidUntil,
      freeMode: this.freeMode,
    };

    const dialogRef = this.dialog.open(UpdateLicenseRemoveModalComponent, {
      width: '100%',
      closeOnNavigation: true,
      data: {
        stateParam: prarms,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
}
