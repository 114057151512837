import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private _http: HttpClient) {}

  query(): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/payment');
  }
  adminQuery(orgIdObj: any): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/payment/admin/' + orgIdObj.orgId);
  }

  adminUpdate(data: any): Observable<any> {
    return this._http.put(environment.serverUrl + '/api/payment/admin', data);
  }

  adminDelete(data: any): Observable<any> {
    return this._http.delete(environment.serverUrl + '/api/payment/admin/' + data.id);
  }

  isEligibleForCreatingInvoicePayment(data: any): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/payment/admin/is_eligible_create_invoice_payment/' + data.id);
  }

  isEligibleForUpdatingLatestInvoicePayment(data: any): Observable<any> {
    return this._http.get(
      environment.serverUrl + '/api/payment/admin/is_eligible_update_latest_invoice_payment/' + data.id,
    );
  }
  createInvoicePayment(data: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/payment/admin/create_invoice_payment/:id', data);
  }
  updateLatestInvoicePayment(data: any, data2: any): Observable<any> {
    return this._http.post(
      environment.serverUrl + '/api/payment/admin/update_latest_invoice_payment/' + data.id,
      data2,
    );
  }
  buySpecialOffer(data: any, data2: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/payment/admin/buy_special_offer/' + data.id, data2);
  }
  forceSendPaymentEmail(data: any): Observable<any> {
    return this._http.post(environment.serverUrl + '/api/payment/admin/force_send_payment_email/:paymentId', data);
  }
}
