import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Logger } from '@app/@shared';
import { VideoDialogComponent } from '@app/calculators/components/video-dialog/video-dialog.component';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const log = new Logger('VideoDetailsService');
@Injectable({
  providedIn: 'root',
})
export class VideoDetailsService {
  safeURL!: SafeResourceUrl;

  constructor(
    private http: HttpClient,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {}

  getTeaserVideo() {
    // return this.http.get<any>(environment.serverUrl +this.safeURL);
    // return this.http.get('assets/resources_generic/location.json');
  }

  openVideoDialog(): void {
    // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/WovwuOFBUuY');
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '100%',
      data: { url: 'https://www.youtube.com/embed/2Zm-uxpVjXE?VQ=HD1080', title: 'Kennwerte in 2 Minuten' },

      panelClass: ['dimension-modalbox', 'video-container'],
      closeOnNavigation: true,
    });
  }

  getTeaserVideoPom() {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '100%',
      data: {
        url: 'https://www.youtube.com/embed/vPi_G_Of2k8?VQ=HD1080',
        title: 'Der Betriebskostenrechner in 2 Minuten',
      },

      panelClass: ['dimension-modalbox', 'video-container'],
      closeOnNavigation: true,
    });
  }

  getTutorialVideo() {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '100%',
      data: { url: 'https://www.youtube.com/embed/rZKq7WtghDQ?VQ=HD1080', title: 'Tutorial-Video' },

      panelClass: ['dimension-modalbox', 'video-container'],
      closeOnNavigation: true,
    });
  }
}
