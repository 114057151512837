import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VideoDetailsService } from '@app/core/services/video-details.service';
import { WithdrawalProductType } from '@generated/generatedEntities';

@Component({
  selector: 'app-pf',
  templateUrl: './pf.component.html',
  styleUrls: ['./pf.component.scss'],
})
export class PfComponent implements OnInit {
  @Input() IsAuthenticated!: boolean;
  @Input() hasBuildingCosts!: boolean;
  @Output() openBillingModal: EventEmitter<WithdrawalProductType> = new EventEmitter();

  constructor(
    private videoDetailService: VideoDetailsService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  // openBillingModalForBuild() {
  //   this.openBillingModal.emit('BUILDING_COSTS_AND_DATES');
  // }
  // openVideoDialog(): void {
  //   this.videoDetailService.openVideoDialog();
  // }

  // goToInfo() {
  //   this.router.navigate([]).then((result) => {
  //     window.open('/info', '_blank');
  //   });
  // }

  // goToProcessForm() {
  //   // this.router.navigate(['/process-form/input']);
  // }
}
