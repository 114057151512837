import { LocationStrategy } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BillingService } from '@app/core/services/billing/billing/billing.service';

@Component({
  selector: 'app-update-license-remove-modal',
  templateUrl: './update-license-remove-modal.component.html',
  styleUrls: ['./update-license-remove-modal.component.scss'],
})
export class UpdateLicenseRemoveModalComponent implements OnInit {
  constructor(
    private billingService: BillingService,
    private dialogRef: MatDialogRef<UpdateLicenseRemoveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public activeWp: any,
    private locationStrategy: LocationStrategy,
  ) {}
  numUsersToRemove: number = 0;
  currentNumMaxUsers: number = 0;
  freeMode: boolean = false;
  priceRelief: number = 0;
  vat: number = 0;
  effectiveVat: number = 0;
  reliefInclVat: number = 0;
  isClicked: boolean = false;
  until: any | Date;

  authorized: boolean = false;
  invalidCard: any;
  errorPaymentFormError: string | null = '';
  freemode: boolean = false;

  formRemoveAccounts!: UntypedFormGroup;

  ngOnInit(): void {
    this.initializeData();
    this.initializeValueData();
  }

  initializeValueData() {
    this.numUsersToRemove = this.activeWp.numUsersToRemove;
    this.currentNumMaxUsers = this.activeWp.currentNumMaxUsers;
  }

  initializeData() {
    this.billingService.fetchPriceRelief(this.numUsersToRemove).subscribe({
      next: (ret) => {
        this.priceRelief = ret.relief;
        this.vat = ret.vat;
        this.effectiveVat = ret.effectiveVat;
        this.reliefInclVat = ret.reliefInclVat;
      },
    });
    if (this.activeWp.validUntil) {
      this.until = this.activeWp.validUntil;
    } else if (this.activeWp.freeUntil) {
      this.until = this.activeWp.freeUntil;
    }
  }

  cancel() {
    this.dialogRef.close({
      response: {
        paymentMeansSuccess: null,
        paymentFormSuccess: null,
      },
    });
  }

  removeAccounts() {
    if (this.isClicked) {
      return;
    }
    this.isClicked = true;
    var n = this.locationStrategy.getBaseHref().lastIndexOf(this.locationStrategy.path());
    var contextPath = this.locationStrategy.getBaseHref().slice(0, n);
    var dto = {
      successUrl: contextPath + '/saferpay-success',
      failUrl: contextPath + '/saferpay-error',
      abortUrl: contextPath + '/saferpay-abort',
      payerLanguageCode: 'DE',
      numUsersToRemove: this.numUsersToRemove,
      currentNumMaxUsers: this.currentNumMaxUsers,
    };
    this.billingService.updateLicense(dto).subscribe({
      next: (response) => {
        this.authorized = response.authorized;
        this.invalidCard = response.invalidCard;
        if (!this.authorized) {
          this.errorPaymentFormError = null;
        } else {
          // SUCCESS
          this.errorPaymentFormError = null;
          setTimeout(() => {
            this.cancel();
          }, 2000);
        }
      },
    });
  }
}
