import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '@app/account/account.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing-payed',
  templateUrl: './billing-payed.component.html',
  styleUrls: ['./billing-payed.component.scss'],
})
export class BillingPayedComponent implements OnInit {
  @Input() activeWp!: any;
  @Output() licenceChanged = new EventEmitter<void>();

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.accountService
      .getActiveWp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (activeWp) => {
          //console.log('activeWp',activeWp);
          this.activeWp = activeWp;
        },
      });
  }
}
