<div *transloco="let t" class="sectionId_lca sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.build">{{ t("products.start.quickNav.lca") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="build.title">{{ t("products.lca.title") }}</h3>
    </div>
    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold"
        data-translate="lca.text-1-header"
        [innerHTML]="t('products.lca.text-1-header')"
      ></p>
      <p class="brightfont" style="display: inline" data-translate="lca.text-1-1">
        {{ t("products.lca.text-1-1") }}
      </p>
      <p class="brightfont" style="display: inline; font-weight: bold">{{ t("products.lca.text-1-2") }}</p>
      <br />
      <p style="display: inline; hyphens: auto" class="brightfont" data-translate="lca.text-1-3">
        {{ t("products.lca.text-1-3") }}
      </p>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li class="brightfont">
            {{ t("products.lca.bp-1") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-2") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-3") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-4") }}
          </li>
          <li class="brightfont">
            {{ t("products.lca.bp-5") }}
          </li>
        </ul>
      </div>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: normal"
      data-translate="lca.text-2-header"
    >
      {{ t("products.lca.text-2-header") }}
    </p>

    <!--    <p class="brightfont" style="margin-bottom: 10px" data-translate="products.text-2">-->
    <!--      {{ t("products.products.text-2") }}-->
    <!--    </p>-->

    <!--    <div style="margin-bottom: 20px">-->
    <!--      <button-->
    <!--        *ngIf="!IsAuthenticated"-->
    <!--        ui-sref="register"-->
    <!--        class="btn btn-elegant-black kw-btn-grid"-->
    <!--        id="buildingCostsAndDates"-->
    <!--        [routerLink]="'7tage'"-->
    <!--      >-->
    <!--        <span data-translate="use_free_month">{{ t("products.use_free_month") }}</span>-->
    <!--      </button>-->
    <!--      <ng-container *ngIf="IsAuthenticated">-->
    <!--&lt;!&ndash;        <button&ndash;&gt;-->
    <!--&lt;!&ndash;          *ngIf="hasBuildingCosts"&ndash;&gt;-->
    <!--&lt;!&ndash;          ui-sref="process-form.input({withdrawalProductType: 'building_costs_and_dates'})"&ndash;&gt;-->
    <!--&lt;!&ndash;          data-ui-sref-opts="{reload: 'operational-costs'}"&ndash;&gt;-->
    <!--&lt;!&ndash;          class="btn btn-elegant-black kw-btn-grid"&ndash;&gt;-->
    <!--&lt;!&ndash;          (click)="goToProcessForm()"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;          <span>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;            <mat-icon class="material-symbols-outlined" style="width: 16px; font-size: 20px; vertical-align: middle">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;              play_arrow </mat-icon&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;            >{{ t('products.calc_now') }}</span&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          &lt;!&ndash;          >&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;          <mat-icon class="material-symbols-outlined" style="font-size: 20px; vertical-align: middle">&ndash;&gt;-->
    <!--&lt;!&ndash;            play_arrow </mat-icon&ndash;&gt;-->
    <!--&lt;!&ndash;          >&nbsp;&ndash;&gt;-->
    <!--&lt;!&ndash;          <span>{{ t("products.calc_now") }}</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </button>&ndash;&gt;-->
    <!--&lt;!&ndash;        <button&ndash;&gt;-->
    <!--&lt;!&ndash;          *ngIf="!hasBuildingCosts"&ndash;&gt;-->
    <!--&lt;!&ndash;          (click)="openBillingModalForBuild()"&ndash;&gt;-->
    <!--&lt;!&ndash;          class="btn btn-elegant-black kw-btn-grid"&ndash;&gt;-->
    <!--&lt;!&ndash;        >&ndash;&gt;-->
    <!--&lt;!&ndash;          <i class="fa fa-credit-card" style="height: 16px; width: 16px"></i>&nbsp;&ndash;&gt;-->
    <!--&lt;!&ndash;          <span data-translate="get_license">{{ t("products.get_license") }}</span>&ndash;&gt;-->
    <!--&lt;!&ndash;        </button>&ndash;&gt;-->
    <!--      </ng-container>-->
    <!--&lt;!&ndash;      <a class="btn btn-elegant-black kw-btn-grid" ui-sref="accuracy" target="_blank" (click)="goToInfo()"&ndash;&gt;-->
    <!--&lt;!&ndash;        >{{ t("products.learn_more") }}&ndash;&gt;-->
    <!--&lt;!&ndash;      </a>&ndash;&gt;-->

    <!--&lt;!&ndash;      <button class="btn btn-elegant-black kw-btn-grid" ui-sref="products.video" (click)="openVideoDialog()">&ndash;&gt;-->
    <!--&lt;!&ndash;        <img src="/assets/images/youtube2.svg" class="align-icon" width="18" />&nbsp;&ndash;&gt;-->
    <!--&lt;!&ndash;        <span data-translate="build.name">{{ t("products.build.name") }}</span>&ndash;&gt;-->
    <!--&lt;!&ndash;      </button>&ndash;&gt;-->

    <!--      <a-->
    <!--        class="btn btn-elegant-black kw-btn-grid"-->
    <!--        href="../../../../pdf/{{ '' | currentLanguage }}/keeValue_AG-Flyer.pdf"-->
    <!--        target="_blank"-->
    <!--      >-->
    <!--        <img src="/assets/images/file-pdf.svg" class="file-pdf align-icon" width="16" />&nbsp;-->
    <!--        <span data-translate="flyer">{{ t("products.flyer") }}</span>-->
    <!--      </a>-->
    <!--    </div>-->
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <!--      <img src="/assets/images/products/lca.svg" style="align-self: center;max-width:402px; max-height:402px;" />-->
      <img
        src="/assets/images/products/lca_coming_soon_BG.svg"
        style="align-self: center; max-width: 402px; max-height: 402px"
      />
    </div>
  </div>
</div>
