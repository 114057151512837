import { Injectable } from '@angular/core';
import { ElevatorEstimateService } from '@app/calculators/services/elevator-estimate.service';
import { RealEstateDTO } from '@generated/generatedEntities';
import { TranslocoService } from '@ngneat/transloco';
import { RealEstateService } from '../realEstate/real-estate.service';
import { SharedControllerFnService } from '../shared/shared-controller-fn.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ValidationDataService {
  completeInput = {
    isComplete: false,
    isSuccess: false,
    isCalculating: false,
  };
  elementsWithError: any = [];
  validationFunctions: string[] = [];
  public pomChangeValidation$ = new Subject<boolean>();
  zIndex = 1048; // lower than navbar & lower than modal
  constructor(
    private translocoService: TranslocoService,
    private realEstateService: RealEstateService,
    private _sharedControllerFnService: SharedControllerFnService,
    private _realEstateService: RealEstateService,
  ) {}

  /**
   * Deregisters all provided validation functions.
   */
  deregisterValidationFunctions() {
    this.validationFunctions.splice(0, this.validationFunctions.length);
    this.validationFunctions = [];
    this.validationFunctions.length = 0;
  }

  /**
   * Registers the provided validation function to this data service.
   * The validation functions are applied in the order this register function is called.
   * @param validationFun
   */
  registerValidationFunction(validationFun: any) {
    this.validationFunctions.push(validationFun);
  }

  // setZindex(zi: number) {
  //   this.zIndex = zi;
  // }

  /**
   * Registers the provided validation functions to this data service - actually registers currently result of the validation functions.
   * The validation function are applied in the order as the array defines.
   * @param validationFunArr
   */
  registerValidationFunctions(validationFunArr: any[]) {
    validationFunArr.forEach((vf: any) => {
      this.registerValidationFunction(vf);
    });
  }

  // clientSideRevalidation(realEstateContainer: any) {
  //   if (this.elementsWithError.length > 0) {
  //    // this.removeValidationErrors();
  //     this.clientSideValidation(realEstateContainer);
  //   }
  // }

  clientSideValidation(realEstate: any) {
    console.info('Start client side validation.');
    console.warn(realEstate);
    //this.removeValidationErrors();
    // ret: BOOLEAN
    // const ret : boolean= false;
    // console.log('this.validationFunctions mapped',this.validationFunctions);
    // if(this.validationFunctions.includes({isValid: true}) || this.validationFunctions.includes(undefined)){

    // }
    // const ret = this.validationFunctions
    //   .map((vf: any) => {
    //     try {
    //       var retObj:any //= vf(realEstate);
    //       // console.log('retObj',retObj)
    //       if (!retObj) {
    //         // for (var i = 0; i < retObj.elements.length; i++) {
    //           //this.showValidationError(retObj.elements[i], retObj.messages[i]);
    //           return false;
    //         // }
    //       }
    //       return true
    //     } catch (error) {
    //       console.error('Failed to execute validation function ', error);
    //       return false;
    //     }
    //   })
    // .reduce((previousValue: any, currentValue: any) => {
    //   return previousValue & currentValue;
    // });
    // let ret : boolean = false
    // this.validationFunctions.length > 1 ? ret = true : ret = false;
    let ret: boolean = false;
    // this.validationFunctions.forEach((vf) => {
    //   if (!vf) {
    //     ret = false;
    //     break;
    //   }
    //   ret = true;
    // });

    for (let i = 0; i < this.validationFunctions.length; i++) {
      if (!this.validationFunctions[i]) {
        ret = false;
        break;
      }
      ret = true;
    }

    console.log('for refeence ', this.validationFunctions);
    console.log('ret for refeence ', ret);

    if (!ret) {
      // var element = '#validateAndProceed';
      // var message = this.translocoService.translate('process-form.userFriendlyErrorMessages.FAIL');
      //this.showValidationErrorDetails(element, message, false);
      console.warn(
        'Client side validation failed with message ::=>',
        this.translocoService.translate('processform.userFriendlyErrorMessages.FAIL'),
      );
      return false;
    }
    return ret;
  }

  // serverSideValidation(realEstate: any) {
  //   var realEstateContainerCopy = Object.assign(realEstate);
  //   this._sharedControllerFnService.cleanDto(realEstateContainerCopy);
  //   // Returns promise (TRUE, FALSE or error code).
  //   return this._realEstateService.checkRealEstate(realEstateContainerCopy).subscribe({
  //     next: (response) => {
  //       if (response !== 'OK') {
  //         console.warn("Server side validation failed. Not able to apply a model to provided values.");
  //       } else {
  //         return true;
  //       }
  //       return response;
  //     },
  //     error: (error) => {
  //       console.error(error);
  //       return false;
  //     }
  //   })
  // }

  // removeValidationErrors() {
  //   for (var i = this.elementsWithError.length - 1; i >= 0; i--) {
  //     this.removeValidationError(this.elementsWithError[i]);
  //   }
  // }

  // removeValidationError(element: any) {
  //   // $(element).removeClass("validationError");
  //   // if (angular.isDefined(element) && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
  //   //   try {
  //   //     $(element).tooltipster('close');
  //   //   } catch (e) {
  //   //     console.error('validation remove error: ', e, $(element).tooltipster, $(element));
  //   //   }
  //   // }
  //    var pos = this.elementsWithError.indexOf(element);
  //    this.elementsWithError.splice(pos, 1);
  // }

  showValidationError(element: any, message: any) {
    return this.showValidationErrorDetails(element, message, true);
  }

  // showValidationErrors(elementsArr: any, message: any) {
  //   var elementsArrLength = elementsArr.length;
  //   for (var i = 0; i < elementsArrLength; i++) {
  //     var element = elementsArr[i];
  //     this.showValidationErrorDetails(element, message, false);
  //   }
  // }

  // // element: Remember to escape the dots in the css id selector "." --> "\\."
  showValidationErrorDetails(element: any, message: any, removeOnElementClick: any) {
    this.elementsWithError.push(element);
    console.info('Add validation error ' + message, element);
    element.addClass('validationError');
    //1048 default
    // var dynamicZIndex = this.getRebuildZindex(element);

    // getRebuildZindex = (element) => {
    // if (element.includes('targetOverhaul'))
    //   return this.zIndex - 1;
    // if (element.includes('targetAdditionStory'))
    //   return this.zIndex - 2;
    // if (element.includes('targetAnnex'))
    //   return this.zIndex - 3;
    // return this.zIndex;
  }
  // }

  // if ($(element).hasClass('tooltipstered')) {
  //   var instance = $(element).tooltipster('instance');
  //   instance.content(message);
  //   instance.open();
  // } else {
  //   $timeout(function () {
  //     $(element).tooltipster({
  //       content: message,
  //       theme: 'tooltipster-kw',
  //       side: ['right'],
  //       zIndex: dynamicZIndex,  //z-index of validationmessages
  //       trigger: 'custom',
  //       plugins: ['sideTip'],
  //       interactive: true,
  //       repositionOnScroll: true,
  //       functionReady: function (instance, helper) {
  //         $(helper.tooltip).click(function () {
  //           removeValidationError(helper.origin);
  //         });
  //         if (removeOnElementClick) {
  //           $(helper.origin).click(function () {
  //             removeValidationError(helper.origin);
  //           });
  //         }
  //       }
  //     }).tooltipster('open');
  //   });
  // }
  // }

  getRebuildZindex(element: any) {
    if (element.includes('targetOverhaul')) return this.zIndex - 1;
    if (element.includes('targetAdditionStory')) return this.zIndex - 2;
    if (element.includes('targetAnnex')) return this.zIndex - 3;
    return this.zIndex;
  }

  setCalculatingFalse() {
    this.completeInput.isCalculating = false;
  }

  setIsSuccess(isSuccess: boolean) {
    this.completeInput.isComplete = true;
    this.completeInput.isSuccess = isSuccess;
  }

  getIsSuccess() {
    return this.completeInput.isSuccess;
  }

  getIsCalculating() {
    return this.completeInput.isCalculating;
  }

  getIsComplete() {
    return this.completeInput.isComplete;
  }

  resetComplete() {
    this.completeInput.isComplete = false;
    this.completeInput.isSuccess = false;
    this.completeInput.isCalculating = false;
  }
}
