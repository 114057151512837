import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@app/material.module';
import { LoaderComponent } from './loader/loader.component';
import { NgInitDirective } from './directives/ng-init.directive';
import { ClearSessionStorageDirective } from './directives/clear-session-storage.directive';

@NgModule({
  imports: [MaterialModule, TranslocoModule, CommonModule],
  declarations: [LoaderComponent, NgInitDirective, ClearSessionStorageDirective],
  exports: [LoaderComponent, NgInitDirective, ClearSessionStorageDirective],
})
export class SharedModule {}
