/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { devTools } from '@ngneat/elf-devtools';

if (environment.production) {
  enableProdMode();
} else {
  devTools();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    // if (window && 'ngRef' in window) {
    //     window.ngRef.destroy();
    // } d
    // window['ngRef'] = ref;
    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));
