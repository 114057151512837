import { Component, Input, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import {
  BaseLicenseDTO,
  BillingPriceDTO,
  OrganisationDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
} from '@generated/generatedEntities';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['prices'],
    },
  ],
})
export class PriceTableComponent implements OnInit {
  @Input() organisation!: OrganisationDTO;
  @Input() selected_withdrawal_product_types!: WithdrawalProductType[];
  @Input() num_additional_users?: number;
  @Input() enable_num_add_users: any;
  @Input({ required: true }) activeWp!: WithdrawalProductDTO;
  @Input() price!: BillingPriceDTO;

  baseLicenceDTO?: BaseLicenseDTO;

  constructor(private baseLicenceService: BaseLicenseService) {}

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    if (this.enable_num_add_users == null) {
      this.enable_num_add_users = true;
    }
    this.baseLicenceService
      .fetchBaseLicenseDetails({ id: this.activeWp.baseLicenseId })
      .pipe(take(1))
      .subscribe({
        next: (baseLicensesPrice) => {
          if (baseLicensesPrice) {
            this.baseLicenceDTO = baseLicensesPrice;
          } else {
            this.baseLicenceDTO = undefined;
          }
        },
      });
  }
}
