<div *transloco="let t">
  <div class="header" [ngStyle]="{ height: height }">
    <div
      id="testBanner"
      class="bannerImg"
      [ngStyle]="{
        'background-image': displayImage,
        'background-position': displayPosition,
        'background-repeat': displayRepeat,
        'background-size': displaySize
      }"
    >
      <div class="content" [ngSwitch]="mode">
        <ng-content select=".none-container" *ngSwitchCase="'NONE'"></ng-content>
        <div class="flexBoxOuter bannerFlexBox bannerModeFlex" *ngSwitchCase="'FLEX'">
          <!--<div class="flexBoxInner marginRight bannerTextBox" style="padding-left: 40px; padding-bottom: 76px">-->
          <div class="flexBoxInner marginRight bannerTextBox">
            <ng-content select=".flex-container"></ng-content>
          </div>
          <div class="flexBoxInner img"></div>
        </div>
        <div class="bannerFlexBox" style="display: flex" *ngSwitchCase="'CONTENT_ROW'">
          <div class="flexBoxOuter" style="width: 100%">
            <div class="flexBoxInner">
              <ng-content select=".content-row-container"></ng-content>
            </div>
          </div>
        </div>
        <div class="bannerFlexBox" style="display: flex" *ngSwitchCase="'FORM_ROW'">
          <div style="width: 100%">
            <ng-content select=".form-row-container"></ng-content>
          </div>
        </div>
        <div class="content" *ngSwitchCase="'BOOTSTRAP'">
          <div [ngStyle]="{ 'margin-top': marginTopContentRow }">
            <div class="contentRowInner">
              <div class="ctPartForm">
                <div style="padding-top: 395px; padding-right: 530px">
                  <ng-content select=".bootstrap-container"></ng-content>
                </div>
              </div>
              <div class="additionalInfoBox" style="height: 0">
                <div class="ctPartImg"></div>
                <div class="ctPartDescr"></div>
                <div class="ctPartClear"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" *ngSwitchCase="'CONTENT_ROW_2'">
          <div [ngStyle]="{ 'margin-top': marginTopContentRow }">
            <div class="contentRowInner2">
              <div class="ctPartForm">
                <div>
                  <ng-content select=".content-row-2-container"></ng-content>
                </div>
              </div>
              <div class="additionalInfoBox" style="height: 0">
                <div class="ctPartImg"></div>
                <div class="ctPartDescr"></div>
                <div class="ctPartClear"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" *ngSwitchDefault>
          <ng-content select=".empty-container"></ng-content>
        </div>
      </div>
      <div class="archLogo">
        <div></div>
      </div>
    </div>
  </div>
</div>
