import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseLicenseDTO } from '@generated/generatedEntities';
@UntilDestroy()
@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss'],
})
export class PricesComponent implements OnInit {
  displayedColumns: string[] = ['empty', 'company size', 'monthly costs', 'package costs', 'empty2'];
  dataSource: BaseLicenseDTO[] = [];
  @Input() showBuildingCosts!: boolean;

  @Input() backGroundColor: string = 'section-white-color';

  discountInPercentIfSiaIndividual!: number;
  discountInPercentIfSiaCorporate!: number;

  discountModule2: number = 0.8;
  discountModule3: number = 0.7;
  isAuthenticated!: boolean;

  constructor(
    private _baseLicenseService: BaseLicenseService,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
  ) {}

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.authenticationService.identity(true).subscribe({
      next: (value) => {
        if (value != null) {
          this.isAuthenticated = this.credentialsService.isAuthenticated();
          // this.isAuthenticated = value;
          // console.log('Settins details', this.settingsAccount);
        }
      },
    });

    this._baseLicenseService
      .actives()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.dataSource = res;
          this.discountInPercentIfSiaIndividual = res[0].discountInPercentIfSiaIndividual;
          this.discountInPercentIfSiaCorporate = res[0].discountInPercentIfSiaCorporate;
        },
        error: (error) => {},
      });
  }
}
