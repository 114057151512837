<div class="flex-1" [ngClass]="language">
  <app-header [sidenav]="sidenav"></app-header>
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" ngClass.gt-sm="has-border"> </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
