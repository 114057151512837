import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appClearSessionStorageNavigate]',
})
export class ClearSessionStorageDirective {
  @Input() targetRoute!: string;
  constructor(private router: Router) {}

  @HostListener('click', ['$event.target']) onClick(e: any) {
    sessionStorage.removeItem('realEstateId');
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.targetRoute], { replaceUrl: true }).then(() => {
      window.location.reload();
    });
  }
}
