import { Component, Input, OnInit } from '@angular/core';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  @Input() isAuthenticated!: boolean;
  countTo: number = 0;
  showCounter: boolean = true;
  constructor(private realEstateService: RealEstateService) {}

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.realEstateService.getCounterNumber().subscribe({
      next: (val) => {
        this.countTo = val.cnt;
        if (val.cnt === 0) {
          this.countTo = 9266;
        }
      },
      error: (error) => {
        // console.error(error);
        this.countTo = 9266;
        this.showCounter = true;
      },
    });
  }
}
