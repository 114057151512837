<div *transloco="let t" class="sectionId_pf sectionAnchor flexBoxOuter">
  <div class="flexBoxInner txt marginRight" style="hyphens: auto">
    <div style="margin-bottom: 26px">
      <h4 data-translate="start.quickNav.build">{{ t("products.start.quickNav.pf") }}</h4>
    </div>

    <div style="margin-top: 33px">
      <h3 data-translate="build.title">{{ t("products.pf.title") }}</h3>
    </div>

    <div style="margin-top: 20px">
      <p
        class="brightfont"
        style="display: block; font-weight: bold; margin-top: 20px"
        data-translate="pf.text-1-header"
        [innerHTML]="t('products.pf.text-1-header')"
      ></p>
      <p class="brightfont" style="margin-top: 10px; display: inline" data-translate="pf.text-1-1">
        {{ t("products.pf.text-1-1") }}
      </p>

      <br />

      <p style="display: inline; hyphens: auto" class="brightfont" data-translate="pf.text-1-3">
        {{ t("products.pf.text-1-3") }}
      </p>
      <div class="newsBox">
        <ul style="list-style-type: none">
          <li class="brightfont">
            {{ t("products.pf.bp-1") }}
          </li>

          <p class="brightfont" style="display: inline; hyphens: auto">{{ t("products.pf.text-1-2") }}</p>

          <li class="brightfont">
            {{ t("products.pf.bp-2") }}
          </li>
        </ul>
      </div>
    </div>

    <p
      class="brightfont"
      style="margin-top: 10px; display: block; font-weight: normal"
      data-translate="pf.text-2-header"
    >
      {{ t("products.pf.text-2-header") }}
    </p>
  </div>

  <div class="flexBoxInner img">
    <div class="centerImage centerImgVertically">
      <img
        src="/assets/images/calculation/KatasterAll1.png"
        style="align-self: center; max-width: 402px; max-height: 402px"
      />
    </div>
  </div>
</div>
