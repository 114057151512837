<div *transloco="let t">
  <div
    class="centeredBox flexBoxOuter"
    *ngIf="activeWp && activeWp.licenseStillValid && !activeWp.yearlyFixPrice"
    style="padding-top: 20px; padding-bottom: 33px"
  >
    <h3 class="flexBoxInner" translate="billing.yourLicense">{{ t("billing.yourLicense") }}</h3>
    <div class="flexBoxInner" style="margin-top: 33px" *ngIf="activeWp && activeWp.dateTimeInactive">
      <div class="">
        <h4 data-translate="license.cancel.header">{{ t("prices.license.cancel.header") }}</h4>
      </div>
      <div class="alert kw-attention">
        <span data-translate="license.cancel.text-1">{{ t("prices.license.cancel.text-1") }}</span>
        {{ activeWp.dateTimeInactive | date: "dd/MM/yy HH:mm" }}
        <span data-translate="license.cancel.text-2">{{ t("prices.license.cancel.text-2") }}</span>
      </div>
      <div class="alert kw-attention" *ngIf="activeWp.licenseStillValid">
        <span data-translate="license.cancel.active-till-1">{{ t("prices.license.cancel.active-till-1") }}</span>
        {{ activeWp.dateTimeValidUntil | date: "dd/MM/yy HH:mm" }}
        <span data-translate="license.cancel.active-till-2">{{ t("prices.license.cancel.active-till-2") }}</span>
      </div>
    </div>

    <div class="flexBoxInner" *ngIf="activeWp && activeWp.licenseStillValid && !isBlankLicense && !isSchoolLicense">
      <div *ngIf="!!wptFreeTracking && !(activeWp && activeWp.licenseStillValid && activeWp.dateTimeInactive)">
        <div class="">
          <h4 data-translate="billing.current-license">{{ t("billing.current-license") }}</h4>
        </div>
        <app-license-table-tpl
          [activewp]="activeWp"
          [basecontainer]="{ wptFreeTracking: wptFreeTracking, activeWp: activeWp.withdrawalProductTypes }"
          [baselicense]="baseLicense"
          [freewpt]="freeWtp"
          [organisation]="organisation"
          [price]="price"
          [wptfreetracking]="wptFreeTracking"
        >
        </app-license-table-tpl>
      </div>
    </div>

    <div class="centeredBox flexBoxOuter" *ngIf="activeWp && isBlankLicense && !isSchoolLicense">
      <div class="centeredBoxText">
        <h4 data-translate="billing.current-license">{{ t("billing.current-license") }}</h4>
      </div>
      <div class="flexBoxInner">
        <span data-translate="license.custom-license-1">
          {{ t("prices.license.custom-license-1") }}
          <!-- Bei Unternehmen die mehr als 51 Mitarbeiter haben, wird eine individuelle Lizenzgebühr berechnet.
               Diese wird aufgrund der Anzahl an jährlich geschätzten Objekten errechnet (Varianten-Bildungen zählen nicht als Schätzungen). -->
        </span>
        <br />
        <span data-translate="license.custom-license-2">
          {{ t("prices.license.custom-license-2") }}
          <!-- Gerne unterbreiten wir Ihnen ein auf Sie zugeschnittenes Angebot, teilen Sie uns einfach die Anzahl zu erwartenden
               Schätzungen pro Jahr mit (+/- 20% Toleranz) und Sie erhalten umgehend ein Angebot von uns. -->
        </span>
        <br />
        <br />
        <span>
          <span data-translate="license.call-us">{{ t("prices.license.call-us") }}</span> <b>056 444 26 01</b>
          <span data-translate="license.call-us-1"> {{ t("prices.license.call-us-1") }}</span>
          <a href="mailto:{{ t('products.about.text-2-2') }}">{{ t("products.about.text-2-2") }}</a>
        </span>
      </div>
      <br />
    </div>

    <div class="centeredBox flexBoxOuter" *ngIf="activeWp && isSchoolLicense && !isBlankLicense">
      <div class="centeredBoxText">
        <h4 data-translate="billing.current-license">{{ t("billing.current-license") }}</h4>
      </div>
      <div class="flexBoxInner">
        <span data-translate="license.education-1">
          {{ t("prices.license.education-1") }}
          <!-- Sie besitzen zur Zeit eine Lizenz für Ausbildungszwecken. -->
        </span>
        <br />
        <span>
          <span data-translate="license.edu-call-us-1">{{ t("prices.license.edu-call-us-1") }}</span>
          <b>{{ t("products.about.text-2-4") }}</b>
          <span data-translate="license.call-us-1"> {{ t("prices.license.call-us-1") }}</span>
          <a href="mailto:{{ t('products.about.text-2-2') }}">{{ t("products.about.text-2-2") }}</a>
        </span>
      </div>
      <br />
    </div>

    <div
      class="flexBoxInner"
      *ngIf="
        activeWp &&
        activeWp.licenseStillValid &&
        (!activeWp.dateTimeInactive || activeWp.dateTimeInactive === null) &&
        !pendingWp &&
        !isSchoolLicense &&
        !isBlankLicense &&
        freeMode
      "
    >
      <table class="table">
        <thead>
          <tr>
            <th style="text-align: left"></th>
            <th style="text-align: left"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="freeMode">
            <td style="width: 55%; text-align: left" data-translate="billing.freeDate">
              {{ t("billing.freeDate") }}
            </td>
            <td style="width: 27%; text-align: right">
              {{ activeWp.dateTimeFreeUntil | date: "dd/MM/yy HH:mm" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-container
      *ngIf="
        !!wptFreeTracking &&
        !(activeWp && activeWp.licenseStillValid && activeWp.dateTimeInactive) &&
        !isSchoolLicense &&
        !isBlankLicense
      "
    >
      <ng-container *ngFor="let track of wptFreeTracking">
        <div *ngIf="isFutureDiffPayment(track)">
          <div class="flexBoxOuter">
            <div class="flexBoxInner">
              <span data-translate="billing.module-price">{{ t("billing.module-price") }}</span>
              {{ t("prices.module." + track.wpt) }}
              <span data-translate="billing.module-price.due">{{ t("billing.module-price.due") }}</span>
              {{ track.dateTimeFreeUntil | date: "dd/MM/yy" }}
              <span data-translate="billing.module-price.runtime">{{ t("billing.module-price.runtime") }}</span>
              {{ track.dateTimeFreeUntil | date: "dd/MM/yy" }}
              <span data-translate="global.to">{{ t("global.to") }}</span>
              {{ dateTimeValidUntilForFutureDiffPayment | date: "dd/MM/yy" }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div
      class="flexBoxInner"
      *ngIf="pendingWp && pendingWp.licenseStillValid && !isSchoolLicense && !isBlankLicense"
      style="margin-top: 20px"
    >
      <div>
        <h4 data-translate="billing.your-new-license">{{ t("billing.your-new-license") }}</h4>
        <h5>
          <span data-translate="billing.valid-from"> {{ t("billing.valid-from") }}</span>
          {{ pendingWp.dateTimeActive | date: "dd/MM/yy HH:mm" }}
        </h5>
      </div>

      <app-license-table-tpl
        [activewp]="pendingWp"
        [basecontainer]="'vm'"
        [baselicense]="pendingBaseLicense"
        [freewpt]="freeWtp"
        [organisation]="organisation"
        [price]="pendingPrice"
        [wptfreetracking]="wptFreeTracking"
      >
      </app-license-table-tpl>
    </div>

    <div class="flexBoxOuter" *ngIf="activeWp && activeWp.licenseStillValid && activeWp.dateTimeInactive">
      <div class="flexBoxInner disabledBilling" style="margin-top: 20px">
        <div class="">
          <h4>{{ t("prices.price.your-modules") }}</h4>
        </div>
        <app-module-overview
          [activeWpt]="activeWp.withdrawalProductTypes"
          [activeWp]="activeWp"
          [organisation]="organisation"
          [wptFreeTracking]="wptFreeTracking"
          [freeWpt]="freeWtp"
          (licenceChanged)="this.licenceChanged.emit()"
        >
        </app-module-overview>
      </div>
    </div>
  </div>

  <div
    class="centeredBox flexBoxOuter"
    *ngIf="activeWp && activeWp.yearlyFixPrice"
    style="padding-top: 20px; padding-bottom: 33px"
  >
    <h3 class="flexBoxInner" data-translate="billing.yourLicense">{{ t("billing.yourLicense") }}</h3>

    <div *ngIf="price">
      <app-license-table-fix-price
        [activeWp]="activeWp"
        [basecontainer]="'vm'"
        [baselicense]="baseLicense"
        [freewpt]="freeWtp"
        [organisation]="organisation"
        [price]="price"
        [wptfreetracking]="wptFreeTracking"
      >
      </app-license-table-fix-price>
    </div>

    <table class="table table-blue-border" style="margin-bottom: 10px; max-width: 610px">
      <thead>
        <tr>
          <th style="text-align: left"></th>
          <th style="text-align: left"></th>
          <th style="text-align: left; border-bottom: none"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 55%; text-align: left" data-translate="billing.expirationDate">
            {{ t("billing.expirationDate") }}
          </td>
          <td style="width: 27%; text-align: left" *ngIf="activeWp.dateTimeValidUntil">
            {{ activeWp.dateTimeValidUntil | date: "dd/MM/yy HH:mm" }}
          </td>
          <td style="width: 27%; text-align: left" *ngIf="!activeWp.dateTimeValidUntil">-</td>
          <td style="width: 18%; border-bottom: none; border-top: none"></td>
        </tr>
      </tbody>
    </table>

    <div class="flexBoxInner" style="margin-top: 33px" *ngIf="activeWp && activeWp.dateTimeInactive">
      <div class="">
        <h4 data-translate="license.cancel.header">{{ t("license.cancel.header") }}</h4>
      </div>
      <div class="alert kw-attention">
        <span data-translate="license.cancel.text-1">{{ t("license.cancel.text-1") }}</span>
        {{ activeWp.dateTimeInactive | date: "dd/MM/yy HH:mm" }}
        <span data-translate="license.cancel.text-2">{{ t("license.cancel.text-2") }}</span>
      </div>
      <div class="alert kw-attention" *ngIf="activeWp.licenseStillValid">
        <span data-translate="license.cancel.active-till">{{ t("license.cancel.active-till") }}</span>
        {{ activeWp.dateTimeValidUntil | date: "dd/MM/yy HH:mm" }}
        <span data-translate="license.cancel.active-till-2">{{ t("license.cancel.active-till-2") }}</span>
      </div>
    </div>
  </div>
</div>
