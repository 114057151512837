import { Injectable } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

const configObj = {
  animationType: ngxLoadingAnimationTypes.circle,
  primaryColour: '#ffffff',
  secondaryColour: '#bb7c7a',
  backdropBorderRadius: '3px',
};

@Injectable({
  providedIn: 'root',
})
export class LoaderUtilService {
  constructor() {}

  getLoaderConfig() {
    return configObj;
  }
}
