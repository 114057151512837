import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { BaseLicenseDTO } from '@generated/generatedEntities';

//FIXME this service is not needed in root scope?
@Injectable({
  providedIn: 'root',
})
export class BaseLicenseService {
  constructor(private http: HttpClient) {}

  /**
   * get all base licenses that are currently active and are available.
   */
  actives(): Observable<BaseLicenseDTO[]> {
    return this.http.get<BaseLicenseDTO[]>(environment.serverUrl + '/api/base_license/actives');
  }

  privates(): Observable<BaseLicenseDTO> {
    return this.http.get<BaseLicenseDTO>(environment.serverUrl + '/api/base_license/privates');
  }

  /**
   * Get correct license with corresponding number of employee.
   * @param numEmployees
   */
  fetchLicense(numEmployees: number): Observable<BaseLicenseDTO> {
    return this.http.get<BaseLicenseDTO>(`${environment.serverUrl} + /api/base_license/fetch/${numEmployees}`);
  }

  fetchBaseLicenseDetails(obj: { id: number }): Observable<BaseLicenseDTO> {
    //const data = this.createRequestOption(obj);
    return this.http.get<BaseLicenseDTO>(environment.serverUrl + '/api/base_license/' + obj.id); //, { params: data, observe: 'response' })
  }

  // createRequestOption = (req?: any | any[]): HttpParams => {
  //   let options: HttpParams = new HttpParams();
  //   if (req) {
  //     // Object.keys(req).forEach((key) => {
  //     //   if (key !== 'id') {
  //     //     options = options.set(key, req[key]);
  //     //   }
  //     // });
  //     if (req.id) {
  //       // req.id.forEach((val: any) => {
  //         options = options.append('id', req.id);
  //       // });
  //     }
  //   }
  //   return options;
  // };

  createCustomLicense(obj: any): Observable<any> {
    //const data = this.createRequestOption(obj);
    return this.http.post<any>(environment.serverUrl + '/api/base_license/create_custom', obj); //, { params: data, observe: 'response' })
  }
  fetchOrphanedBaseLicenses(): Observable<any> {
    //const data = this.createRequestOption(obj);
    return this.http.get<any>(environment.serverUrl + '/api/base_license/fetch_orphans'); //, { params: data, observe: 'response' })
  }
  deleteCustomLicense(obj: any): Observable<any> {
    //const data = this.createRequestOption(obj);
    return this.http.delete<any>(environment.serverUrl + '/api/base_license/delete_custom/' + obj.id); //, { params: data, observe: 'response' })
  }
}
