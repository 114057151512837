import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentPdfService {
  constructor(private _http: HttpClient) {}

  downloadPaymentPdf(paymentId: any): Observable<any> {
    return this._http.get(environment.serverUrl + '/api/billing/pdf/download?id=' + paymentId, {
      responseType: 'blob',
    });
  }
}
