import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/account/account.service';
import { BaseLicenseService } from '@app/core/services/billing/base-license/base-license.service';
import { BillingService } from '@app/core/services/billing/billing/billing.service';
import { WptFreeTrackingService } from '@app/core/services/billing/wpt-free-tracking/wpt-free-tracking.service';
import { WithdrawalProductService } from '@app/core/services/credits/withdrawal-product/withdrawal-product.service';
import { ExistingWptService } from '@app/core/services/existing-wpt/existing-wpt.service';
import { OrganisationService } from '@app/core/services/Organisation/organisation.service';
import {
  BaseLicenseDTO,
  OrganisationDTO,
  PaymentMeans,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [
        'prices',
        'global',
        'register',
        'settings',
        'process_form',
        'model_realEstate',
        'realestates',
        'favorite',
        'model_quality',
        'model_reference',
        'billing',
        'prices',
      ],
    },
  ],
})
export class BillingComponent implements OnInit {
  baseLicense: BaseLicenseDTO = {} as BaseLicenseDTO;
  pendingBaseLicense: BaseLicenseDTO = {} as BaseLicenseDTO;
  activeWp: WithdrawalProductDTO = {} as WithdrawalProductDTO;
  pendingWp: WithdrawalProductDTO = {} as WithdrawalProductDTO;
  wptFreeTracking: WptFreeTrackingDTO[] = [];
  priceCurrentLicense = null;
  numAvailableAccountsToAdd: number = 0;
  // license edit properties
  numAccountsToAdd = null;
  numAccountsToRemove = null;
  newAllWithdrawalProducts = null;

  isBlankLicense = false;
  isSchoolLicense = false;

  // the maximum amount of users which are allowed to remove
  numMaxUsersToRemove = 0;
  price: any;
  paymentMeans!: PaymentMeans;
  freeMode!: boolean;
  licenseState: any;
  organisation: any;
  usersSize: number = 0;
  invitesSize: any;
  discountInPercentIfSiaIndividual: any;
  discountInPercentIfSiaCorporate: any;
  isLuucyLicense!: boolean;
  baseLicenses!: BaseLicenseDTO[];
  pendingPrice: any;
  pendingFreeMode!: boolean;
  pendingNumAvailableAccountsToAdd!: number;
  pendingDiscountInPercentIfSiaIndividual: any;
  pendingDiscountInPercentIfSiaCorporate: any;
  pendingInvitesSize: any;
  pendingUsersSize: any;
  pendingNumMaxUsersToRemove: any;
  freeWtp: WithdrawalProductType[] = [];
  // paymentFormSuccess = $stateParams.paymentFormSuccess;
  // paymentMeansSuccess = $stateParams.paymentMeansSuccess;

  constructor(
    private wptFreeTrackingService: WptFreeTrackingService,
    private withdrawalProductService: WithdrawalProductService,
    private billingService: BillingService,
    private organisationService: OrganisationService,
    private baselicenceService: BaseLicenseService,
    private existingWptService: ExistingWptService,
    private router: Router,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.intializeData();
  }

  intializeData() {
    this.fetchWithdrawalProduct();
    this.pendingWithdrawalProduct();
  }

  endsWith(str: string, suffix: string) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  fetchWptFreeTracking(orgId: number) {
    this.wptFreeTrackingService
      .queryByOrg(orgId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (wptTracking: WptFreeTrackingDTO[]) => {
          this.wptFreeTracking = wptTracking;
          this.accountService.wptFreeTracking.next(this.wptFreeTracking);
          this.possibleFreeModule();
        },
        error: (error) => {},
      });
  }

  fetchWithdrawalProduct() {
    this.withdrawalProductService
      .current_for_user()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (activeWp) => {
          //console.log('activeWp',activeWp)
          if (activeWp) {
            this.billingService
              .fetchPrice(activeWp.id)
              .pipe(untilDestroyed(this))
              .subscribe({
                next: (res) => {
                  //console.log('line 116 : response from fetchPrice() API ',res)
                  this.price = res;
                  this.accountService.price.next(this.price); //
                  this.paymentMeans = activeWp.paymentMeans;
                  this.activeWp = activeWp;
                  this.accountService.activeWp.next(this.activeWp); //
                  this.freeMode = activeWp.licenseState === 'FREE';
                  this.accountService.isFreeMode.next(this.freeMode);
                  this.numAvailableAccountsToAdd = 100 - activeWp.numMaxUsers;
                  this.licenseState = activeWp.licenseState;
                  this.organisationService
                    .current()
                    .pipe(untilDestroyed(this))
                    .subscribe({
                      next: (org) => {
                        this.organisation = org;
                        this.accountService.organisation.next(this.organisation);
                        //console.log('line 127 : response from current() API ',this.organisation)
                        this.fetchWptFreeTracking(org.id);
                        this.organisationService
                          .query_users(this.organisation.id)
                          .pipe(untilDestroyed(this))
                          .subscribe({
                            next: (data) => {
                              if (data) {
                                //console.log('line 138 : response from query_users API ',data)
                                let users = data.filter((u: any) => {
                                  return (
                                    !this.endsWith(u.login, ' orphaned') &&
                                    !(u.email.startsWith('org#') && this.endsWith(u.email, '@org'))
                                  );
                                });
                                this.usersSize = users.length;
                                if (this.invitesSize || this.invitesSize === 0) {
                                  this.numMaxUsersToRemove =
                                    this.activeWp.numMaxUsers - this.usersSize - this.invitesSize;
                                }
                                ////console.log('line 138 : response from query_users API ',users)
                              }
                            },
                            error: (error) => {
                              console.warn('error [organisationService.query_users]  handle it' + error);
                            },
                          });
                        this.organisationService
                          .query_all({ id: this.organisation.id })
                          .pipe(untilDestroyed(this))
                          .subscribe({
                            next: (response) => {
                              //console.log('line 149 : response from query_all() API ',response)
                              // this.invitesSize = response..length;
                              if (this.usersSize || this.usersSize === 0) {
                                this.numMaxUsersToRemove =
                                  this.activeWp.numMaxUsers - this.usersSize - this.invitesSize;
                                //console.log('line 153 :this.numMaxUsersToRemove ', this.numMaxUsersToRemove)
                              }
                            },
                            error: (error) => {
                              console.warn('error [organisationService.query_all(]  handle it' + error);
                            },
                          });
                      },
                      error: (error) => {
                        console.warn('error [organisationService.current()]  handle it' + error);
                      },
                    });
                },
              });
            this.baselicenceService
              .fetchBaseLicenseDetails({ id: activeWp.baseLicenseId })
              .pipe(untilDestroyed(this))
              .subscribe({
                next: (baseLicense: BaseLicenseDTO) => {
                  //console.log('line 169 : fetchBaseLicenseDetails ', baseLicense)
                  this.baseLicense = baseLicense;
                  this.accountService.baseLicense.next(this.baseLicense);
                  this.discountInPercentIfSiaIndividual = baseLicense.discountInPercentIfSiaIndividual;
                  this.discountInPercentIfSiaCorporate = baseLicense.discountInPercentIfSiaCorporate;
                  this.isBlankLicense = baseLicense.name.trim() === 'Blank Lizenz';
                  this.accountService.isBlankLicense.next(this.isBlankLicense);
                  this.isSchoolLicense = baseLicense.name.trim() === 'Blank Lizenz Schule';
                  this.accountService.isSchoolLicense.next(this.isSchoolLicense);
                  this.isLuucyLicense = baseLicense.name.trim() === 'Blank Lizenz Luucy';
                  if (activeWp.dateTimeInactive || this.licenseState === 'INVALID') {
                    this.baselicenceService
                      .actives()
                      .pipe(untilDestroyed(this))
                      .subscribe({
                        next: (res) => {
                          this.baseLicenses = res;
                        },
                        error: (error) => {
                          console.warn('error ...  handle it' + error);
                        },
                      });
                  }
                },
                error: (error) => {
                  console.warn('error [baselicenceService.fetchBaseLicenseDetails]  handle it' + error);
                },
              });
          }
        },
        error: (error) => {
          console.warn('error [withdrawalProductService.current_for_user()]  handle it' + error);
        },
      });
  }

  pendingWithdrawalProduct() {
    this.withdrawalProductService
      .current_pending_for_user()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pendingWp: WithdrawalProductDTO) => {
          this.billingService
            .fetchPrice(pendingWp.id)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: (res) => {
                //console.log('line 204 : fetchPrice() ', res)
                this.pendingPrice = res;
                this.accountService.pendingPrice.next(this.pendingPrice);
                this.pendingWp = pendingWp;
                this.accountService.pendingWp.next(this.pendingWp);
                //this.pendingWp = pendingWp;
                this.pendingFreeMode = pendingWp.licenseState === 'FREE';
                this.pendingNumAvailableAccountsToAdd = 100 - pendingWp.numMaxUsers;
                this.pendingWp.licenseState = pendingWp.licenseState;

                this.organisationService
                  .current()
                  .pipe(untilDestroyed(this))
                  .subscribe({
                    next: (org: OrganisationDTO) => {
                      //console.log('line 213 : current() ', org)
                      this.organisation = org;
                      this.accountService.organisation.next(this.organisation);
                      this.organisationService
                        .query_users(this.organisation.id)
                        .pipe(untilDestroyed(this))
                        .subscribe({
                          next: (data: any) => {
                            if (data && data.body) {
                              //console.log('line 222 : response from query_users API ',data)
                              let users = data.body.filter((u: any) => {
                                return (
                                  !this.endsWith(u.login, ' orphaned') &&
                                  !(u.email.startsWith('org#') && this.endsWith(u.email, '@org'))
                                );
                              });
                              this.usersSize = users.length;
                              if (this.invitesSize || this.invitesSize === 0) {
                                this.numMaxUsersToRemove =
                                  this.activeWp.numMaxUsers - this.usersSize - this.invitesSize;
                                //console.log('line 225 : this.numMaxUsersToRemove ', this.numMaxUsersToRemove)
                              }
                            }
                          },
                          error: (error) => {
                            console.warn('error [organisationService.query_users]  handle it' + error);
                          },
                        });
                      this.organisationService
                        .query_all({ id: this.organisation.id })
                        .pipe(untilDestroyed(this))
                        .subscribe({
                          next: (response: any) => {
                            //console.log(' this.pendingInvitesSize ',response)
                            this.pendingInvitesSize = response.body.length;
                            if (this.pendingUsersSize || this.usersSize === 0) {
                              this.pendingNumMaxUsersToRemove =
                                this.pendingWp.numMaxUsers - this.pendingUsersSize - this.invitesSize;
                            }
                          },
                          error: (error) => {},
                        });
                    },
                    error: (error) => {
                      console.warn('error [organisationService.current()]  handle it' + error);
                    },
                  });
                this.baselicenceService
                  .fetchBaseLicenseDetails({ id: pendingWp.baseLicenseId })
                  .pipe(untilDestroyed(this))
                  .subscribe({
                    next: (baseLicense: BaseLicenseDTO) => {
                      this.pendingBaseLicense = baseLicense;
                      this.accountService.pendingBaseLicense.next(this.pendingBaseLicense);
                      //console.log('line 252 : fetchBaseLicenseDetails() ', this.pendingBaseLicense)
                      this.pendingDiscountInPercentIfSiaIndividual = baseLicense.discountInPercentIfSiaIndividual;
                      this.pendingDiscountInPercentIfSiaCorporate = baseLicense.discountInPercentIfSiaCorporate;
                      if (!pendingWp.dateTimeInactive || pendingWp.licenseState === 'INVALID') {
                        this.baselicenceService
                          .actives()
                          .pipe(untilDestroyed(this))
                          .subscribe({
                            next: (res) => {
                              this.baseLicenses = res;
                              //console.log('line 258 : this.baseLicenses ', this.baseLicenses)
                            },
                            error: (error) => {
                              console.warn('error [baselicenceService.actives()]  handle it' + error);
                            },
                          });
                      }
                    },
                    error: (error) => {
                      console.warn('error [baselicenceService.fetchBaseLicenseDetails]  handle it' + error);
                    },
                  });
              },
              error: (errorResponse) => {
                this.accountService.pendingWp.next(null);
              },
            });
        },
        error: (error) => {
          console.warn('error [current_pending_for_user()]  handle it' + error.toString());
        },
      });
  }

  renewLicense() {
    this.billingService
      .enableLicenseRenewal()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (ret) => {
          this.router.navigate(['/billing'], {
            state: { paymentMeansSuccess: null, paymentFormSuccess: null },
            replaceUrl: true,
          });
        },
        error: (error) => {
          console.error('Failed to renew license');
        },
      });
  }

  deleteLicense() {
    this.billingService
      .removeLicenseRenewal()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (ret) => {
          this.router.navigate(['/billing'], {
            state: { paymentMeansSuccess: null, paymentFormSuccess: null },
            replaceUrl: true,
          });
        },
        error: (error) => {
          console.error('Failed to remove license renewal');
        },
      });
  }

  showUpdateLicenseModal(
    numAdditionalUsers: number,
    currentNumMaxUsers: number,
    additionalWpts: any,
    freeMode: any,
    licenseState: any,
    validUntil: any,
  ) {
    //open app/account/billing/update_license_additional.modal.html
  }

  showUpdateLicenseRemoveModal(
    numAccountsToRemove: number,
    currentNumMaxUsers: number,
    wptsToRemove: any,
    freeUntil: any | Date,
    validUntil: any | Date,
    freeMode: any,
  ) {
    //  opem app/account/billing/update_license_remove.modal.html
  }

  getWptFreeTrackingDateTimeFreeUntilIfPresent(wpt: any) {
    var result = this.wptFreeTracking.filter((obj: any) => {
      return obj.wpt === wpt;
    });

    if (result.length > 0) {
      return result[0]['dateTimeFreeUntil'];
    }
    return '';
  }

  possibleFreeModule() {
    this.freeWtp = this.existingWptService.getExistingWpt().filter((wpt) => {
      //console.log('wpt',wpt)
      let receivedModules = this.wptFreeTracking.map((track: WptFreeTrackingDTO) => {
        //console.log('track',track)
        return track.wpt;
      });
      return !receivedModules.includes(wpt);
    });
    this.accountService.freeWtp.next(this.freeWtp);
  }

  isCurrentlyFree(wpt: any) {
    var res = this.wptFreeTracking.filter((res: any) => {
      return res.wpt === wpt;
    });

    if (res && res.length > 0) {
      // use typescript to do date rerendering instead of moment
      // return moment.utc().isBefore(moment.parseZone(res[0]["dateTimeFreeUntil"]));
    }
    return '';
  }

  onManipulateLicenseSuccessFn() {
    this.fetchWithdrawalProduct();
    this.pendingWithdrawalProduct();
    // this.updatePrincipal();
  }

  isFutureDiffPayment(track: any) {
    return new Date().toUTCString() < track.dateTimeFreeUntil && !track.dateTimeInactive;
    //return moment.utc().isBefore(track.dateTimeFreeUntil) && !track.dateTimeInactive;
  }

  // getDateAndModulesWithFutureDiffPayment(track:any) {
  //   if (!!this.wptFreeTracking && !(this.activeWp && this.activeWp.licenseStillValid && this.activeWp.dateTimeInactive)) {

  //   } else {
  //       return [];
  //   }
  // }

  // getDateTimeValidUntilForFutureDiffPayment() {
  //   if (this.activeWp.licenseState !== 'FREE') {
  //     console.log('this.activeWp.dateTimeValidUntil',this.activeWp.dateTimeValidUntil)
  //     this.accountService.getDateTimeValidUntilForFutureDiffPayment.next(this.activeWp.dateTimeValidUntil)
  //     return this.activeWp.dateTimeValidUntil;
  //   } else {
  //     console.log('new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString()',new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString())
  //     this.accountService.getDateTimeValidUntilForFutureDiffPayment.next(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString())
  //     return new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();
  //     //return moment(this.activeWp.dateTimeValidUntil).add('years', 1).toISOString();
  //   }
  // }
}
