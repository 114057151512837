import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '@app/account/account.service';
import { BillingModalService } from '@app/core/services/billing/billing/billing-modal/billing-modal.service';
import {
  OrganisationDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BillingComponent } from '../billing/billing.component';
import { WithdrawalProductTypeBuyable } from '@app/account/billings/billing-edit/module-overview/module-overview.component';
import { mergeArrays } from '@generated/extended';
@UntilDestroy()
@Component({
  selector: 'app-billing-modal',
  templateUrl: './billing-modal.component.html',
  styleUrls: ['./billing-modal.component.scss'],
  providers: [
    BillingComponent,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: ['billing', 'register', 'prices', 'global'],
    },
  ],
})
export class BillingModalComponent implements OnInit {
  modules!: WithdrawalProductType | WithdrawalProductType[];
  activeWp!: WithdrawalProductDTO;
  organisation!: OrganisationDTO;
  wptFreeTracking!: WptFreeTrackingDTO[];
  freeWtp!: WithdrawalProductType[];
  moduleName: (WithdrawalProductTypeBuyable | 'LCC')[] = [];

  constructor(
    private dialogRef: MatDialogRef<BillingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { modules: (WithdrawalProductTypeBuyable | 'LCC')[] },
    public _billingComponent: BillingComponent,
    private _billingModalService: BillingModalService,
  ) {
    this.initializeData();
  }

  ngOnInit(): void {
    // this.accountService
    // .getActiveWp()
    // .pipe(untilDestroyed(this))
    // .subscribe({
    //   next: (activeWp) => {
    //     //console.log('activeWp',activeWp);
    //     this.activeWp = activeWp;
    //     // this.paymentMeans = this.activeWp.paymentMeans;
    //     // this.freeMode = this.activeWp.licenseState === 'FREE';
    //   },
    // });
    this.getModulenameList();
  }

  initializeData() {
    this._billingComponent.intializeData();
  }

  getModulenameList() {
    this._billingModalService
      .getModuleNamesList()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          console.warn('response', response);
          if (this.dialogData?.modules != null && response != null) {
            this.moduleName = mergeArrays<WithdrawalProductTypeBuyable | 'LCC'>(this.dialogData.modules, response);
          } else {
            this.moduleName = response;
          }
        },
        error: (errorResponse) => {
          console.warn('errorResponse', errorResponse);
        },
      });
  }

  clear() {
    this.dialogRef.close({
      response: {
        paymentMeansSuccess: null, //here we could actually give return if the module was bought successfully.
        paymentFormSuccess: null, //here we could actually give return if the module was bought successfully.
      },
    });
  }
}
