<div *transloco="let t">
  <div
    *ngIf="activewp && activewp.licenseStillValid && !activewp.dateTimeInactive && !activewp.yearlyFixPrice"
    style="padding-top: 20px; padding-bottom: 33px"
  >
    <div class="centeredBox flexBoxOuter" [ngSwitch]="paymentMeans === 'CREDIT_CARD' || freeMode">
      <div class="flexBoxInner">
        <div class="flexBoxInner" style="text-align: left">
          <h3 data-translate="license.edit.header">{{ t("prices.license.edit.header") }}</h3>
        </div>
        <div *ngIf="!activewp.licenseState.endsWith('UNSUCCESSFUL')">
          <h4 data-translate="license.edit.need-more-accounts">{{ t("prices.license.edit.need-more-accounts") }}</h4>
        </div>
        <div
          class="flexBoxInner"
          style="margin-top: 33px"
          *ngIf="activewp.licenseState == 'PAYMENT_UNSUCCESSFUL' || activewp.licenseState == 'HIBERNATE_UNSUCCESSFUL'"
        >
          <div class="centeredBoxText"></div>
          <div class="flexBoxInner alert kw-attention">
            Zur Zeit können Sie keine zusätzlichen Accounts bestellen - Auf Grund eines ausstehenden Betrag von
            {{ activewp.saferpayAmount / 100 | number: "1.2-2" }} CHF. Bei Problemen (z.B. wechsel des Zahlungsmittels)
            oder Unklarheiten melden Sie sich direkt bei Daniel Hunziker unter +41 56 444 26 01.
          </div>
        </div>
        <div *ngIf="!activewp.licenseState.endsWith('UNSUCCESSFUL')">
          <div class="">
            <div data-translate="license.edit.get-more-accounts-1">
              {{ t("prices.license.edit.get-more-accounts-1") }}
            </div>
            <div data-translate="license.edit.get-more-accounts-2">
              {{ t("prices.license.edit.get-more-accounts-2") }}
            </div>
          </div>
          <div *ngSwitchCase="true">
            <form
              [formGroup]="formAdditionalAccounts"
              name="formAdditionalAccounts"
              role="form"
              novalidate
              ng-submit="showUpdateLicenseModal(numAccountsToAdd, activewp.numMaxUsers, [], freeMode, activewp.licenseState, activewp.dateTimeValidUntil)"
            >
              <fieldset
                [disabled]="
                  activewp.licenseState == 'PAYMENT_UNSUCCESSFUL' || activewp.licenseState == 'HIBERNATE_UNSUCCESSFUL'
                "
                style="border-color: transparent"
              >
                <div class="centeredBoxText flexBoxOuter" style="margin-top: 20px">
                  <div class="flexBoxInner">
                    <div class="form-group">
                      <div class="col-sm-12">
                        <input
                          type="number"
                          class="form-control"
                          id="additionalAccounts"
                          placeholder="{{ t('billing.formAdditionalAccounts.number.placeholder') }}"
                          ng-model="numAccountsToAdd"
                          formControlName="numAccountsToAdd"
                          min="1"
                          (change)="AdditionalAccountsValueChanges($event)"
                          (keyup)="AdditionalAccountsValueChanges($event)"
                          max="{{ numAvailableAccountsToAdd }}}"
                          (blur)="enableBuyAdditionalAccounts()"
                          required
                        />

                        <div
                          [hidden]="
                            !(
                              formAdditionalAccounts.get('numAccountsToAdd')?.dirty &&
                              formAdditionalAccounts.get('numAccountsToAdd')?.invalid
                            )
                          "
                        >
                          <p
                            class="help-block"
                            *ngIf="errorBlock.minError"
                            data-translate="billing.messages.validate.numAvailableAccountsToAdd.min"
                          >
                            {{ t("billing.messages.validate.numAvailableAccountsToAdd.min") }}
                          </p>
                          <p
                            class="help-block"
                            *ngIf="errorBlock.maxError"
                            data-translate="billing.messages.validate.numAvailableAccountsToAdd.max"
                          >
                            {{ t("billing.messages.validate.numAvailableAccountsToAdd.max") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="true"></div>
                <div class="centeredBoxText" style="margin-top: 20px">
                  <button
                    type="button"
                    [disabled]="formAdditionalAccounts.invalid || errorBlock.minError || errorBlock.maxError"
                    (click)="showUpdateLicenseModal()"
                    class="btn btn-elegant-black"
                    style="cursor: pointer"
                    data-translate="billing.formAdditionalAccounts.label"
                  >
                    {{ t("billing.formAdditionalAccounts.label") }}
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
          <div *ngSwitchCase="false">
            <p style="margin-top: 20px">
              <span data-translate="license.call-us-bd-1"> {{ t("prices.license.call-us-bd-1") }}</span>
              <a href="mailto:mailto:{{ t('products.about.text-2-2') }}"
                ><span data-translate="license.call-us-mail">{{ t("prices.license.call-us-mail") }}</span></a
              >
              <span data-translate="license.call-us-bd-2">{{ t("prices.license.call-us-bd-2") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="activewp.numMaxUsers > 1"
      class="centeredBox flexBoxOuter"
      [ngSwitch]="paymentMeans === 'CREDIT_CARD' || freeMode"
    >
      <div class="flexBoxInner">
        <div style="margin-top: 33px">
          <h4 data-translate="license.remove-account.header">{{ t("prices.license.remove-account.header") }}</h4>
        </div>
        <h4 data-translate="license.remove-account.to-many-accounts">
          {{ t("prices.license.remove-account.to-many-accounts") }}
        </h4>
        <div *ngSwitchCase="true">
          <div>
            <ul class="list-unstyled" style="list-style-type: none">
              <li *ngIf="!freeMode" data-translate="license.remove-account.text-1">
                {{ t("prices.license.remove-account.text-1") }}
              </li>
              <li *ngIf="freeMode" data-translate="license.remove-account.text-2">
                {{ t("prices.license.remove-account.text-2") }}
              </li>
            </ul>
          </div>
          <div>
            <form [formGroup]="formRemoveAccounts" role="form" novalidate (ngSubmit)="showUpdateLicenseRemoveModal()">
              <div class="centeredBoxText flexBoxOuter" style="margin-top: 20px">
                <div class="flexBoxInner" style="margin-left: 15px">
                  <div class="form-group">
                    <div class="col-sm-12">
                      <input
                        type="number"
                        class="form-control"
                        id="removeAccounts"
                        name="removeAccounts"
                        placeholder="{{ t('billing.formRemoveAccounts.number.placeholder') }}"
                        formControlName="numAccountsToRemove"
                        min="1"
                        max="{{ numMaxUsersToRemove }}"
                        (blur)="enableRemoveAccounts()"
                        (change)="RemoveAccountsValueChanges($event)"
                        (keyup)="RemoveAccountsValueChanges($event)"
                        required
                      />
                      <div
                        *ngIf="
                          formRemoveAccounts.get('numAccountsToRemove')?.dirty &&
                          formRemoveAccounts.get('numAccountsToRemove')?.invalid
                        "
                      >
                        <p
                          class="help-block"
                          *ngIf="errorBlock.minError"
                          data-translate="billing.messages.validate.numAvailableAccountsToRemove.min"
                        >
                          {{ t("billing.messages.validate.numAvailableAccountsToRemove.min") }}
                        </p>
                        <p
                          class="help-block"
                          *ngIf="formRemoveAccounts.controls['numAccountsToRemove'].hasError('maxlength')"
                        >
                          <span data-translate="billing.messages.validate.numAvailableAccountsToRemove.max-1">{{
                            t("billing.messages.validate.numAvailableAccountsToRemove.max-1")
                          }}</span>
                          <a ui-sref="settings" data-translate="global.menu.account.settings">
                            {{ t("global.menu.account.settings") }}
                          </a>
                          <span data-translate="billing.messages.validate.numAvailableAccountsToRemove.max-2">{{
                            t("billing.messages.validate.numAvailableAccountsToRemove.max-2")
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="centeredBoxText" style="margin-top: 20px">
                <button
                  type="submit"
                  [disabled]="formRemoveAccounts.invalid"
                  class="btn btn-elegant-black"
                  data-translate="billing.formRemoveAccounts.button"
                >
                  {{ t("billing.formRemoveAccounts.button") }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div *ngSwitchCase="false">
          <p style="margin-top: 20px">
            <span data-translate="license.call-us-bd-1">{{ t("prices.license.call-us-bd-1") }}</span>
            <a href="mailto:{{ t('products.about.text-2-2') }}"
              ><span data-translate="license.call-us-mail">{{ t("prices.license.call-us-mail") }}</span></a
            >
            <span data-translate="license.call-us-bd-2">{{ t("prices.license.call-us-bd-2") }} </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
