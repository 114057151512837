import { createFeature, createReducer, Store } from '@ngrx/store';
import { OutRealEstateDTO, RebuildEstimationOutputDTO } from '@generated/generatedEntities';
import { immerOn } from 'ngrx-immer/store';
import { RealEstateActions } from '@app/core/services/store/real-estate.actions';
import { inject } from '@angular/core';

export const realEstatesFeatureKey = 'realEstate';

export enum CALCULATION_STATE {
  INIT = 'INIT',
  CALCULATED = 'CALCULATED',
  NOT_CALCULATED = 'NOT_CALCULATED',
  LOADING = 'LOADING',
  READY = 'READY',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  CALLING = 'CALLING',
}

export interface RealEstateState {
  realEstate: OutRealEstateDTO;
  status: CALCULATION_STATE;
  estimationOutput: RebuildEstimationOutputDTO;
  plots: { [key: string]: any };
}

export const initialState: RealEstateState = {
  realEstate: {} as OutRealEstateDTO,
  status: CALCULATION_STATE.INIT,
  estimationOutput: {} as RebuildEstimationOutputDTO,
  plots: {},
};

const reducer = createReducer(
  initialState,
  immerOn(RealEstateActions.updateRealEstate, (draft: RealEstateState, data) => {
    draft.realEstate = data;
    draft.status = CALCULATION_STATE.LOADED;
  }),
  immerOn(RealEstateActions.loadRealEstate, (draft) => ({
    // realEstate: draft.realEstate,
    status: CALCULATION_STATE.LOADING,
  })),
  immerOn(RealEstateActions.getEmptyRealEstate, (draft) => ({
    realEstate: draft.realEstate,
    status: CALCULATION_STATE.LOADING,
  })),

  immerOn(RealEstateActions.loadRealEstateSuccess, (draft: RealEstateState, data) => {
    draft.realEstate = data;
    draft.status = CALCULATION_STATE.LOADED;
  }),

  immerOn(RealEstateActions.loadRealEstateFailure, (draft: RealEstateState, data) => {
    draft.realEstate = {} as OutRealEstateDTO;
    draft.status = CALCULATION_STATE.ERROR;
  }),
  immerOn(RealEstateActions.estimateLCAEnergyNeeds, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.CALLING;
  }),
  immerOn(RealEstateActions.estimateLCAEnergyNeedsSuccess, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.READY;
    draft.estimationOutput = data;
    if (data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING && data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR) {
      draft.realEstate.energy.current.annualHeatingNeedsPlaceholder = Math.round(
        data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR,
      );
    }
    if (data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING && data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING.YEAR) {
      draft.realEstate.energy.current.annualCoolingNeedsPlaceholder = Math.round(
        data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING.YEAR,
      );
    }
    if (
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION &&
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION.YEAR
    ) {
      //Sum up all values inside the following object data.lcaOutput.ANNUAL_LATENT_ENERGY_NEEDS_FOR_DE_HUMIDIFICATION and round the value
      draft.realEstate.energy.current.annualDehumidificationNeedsPlaceholder = Math.round(
        // Object.values(data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION).reduce((a, b) => a + b, 0),
        // );
        data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION.YEAR,
      );
    }
    if (
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION &&
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION.YEAR
    ) {
      draft.realEstate.energy.current.annualHumidificationNeedsPlaceholder = Math.round(
        // Object.values(data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION).reduce((a, b) => a + b, 0),
        data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION.YEAR,
      );
    }
  }),
  immerOn(RealEstateActions.estimateLCAEnergyPerformance, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.CALLING;
  }),
  immerOn(
    RealEstateActions.estimateLCAEnergyPerformanceSuccess,
    (draft: RealEstateState, data: RebuildEstimationOutputDTO) => {
      draft.status = CALCULATION_STATE.READY;
      //only write the data to the inner fields of estimateOutput if inner object has values loop through the object and check if there are values
      if (draft.estimationOutput == null) {
        draft.estimationOutput = data;
      } else {
        draft.estimationOutput.lcaOutput.RENEWABLE_PRIMARY_ENERGY = data.lcaOutput.RENEWABLE_PRIMARY_ENERGY;
        draft.estimationOutput.lcaOutput.TOTAL_PRIMARY_ENERGY = data.lcaOutput.TOTAL_PRIMARY_ENERGY;
        draft.estimationOutput.lcaOutput.CO_2_EMISSION = data.lcaOutput.CO_2_EMISSION;
        draft.estimationOutput.lcaOutput.COST = data.lcaOutput.COST;
        draft.estimationOutput.lcaOutput.RER = data.lcaOutput.RER;
        draft.estimationOutput.lcaOutput.EPB_USES_OF_ELECTRICITY = data.lcaOutput.EPB_USES_OF_ELECTRICITY;
        draft.estimationOutput.lcaOutput.PRODUCED_ELECTRICITY = data.lcaOutput.PRODUCED_ELECTRICITY;
        draft.estimationOutput.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY =
          data.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY;
        draft.estimationOutput.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING =
          data.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING;
        draft.estimationOutput.lcaOutput.GRID_EXPORTED = data.lcaOutput.GRID_EXPORTED;
        draft.estimationOutput.lcaOutput.CONTRIBUTION_BY_BATTERY = data.lcaOutput.CONTRIBUTION_BY_BATTERY;
        draft.estimationOutput.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION =
          data.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION;
        draft.estimationOutput.lcaOutput.GRID_DELIVERED_ELECTRICITY = data.lcaOutput.GRID_DELIVERED_ELECTRICITY;
        draft.estimationOutput.lcaOutput.CONTRIBUTION = data.lcaOutput.CONTRIBUTION;
        draft.estimationOutput.lcaOutput.FRACTION_CONTRIBUTION = data.lcaOutput.FRACTION_CONTRIBUTION;
        draft.estimationOutput.lcaOutput.DHW = data.lcaOutput.DHW;
        draft.estimationOutput.lcaOutput.LIGHTING = data.lcaOutput.LIGHTING;
        draft.estimationOutput.lcaOutput.APPLIANCES = data.lcaOutput.APPLIANCES;
        draft.estimationOutput.lcaOutput.TRANSPORT = data.lcaOutput.TRANSPORT;
        draft.estimationOutput.lcaOutput.ENERGY_SUM = data.lcaOutput.ENERGY_SUM;
        draft.estimationOutput.lcaOutput.NON_EPB = data.lcaOutput.NON_EPB;
        draft.estimationOutput.lcaOutput.SOLAR_PRODUCIBILITY = data.lcaOutput.SOLAR_PRODUCIBILITY;
        draft.estimationOutput.lcaOutput.PV = data.lcaOutput.PV;
      }

      if (data.lcaOutput.TRANSPORT) {
        draft.realEstate.energy.current.annualTransportNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.TRANSPORT).reduce((a, b) => a + b, 0),
        );
      }

      if (data.lcaOutput.LIGHTING) {
        draft.realEstate.energy.current.annualLightningNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.LIGHTING).reduce((a, b) => a + b, 0),
        );
      }

      if (data.lcaOutput.APPLIANCES) {
        draft.realEstate.energy.current.annualApplianceNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.APPLIANCES).reduce((a, b) => a + b, 0),
        );
      }
      if (data.lcaOutput.DHW) {
        draft.realEstate.energy.current.annualDhwNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.DHW).reduce((a, b) => a + b, 0),
        );
      }
      if (data.lcaOutput.PV) {
        draft.realEstate.energy.current.annualPvRoofProduction = Math.round(
          Object.values(data.lcaOutput.PV).reduce((a, b) => a + b, 0),
        );
      }
    },
  ),
  immerOn(RealEstateActions.getLCAPlotCRREMCO2Success, (draft: RealEstateState, data) => {
    console.log('getLCAPlotCRREMCO2Success', data);

    // blobToBase64(draft).then((base64) => {
    // console.log(base64);
    // draft.plots['CRREMCO2'] = base64;
    // return RealEstateActions.getLCAPlotCRREMCO2Success(ret);
    // });
    if (draft.plots === undefined) {
      draft.plots = {};
    }
    draft.plots['CRREMCO2'] = data.base64;
  }),
);

export const realEstatesFeature = createFeature({
  name: realEstatesFeatureKey,
  reducer,
  // extraSelectors: ({ selectStatus, selectRealEstate }) => ({
  //   selectRealEstate: createSelector(
  //     selectStatus,
  //     selectRealEstatesState,
  //     (query, realEstate) => realEstate.realEstate,
  //   ),
  // }),
  // extraSelectors: ({ selectRealEstatesState }) => ({
  //   ...adapter.getSelectors(selectRealEstatesState),
  // }),
});

export function injectRealEstateFeature() {
  const store = inject(Store);
  return {
    realEstate$: store.select(realEstatesFeature.selectRealEstate),
  };
}
//other way of declaring effect for easier testability.
// export const loadEmptyRealEstateDto$ = createEffect(
//   (actions$ = inject(Actions), store = inject(Store), http = inject(HttpClient)) => {
//     // const http = inject(HttpClient);
//     return actions$.pipe(
//       ofType(RealEstateActions.getEmptyRealEstate),
//       withLatestFrom(store.select(realEstatesFeature.selectRealEstate)),
//       concatMap(([request, filters]) =>
//         http.get(`${environment.serverUrl}/api/rebuild_estate/empty`).pipe(
//           map(
//             (realestate: any) => {
//               realestate.metaData.withdrawalProductType = request.wpt;
//
//               realestate.metaData.tasks = request.tasks.length === 0 ? [request.wpt] : request.tasks;
//               // let clonedRealEstate = _.cloneDeep(realestate);
//               this.prepareRealEstateEmptyObject(realestate, request.wpt);
//               // console.error('prepareRealEstateEmptyObject: ' + JSON.stringify(clonedRealEstate));
//               return RealEstateActions.loadRealEstateSuccess(realestate);
//             },
//             catchError((error) => of(RealEstateActions.loadRealEstateFailure(error))),
//             // (),
//           ),
//         ),
//       ),
//     );
//   },
//   { functional: true },
// );
