import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billing-buy-license',
  templateUrl: './billing-buy-license.component.html',
  styleUrls: ['./billing-buy-license.component.scss'],
})
export class BillingBuyLicenseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
