import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AccountService } from '@app/account/account.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BaseLicenseDTO,
  OrganisationDTO,
  WithdrawalProductDTO,
  WithdrawalProductType,
  WptFreeTrackingDTO,
} from '@generated/generatedEntities';
import { addYears, parseJSON } from 'date-fns';

@UntilDestroy()
@Component({
  selector: 'app-billing-information-active',
  templateUrl: './billing-information-active.component.html',
  styleUrls: ['./billing-information-active.component.scss'],
})
export class BillingInformationActiveComponent implements OnInit, AfterContentInit {
  activeWp: WithdrawalProductDTO = {} as WithdrawalProductDTO;
  organisation!: OrganisationDTO;
  wptFreeTracking: WptFreeTrackingDTO[] = [];
  freeWtp: WithdrawalProductType[] = [];
  baseLicense: BaseLicenseDTO = {} as BaseLicenseDTO;
  price: any;
  pendingWp!: WithdrawalProductDTO | null;
  pendingBaseLicense: BaseLicenseDTO = {} as BaseLicenseDTO;
  pendingPrice: any;
  isSchoolLicense: boolean = false;
  isBlankLicense: boolean = false;
  freeMode: boolean = false;
  // @Input() getDateTimeValidUntilForFutureDiffPayment: any;
  onManipulateLicenseSuccessFn: any;
  dateTimeValidUntilForFutureDiffPayment: string = '';

  @Output() licenceChanged = new EventEmitter<void>();
  currentDate!: Date;

  constructor(
    private accountService: AccountService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterContentInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.loadValues();
  }

  isFutureDiffPayment(track: any) {
    //this.accountService.setisFutureDiffPayment(track);
    return new Date() < new Date(track.dateTimeFreeUntil) && !track.dateTimeInactive;
  }

  getDateTimeValidUntilForFutureDiffPayment() {
    if (this.activeWp.licenseState !== 'FREE') {
      // this.accountService.getDateTimeValidUntilForFutureDiffPayment.next(this.activeWp.dateTimeValidUntil)
      this.dateTimeValidUntilForFutureDiffPayment = parseJSON(
        this.activeWp.dateTimeValidUntil.toString(),
      ).toISOString();
    } else {
      this.dateTimeValidUntilForFutureDiffPayment = addYears(
        parseJSON(this.activeWp.dateTimeValidUntil.toString()),
        1,
      ).toISOString();
      //return moment(this.activeWp.dateTimeValidUntil).add('years', 1).toISOString();
    }
  }

  private loadValues(): void {
    this.currentDate = new Date();
    this.accountService
      .getActiveWp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (activeWp) => {
          //console.log('activeWp',activeWp);
          if (activeWp != null) {
            this.activeWp = activeWp;
            this.getDateTimeValidUntilForFutureDiffPayment();
          } else {
            this.activeWp = {} as WithdrawalProductDTO;
          }
        },
      });
    this.accountService
      .getOrganisation()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (organisation: OrganisationDTO | null) => {
          //console.log('organisation',organisation);
          if (organisation != null) {
            this.organisation = organisation;
          } else {
            this.organisation = {} as OrganisationDTO;
          }
        },
      });
    this.accountService
      .getWptFreeTracking()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (wptFreeTracking: WptFreeTrackingDTO[] | null) => {
          //console.log('wptFreeTracking',wptFreeTracking);
          if (wptFreeTracking != null) {
            this.wptFreeTracking = wptFreeTracking;
            console.warn(this.wptFreeTracking);
          } else {
            this.wptFreeTracking = [];
          }
        },
      });
    this.accountService
      .getFreeWtp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (freeWtp) => {
          //console.log('freeWtp',freeWtp);
          if (freeWtp != null) {
            this.freeWtp = freeWtp;
          } else {
            this.freeWtp = [];
          }
        },
      });
    this.accountService
      .getBaseLicense()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (baseLicense: BaseLicenseDTO | null) => {
          //console.log('baseLicense',baseLicense);
          if (baseLicense != null) {
            this.baseLicense = baseLicense;
          } else {
            this.baseLicense = {} as BaseLicenseDTO;
          }
        },
      });
    this.accountService
      .getPrice()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (price) => {
          //console.log('price',price);
          this.price = price;
        },
      });
    this.accountService
      .getPendingWp()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pendingWp: WithdrawalProductDTO | null) => {
          // console.log('pendingWp', pendingWp);
          if (pendingWp != null) {
            this.pendingWp = pendingWp;
          } else {
            this.pendingWp = null;
          }
        },
      });
    this.accountService
      .getPendingBaseLicense()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pendingBaseLicense: BaseLicenseDTO | null) => {
          //console.log('pendingBaseLicense',pendingBaseLicense);
          if (pendingBaseLicense != null) {
            this.pendingBaseLicense = pendingBaseLicense;
          } else {
            this.pendingBaseLicense = {} as BaseLicenseDTO;
          }
        },
      });
    this.accountService
      .getPendingPrice()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (pendingPrice) => {
          //console.log('pendingPrice',pendingPrice);
          this.pendingPrice = pendingPrice;
        },
      });

    this.accountService
      .getIsBlankLicense()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isBlankLicense) => {
          // console.log('isBlankLicense', isBlankLicense);
          this.isBlankLicense = isBlankLicense;
        },
      });
    this.accountService
      .getIsSchoolLicense()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isSchoolLicense) => {
          // console.log('isSchoolLicense', isSchoolLicense);
          this.isSchoolLicense = isSchoolLicense;
        },
      });

    this.accountService
      .getIsFreeMode()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (freeMode) => {
          //console.log('freeMode',freeMode);
          this.freeMode = freeMode;
        },
      });

    // this.accountService.getGetDateTimeValidUntilForFutureDiffPayment().pipe(untilDestroyed(this)).subscribe({
    //   next: (getDateTimeValidUntilForFutureDiffPayment) => {
    //     //console.log('getDateTimeValidUntilForFutureDiffPayment',getDateTimeValidUntilForFutureDiffPayment);
    //     this.getDateTimeValidUntilForFutureDiffPayment = getDateTimeValidUntilForFutureDiffPayment
    //   }
    // })

    this.accountService
      .getOnManipulateLicenseSuccessFn()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (onManipulateLicenseSuccessFn) => {
          //console.log('onManipulateLicenseSuccessFn',onManipulateLicenseSuccessFn);
          this.onManipulateLicenseSuccessFn = onManipulateLicenseSuccessFn;
        },
      });
  }
}
