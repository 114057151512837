import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { RealEstateActions } from './real-estate.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { from, of } from 'rxjs';
import {
  AdditionEntryDTO,
  OutRealEstateDTO,
  RealEstateUsageEmbeddable,
  WithdrawalProductType,
} from '@generated/generatedEntities';
import { realEstatesFeature } from '@app/core/services/store/real-estate.state';

@Injectable()
export class RealEstateEffects {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly store = inject(Store);
  private readonly actions$: Actions = inject(Actions);

  loadRealEstateDtos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.getEmptyRealEstate),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstate)),
      concatMap(([request, filters]) =>
        this.httpClient.get(`${environment.serverUrl}/api/rebuild_estate/empty`).pipe(
          map(
            (realestate: any) => {
              realestate.metaData.withdrawalProductType = request.wpt;

              realestate.metaData.tasks = request.tasks.length === 0 ? [request.wpt] : request.tasks;
              // let clonedRealEstate = _.cloneDeep(realestate);
              // this.prepareRealEstateEmptyObjectTestCase(realestate, request.wpt);
              this.prepareRealEstateEmptyObjectTestCaseEnergyNeeds(realestate, request.wpt);
              // console.error('prepareRealEstateEmptyObject: ' + JSON.stringify(clonedRealEstate));
              return RealEstateActions.loadRealEstateSuccess(realestate);
            },
            catchError((error) => of(RealEstateActions.loadRealEstateFailure(error))),
            // (),
          ),
        ),
      ),
    );
  });

  estimateEnergyNeeds$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCAEnergyNeeds),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstate)),
      concatMap(([request, filters]) =>
        this.httpClient.post(`${environment.serverUrl}/api/withdrawal/energy/needs`, filters).pipe(
          map(
            (energyNeeds: any) => {
              return RealEstateActions.estimateLCAEnergyNeedsSuccess(energyNeeds);
            },
            catchError((error) => of(RealEstateActions.estimateLCAEnergyNeedsFailure(error))),
            // (),
          ),
        ),
      ),
    );
  });

  estimateEnergyPerformance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.estimateLCAEnergyPerformance),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectRealEstate)),
      concatMap(([request, filters]) =>
        this.httpClient.post(`${environment.serverUrl}/api/withdrawal/energy/performance`, filters).pipe(
          map(
            (energyPerformance: any) => {
              return RealEstateActions.estimateLCAEnergyPerformanceSuccess(energyPerformance);
            },
            catchError((error) => of(RealEstateActions.estimateLCAEnergyPerformanceFailure(error))),
            // (),
          ),
        ),
      ),
    );
  });

  plotLCA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RealEstateActions.getLCAPlotCRREMCO2),
      concatLatestFrom(() => this.store.select(realEstatesFeature.selectEstimationOutput)),
      concatMap(([request, filters]) => {
        var payload = {
          data: [24, 22, 20, 18, 17, 15, 14, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 3, 2, 2, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0],
          intensity: [
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266, 2.4590810900248266,
            2.4590810900248266,
          ],
          years: [
            2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037,
            2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050,
          ],
          colors1: [235, 218, 217],
          colors2: [187, 124, 122],
          intersect: [17.540918909975172, 2.4590810900248266],
          width: 500,
          height: 300,
        };

        const headers = new HttpHeaders()
          .set('content-type', 'application/json')
          // .set('Access-Control-Allow-Origin', '*')
          .append('Accept', 'image/svg+xml');
        return this.httpClient
          .post(`${environment.kvPlotService}/crrem-co2`, payload, { headers, responseType: 'blob' })
          .pipe(
            switchMap((image: Blob) => {
              const blobToBase64 = (blob: Blob): Promise<string> => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((resolve) => {
                  reader.onloadend = () => {
                    resolve(reader.result as string);
                  };
                });
              };
              return from(blobToBase64(image));
            }),
            map((base64: string) => RealEstateActions.getLCAPlotCRREMCO2Success({ base64 })),
            catchError((error) => of(RealEstateActions.getLCAPlotCRREMCO2Error(error))),
          );
      }),
    );
  });

  private prepareRealEstateEmptyObject(realEstateObj: OutRealEstateDTO, wtp: WithdrawalProductType) {
    switch (wtp) {
      case 'OPERATING_COSTS':
        realEstateObj.additions.length = 0;
        realEstateObj.additions.push({} as AdditionEntryDTO);
        break;

      case 'INSURANCE_VALUES_GVBS':
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: null,
        } as AdditionEntryDTO);
        break;

      default:
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: 'BKP_6_RESERVE',
        } as AdditionEntryDTO);
        realEstateObj.additions.push({
          label: '',
          bkp: null,
        } as AdditionEntryDTO);
    }
    //TODO LCA this should not be needed - should be handled by the backend.
    // if (realEstateObj.quality.facadeTypes == null) {
    //   realEstateObj.quality.facadeTypes = [];
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    // }
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain =
    //   realEstateObj.quality.exteriorWallsCladdingAboveTerrain ?? [];
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain.push({
    //   area: 20,
    //   uvalue: 0.4,
    //   orientation: 'NV',
    //   base: 'AW_s_6',
    //   isolation: 'AW_a_3',
    //   surface: 'AW_o_3',
    // } as ExteriorWallsAboveTerrainContainer);
    // realEstateObj.quality.exteriorWallsCladdingUnderTerrain ?? [];
    realEstateObj.energy = {
      current: {
        pvPeakPower: 3.0,
        pvPeakPowerUserInput: 3.0,
        pvPeakPowerUserPlaceholder: 3.0,
        pvExport: null,
        hotWaterFromHeating: null,
        heatPower: null,
        batteryCapacity: null,
        annualPvRoofProduction: null,
        annualPvRoofProductionUserInput: null,
        annualPvFacadeProduction: null,
        annualPvFacadeProductionUserInput: 0,
        energySource: null,
        annualHeatingNeeds: null,
        annualHeatingNeedsPlaceholder: null,
        annualHeatingNeedsUserInput: null,
        annualCoolingNeeds: null,
        annualCoolingNeedsPlaceholder: null,
        annualCoolingNeedsUserInput: null,
        annualDhwNeeds: null,
        annualDhwNeedsPlaceholder: null,
        annualDhwNeedsUserInput: null,
        annualThermalSolarProduction: null,
        annualLightningNeeds: null,
        annualLightningNeedsPlaceholder: null,
        annualLightningNeedsUserInput: null,
        annualApplianceNeedsUserInput: null,
        annualApplianceNeeds: null,
        annualApplianceNeedsPlaceholder: null,
        annualNonEPBNeedsUserInput: null,
        annualNonEPBNeeds: null,
        annualNonEPBNeedsPlaceholder: null,
        annualTransportNeedsUserInput: null,
        annualTransportNeeds: null,
        annualTransportNeedsPlaceholder: null,
        annualDehumidificationNeeds: null,
        annualDehumidificationNeedsPlaceholder: null,
        annualDehumidificationNeedsUserInput: null,
        annualHumidificationNeeds: null,
        annualHumidificationNeedsPlaceholder: null,
        annualHumidificationNeedsUserInput: null,
      },
      target: undefined,
    };
    realEstateObj.usages.push({
      type: 'WOHNBAUTEN__MFH',
      standard: 'MEDIUM',
      percentage: 100,
      numFuUsage: 12,
      comfortClass: 'CC3',
    } as RealEstateUsageEmbeddable);
    realEstateObj.quality.facadeTypes = [
      {
        facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
        uValue: 0.5,
        sqrMeter: 200,
        orientation: 'NV',
        percentage: 100,
      },
    ];
    realEstateObj.quality.windowsAboveTerrain = [
      {
        uvalue: 0.2,
        area: 100,
        orientation: 'NV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
    ];
    realEstateObj.quality.roofsAboveTerrain = [
      {
        uvalue: 0.2,
        area: 100,
        orientation: 'NV',
        base: 'FD_s_2',
        isolation: 'FD_a_1',
        surface: 'FD_o_1',
      },
    ];
    realEstateObj.quality.groundFloorSlabs = [
      {
        uvalue: 0.2,
        area: 100,
        orientation: 'NV',
        base: 'BP_s_2',
        isolation: 'BP_a_2',
        surface: 'BP_o_1',
      },
    ];
    realEstateObj.quality.heatingContainers = [
      {
        heatingType: 'OIL',
        distribution: 'WUE_2',
        percentage: 100,
        fossileAmount: null,
      },
    ];
    realEstateObj.geometry.totalVolume416 = 1800;
    realEstateObj.geometry.totalFloorArea416 = 700;
    realEstateObj.geometry.energyReferenceArea = 700;
    realEstateObj.geometry.areaBuilding416 = 250;
  }

  private prepareRealEstateEmptyObjectTestCase(realEstateObj: OutRealEstateDTO, wtp: WithdrawalProductType) {
    switch (wtp) {
      case 'OPERATING_COSTS':
        realEstateObj.additions.length = 0;
        realEstateObj.additions.push({} as AdditionEntryDTO);
        break;

      case 'INSURANCE_VALUES_GVBS':
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: null,
        } as AdditionEntryDTO);
        break;

      default:
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: 'BKP_6_RESERVE',
        } as AdditionEntryDTO);
        realEstateObj.additions.push({
          label: '',
          bkp: null,
        } as AdditionEntryDTO);
    }
    //TODO LCA this should not be needed - should be handled by the backend.
    // if (realEstateObj.quality.facadeTypes == null) {
    //   realEstateObj.quality.facadeTypes = [];
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    // }
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain =
    //   realEstateObj.quality.exteriorWallsCladdingAboveTerrain ?? [];
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain.push({
    //   area: 20,
    //   uvalue: 0.4,
    //   orientation: 'NV',
    //   base: 'AW_s_6',
    //   isolation: 'AW_a_3',
    //   surface: 'AW_o_3',
    // } as ExteriorWallsAboveTerrainContainer);
    // realEstateObj.quality.exteriorWallsCladdingUnderTerrain ?? [];
    realEstateObj.energy = {
      current: {
        pvPeakPower: 3.0,
        pvPeakPowerUserInput: 3.0,
        pvPeakPowerUserPlaceholder: null,
        pvExport: true,
        hotWaterFromHeating: null,
        heatPower: null,
        batteryCapacity: null,
        annualPvRoofProduction: null,
        annualPvRoofProductionUserInput: null,
        annualPvFacadeProduction: null,
        annualPvFacadeProductionUserInput: 0,
        energySource: null,
        annualHeatingNeeds: null,
        annualHeatingNeedsPlaceholder: null,
        annualHeatingNeedsUserInput: null,
        annualCoolingNeeds: null,
        annualCoolingNeedsPlaceholder: null,
        annualCoolingNeedsUserInput: null,
        annualDhwNeeds: null,
        annualDhwNeedsPlaceholder: null,
        annualDhwNeedsUserInput: null,
        annualThermalSolarProduction: 2452,
        annualLightningNeeds: null,
        annualLightningNeedsPlaceholder: null,
        annualLightningNeedsUserInput: null,
        annualApplianceNeedsUserInput: null,
        annualApplianceNeeds: null,
        annualApplianceNeedsPlaceholder: null,
        annualNonEPBNeedsUserInput: null,
        annualNonEPBNeeds: null,
        annualNonEPBNeedsPlaceholder: null,
        annualTransportNeedsUserInput: null,
        annualTransportNeeds: null,
        annualTransportNeedsPlaceholder: null,
        annualDehumidificationNeeds: null,
        annualDehumidificationNeedsPlaceholder: null,
        annualDehumidificationNeedsUserInput: null,
        annualHumidificationNeeds: null,
        annualHumidificationNeedsPlaceholder: null,
        annualHumidificationNeedsUserInput: null,
      },
      target: undefined,
    };
    realEstateObj.usages.push({
      type: 'WOHNBAUTEN__MFH',
      standard: 'MEDIUM',
      percentage: 100,
      numFuUsage: 2.22885714285713,
      comfortClass: 'CC2',
    } as RealEstateUsageEmbeddable);
    realEstateObj.quality.facadeTypes = [
      {
        facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
        uValue: 1,
        sqrMeter: 210,
        orientation: 'SEV',
        percentage: 100,
      },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 1,
      //   sqrMeter: 52,
      //   orientation: 'NWV',
      //   percentage: 100,
      // },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 1,
      //   sqrMeter: 53,
      //   orientation: 'NEV',
      //   percentage: 100,
      // },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 0.55648303,
      //   sqrMeter: 54,
      //   orientation: 'SWV',
      //   percentage: 100,
      // },
    ];

    realEstateObj.quality.exteriorWallsCladdingAboveTerrain = [
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 51,
        orientation: 'SEV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 52,
        orientation: 'NWV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 53,
        orientation: 'NEV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 0.55648303,
        area: 54,
        orientation: 'SWV',
      },
    ];

    realEstateObj.quality.windowsAboveTerrain = [
      {
        uvalue: 2.984,
        area: 12,
        orientation: 'SEV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 12,
        orientation: 'NWV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 13,
        orientation: 'NEV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 14,
        orientation: 'SWV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
    ];
    realEstateObj.quality.roofsAboveTerrain = [
      {
        uvalue: 0.3342246,
        area: 48,
        orientation: 'NV',
        base: 'FD_s_2',
        isolation: 'FD_a_1',
        surface: 'FD_o_1',
      },
    ];
    realEstateObj.quality.groundFloorSlabs = [
      {
        uvalue: 0.03972195,
        area: 48,
        orientation: 'NV',
        base: 'BP_s_2',
        isolation: 'BP_a_2',
        surface: 'BP_o_1',
      },
    ];
    realEstateObj.quality.heatingContainers = [
      {
        heatingType: 'HEAT_PUMP_AIR',
        distribution: 'WUE_1',
        percentage: 100,
        fossileAmount: null,
      },
      {
        heatingType: 'NATURAL_GAS',
        distribution: 'WUE_1',
        percentage: 100,
        fossileAmount: null,
      },
    ];
    realEstateObj.geometry.totalVolume416 = 1800;
    realEstateObj.geometry.totalFloorArea416 = 700;
    realEstateObj.geometry.energyReferenceArea = 1800;
    realEstateObj.geometry.areaBuilding416 = 250;
    realEstateObj.geometry.elevator.vertical = 1;
    realEstateObj.quality.pv = true;
  }

  private prepareRealEstateEmptyObjectTestCaseEnergyNeeds(realEstateObj: OutRealEstateDTO, wtp: WithdrawalProductType) {
    switch (wtp) {
      case 'OPERATING_COSTS':
        realEstateObj.additions.length = 0;
        realEstateObj.additions.push({} as AdditionEntryDTO);
        break;

      case 'INSURANCE_VALUES_GVBS':
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: null,
        } as AdditionEntryDTO);
        break;

      default:
        realEstateObj.additions.push({
          label: 'Reserve',
          bkp: 'BKP_6_RESERVE',
        } as AdditionEntryDTO);
        realEstateObj.additions.push({
          label: '',
          bkp: null,
        } as AdditionEntryDTO);
    }
    //TODO LCA this should not be needed - should be handled by the backend.
    // if (realEstateObj.quality.facadeTypes == null) {
    //   realEstateObj.quality.facadeTypes = [];
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    //   realEstateObj.quality.facadeTypes.push({} as FacadeContainer);
    // }
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain =
    //   realEstateObj.quality.exteriorWallsCladdingAboveTerrain ?? [];
    // realEstateObj.quality.exteriorWallsCladdingAboveTerrain.push({
    //   area: 20,
    //   uvalue: 0.4,
    //   orientation: 'NV',
    //   base: 'AW_s_6',
    //   isolation: 'AW_a_3',
    //   surface: 'AW_o_3',
    // } as ExteriorWallsAboveTerrainContainer);
    // realEstateObj.quality.exteriorWallsCladdingUnderTerrain ?? [];
    realEstateObj.energy = {
      current: {
        pvPeakPower: 3.0,
        pvPeakPowerUserInput: 3.0,
        pvPeakPowerUserPlaceholder: null,
        pvExport: true,
        hotWaterFromHeating: null,
        heatPower: null,
        batteryCapacity: null,
        annualPvRoofProduction: null,
        annualPvRoofProductionUserInput: null,
        annualPvFacadeProduction: null,
        annualPvFacadeProductionUserInput: 0,
        energySource: null,
        annualHeatingNeeds: null,
        annualHeatingNeedsPlaceholder: null,
        annualHeatingNeedsUserInput: null,
        annualCoolingNeeds: null,
        annualCoolingNeedsPlaceholder: null,
        annualCoolingNeedsUserInput: null,
        annualDhwNeeds: null,
        annualDhwNeedsPlaceholder: null,
        annualDhwNeedsUserInput: null,
        annualThermalSolarProduction: 2452,
        annualLightningNeeds: null,
        annualLightningNeedsPlaceholder: null,
        annualLightningNeedsUserInput: null,
        annualApplianceNeedsUserInput: null,
        annualApplianceNeeds: null,
        annualApplianceNeedsPlaceholder: null,
        annualNonEPBNeedsUserInput: null,
        annualNonEPBNeeds: null,
        annualNonEPBNeedsPlaceholder: null,
        annualTransportNeedsUserInput: null,
        annualTransportNeeds: null,
        annualTransportNeedsPlaceholder: null,
        annualDehumidificationNeeds: null,
        annualDehumidificationNeedsPlaceholder: null,
        annualDehumidificationNeedsUserInput: null,
        annualHumidificationNeeds: null,
        annualHumidificationNeedsPlaceholder: null,
        annualHumidificationNeedsUserInput: null,
      },
      target: undefined,
    };
    realEstateObj.usages.push({
      type: 'WOHNBAUTEN__MFH',
      standard: 'MEDIUM',
      percentage: 100,
      numFuUsage: 2.22885714285713,
      comfortClass: 'CC1',
    } as RealEstateUsageEmbeddable);
    realEstateObj.quality.facadeTypes = [
      {
        facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
        uValue: 1,
        sqrMeter: 210,
        orientation: 'SEV',
        percentage: 100,
      },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 1,
      //   sqrMeter: 52,
      //   orientation: 'NWV',
      //   percentage: 100,
      // },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 1,
      //   sqrMeter: 53,
      //   orientation: 'NEV',
      //   percentage: 100,
      // },
      // {
      //   facadeType: 'AUSSENDAEMMUNG_KLEID_EDEL',
      //   uValue: 0.55648303,
      //   sqrMeter: 54,
      //   orientation: 'SWV',
      //   percentage: 100,
      // },
    ];

    realEstateObj.quality.exteriorWallsCladdingAboveTerrain = [
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 51,
        orientation: 'SEV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 52,
        orientation: 'NWV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 53,
        orientation: 'NEV',
      },
      {
        base: 'AW_s_1',
        surface: 'AW_o_1',
        isolation: 'AW_a_1',
        uvalue: 1,
        area: 54,
        orientation: 'SWV',
      },
    ];

    realEstateObj.quality.windowsAboveTerrain = [
      {
        uvalue: 2.984,
        area: 12,
        orientation: 'SEV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 12,
        orientation: 'NWV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 13,
        orientation: 'NEV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
      {
        uvalue: 2.984,
        area: 14,
        orientation: 'SWV',
        base: 'F_s_1',
        isolation: 'F_a_3',
        surface: 'F_o_0',
      },
    ];
    realEstateObj.quality.roofsAboveTerrain = [
      {
        uvalue: 0.33,
        area: 200,
        orientation: 'NV',
        base: 'FD_s_2',
        isolation: 'FD_a_1',
        surface: 'FD_o_1',
      },
    ];
    realEstateObj.quality.groundFloorSlabs = [
      {
        uvalue: 0.5,
        area: 48,
        // orientation: 'NV',

        base: 'BP_s_2',
        isolation: 'BP_a_2',
        surface: 'BP_o_1',
      },
    ];
    realEstateObj.quality.heatingContainers = [
      {
        heatingType: 'HEAT_PUMP_AIR',
        distribution: 'WUE_1',
        percentage: 100,
        fossileAmount: null,
      },
    ];
    // realEstateObj.geometry.totalVolume416 = 1800;
    // realEstateObj.geometry.totalFloorArea416 = 700;
    realEstateObj.geometry.energyReferenceArea = 48;
    // realEstateObj.geometry.areaBuilding416 = 250;
    // realEstateObj.geometry.elevator.vertical = 1;
    // realEstateObj.quality.pv = true;
  }
}
