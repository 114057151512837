import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-billing-payment-unsuccessful',
  templateUrl: './billing-payment-unsuccessful.component.html',
  styleUrls: ['./billing-payment-unsuccessful.component.scss'],
})
export class BillingPaymentUnsuccessfulComponent implements OnInit {
  @Output() licenceChanged = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
