import { Injectable } from '@angular/core';
import { Logger } from '@shared';
import { concatMap, delay, Observable, of, Subscription, switchMap, take, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { sub } from 'date-fns';
const log = new Logger('CredentialsService');

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private tokenSubscription = new Subscription();

  private loginModalOpen = false;

  private _expiryDate: Date = new Date();
  showInsuranceValues!: boolean;

  constructor(private dialog: MatDialog) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      if (this._credentials) {
        this.verifyTokenExpireTime(this._credentials.token);
      }
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   * Gets the user credentials - dont use this to access the username: instead use {@link AuthenticationService}
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    if (this._credentials != null) {
      return this._credentials;
    } else {
      return this._credentials;
    }
  }

  private verifyTokenExpireTime(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    this._expiryDate = new Date(expiry * 1000);
    this.updateExpireTimer();

    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  private updateExpireTimer() {
    this.tokenSubscription.unsubscribe();
    // log.warn(this._expiryDate);
    this.tokenSubscription = of(false)
      .pipe(delay(sub(this._expiryDate, { minutes: 2 })))
      .subscribe({
        complete: () => {
          if (!this.loginModalOpen) {
            // this.loginModalOpen = true;
            // const loginModal = this.dialog.open(LoginComponent, {
            //   width: '600px',
            //   panelClass: 'custom-modalbox',
            //   closeOnNavigation: true,
            // });
            //
            // loginModal
            //   .afterClosed()
            //   .pipe(take(1))
            //   .subscribe({
            //     complete: () => {
            //       this.loginModalOpen = false;
            //     },
            //   });
          }
        },
      });
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
      this.verifyTokenExpireTime(credentials.token);
    } else {
      this.tokenSubscription.unsubscribe();
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}
