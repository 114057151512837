<div *transloco="let t">
  <div class="modal-header">
    <button aria-label="Close" data-dismiss="modal" class="close" type="button" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" data-translate="billing.formAdditionalAccounts.label">
      {{ t("billing.formAdditionalAccounts.label") }}
    </h4>
  </div>
  <div class="modal-body pad" *ngIf="priceForNextWindow || priceForNextWindow === 0">
    <div class="centeredBoxText" style="margin-top: 33px">
      <ul class="list-unstyled">
        <li *ngIf="!freeMode" data-translate="billing.formAdditionalAccounts.text-1">
          {{ t("billing.formAdditionalAccounts.text-1") }}
          <!-- Der Preis für die Restlaufzeit Ihrer aktuellen Lizenz -->
        </li>
        <li *ngIf="!freeMode" data-translate="billing.formAdditionalAccounts.text-2">
          {{ t("billing.formAdditionalAccounts.text-2") }}
          <!-- berechnet sich aus den pendenten Tagen bis zum Erneuerungsdatum Ihrer Lizenz. -->
        </li>
        <li *ngIf="!freeMode" data-translate="billing.formAdditionalAccounts.text-3">
          {{ t("billing.formAdditionalAccounts.text-3") }}
          <!-- Dieser ist sofort nach Bestellung fällig und wird unmittelbar von Ihrer Kreditkarte belastet. -->
        </li>
        <li *ngIf="!freeMode">
          Nach erfolgreicher Autorisierung der Kreditkartentransaktion werden die Accounts sogleich eröffnet - i.d.R
          weniger als 1 min.
        </li>
        <li *ngIf="freeMode" data-translate="billing.formAdditionalAccounts.text-1b">
          {{ t("billing.formAdditionalAccounts.text-1b") }}
          <!-- Die Accounts stehen unmittelbar nach Bestellung zur Verfügung. -->
        </li>
        <li *ngIf="freeMode" data-translate="billing.formAdditionalAccounts.text-2b">
          {{ t("billing.formAdditionalAccounts.text-2b") }}
          <!-- Die Lizenzkosten fallen erst nach Ablauf der Gratiswoche an. -->
        </li>
      </ul>
    </div>
    <form
      [formGroup]="formAdditionalAccounts"
      name="formAdditionalAccounts"
      role="form"
      novalidate
      (ngSubmit)="buyAdditionalUsers()"
      style="margin-top: 66px"
    >
      <div class="centeredBox flexBoxOuter">
        <table class="table table-blue-border flexBoxInner">
          <thead>
            <tr>
              <th style="text-align: right"></th>
              <th style="text-align: left"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!freeMode">
              <td style="width: 50%; text-align: right" data-translate="billing.table.pricing.text-1c">
                {{ t("billing.table.pricing.text-1c") }}
                <!-- Zusätzliche Gebühr exkl. MWST für die Restlaufzeit Ihrer aktuellen Lizenz -->
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">{{ priceForCurrentWindow / 100 | number: "2" }} CHF</div>
              </td>
            </tr>
            <tr *ngIf="licenseState !== 'HIBERNATE'">
              <td style="width: 50%; text-align: right" *ngIf="!freeMode">
                <span data-translate="billing.table.pricing.text-1">
                  {{ t("billing.table.pricing.text-1") }}
                  <!-- Zusätzliche jährliche Gebühr exkl. MWST nach Lizenzerneuerung (ab -->
                </span>
                {{ validUntil | date: "dd/MM/yy" }} )
              </td>
              <td style="width: 50%; text-align: right" *ngIf="freeMode">
                <span data-translate="billing.table.pricing.text-1b">
                  {{ t("billing.table.pricing.text-1b") }}
                  <!-- Zusätzliche jährliche Gebühr exkl. MWST nach Ablauf der Gratiswoche (ab -->
                </span>
                {{ validUntil | date: "dd/MM/yy" }} )
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">{{ priceForNextWindow / 100 | number: "1.2-2" }} CHF</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="centeredBoxText flexBoxOuter">
        <table class="table table-blue-border flexBoxInner">
          <thead>
            <tr>
              <th style="text-align: right"></th>
              <th style="text-align: left"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style="width: 50%; text-align: right"
                data-translate="billing.formAdditionalAccounts.number.placeholder"
              >
                {{ t("billing.formAdditionalAccounts.number.placeholder") }}
                <!-- Anzahl zusätzliche Accounts -->
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  {{ numAdditionalUsers }}
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 50%; text-align: right" data-translate="billing.table.pricing.price-no-vat">
                {{ t("billing.table.pricing.price-no-vat") }}
                <!-- Kosten exkl. MWST jetzt fällig -->
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  {{ priceForCurrentWindow / 100 | number: "1.2-2" }} CHF
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 50%; text-align: right">
                {{ vat | number: "1.2-2"
                }}<span data-translate="mwst">
                  {{ t("prices.mwst") }}
                  <!-- % MWST -->
                </span>
              </td>
              <td style="width: 50%">
                <div style="width: 90px; text-align: right">{{ effectiveVat / 100 | number: "1.2-2" }} CHF</div>
              </td>
            </tr>
            <tr>
              <td style="width: 50%; text-align: right">
                <b data-translate="billing.table.pricing.price-vat">
                  {{ t("billing.table.pricing.price-vat") }}
                  <!-- Kosten inkl. MWST jetzt fällig -->
                </b>
              </td>
              <td style="width: 50%; text-align: left">
                <div style="width: 90px; text-align: right">
                  <b>{{ priceForCurrentWindowInclVat / 100 | number: "1.2-2" }} CHF</b>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="centeredBoxText" style="margin-top: 20px" [hidden]="isAuthorized">
        <!--                currently not visible (missing implementation)-->
        <button
          type="submit"
          [disabled]="formAdditionalAccounts.invalid"
          (click)="buyAdditionalUsers()"
          class="btn btn-elegant-black"
          *ngIf="!freeMode && !isClicked"
        >
          Jetzt auf Rechnung kostenpflichtig kaufen
        </button>
        <button
          type="submit"
          [disabled]="formAdditionalAccounts.invalid"
          (click)="buyAdditionalUsers()"
          class="btn btn-elegant-black"
          *ngIf="freeMode"
        >
          Jetzt bestellen, kostenpflichtig erst nach Ablauf der Gratiswoche
        </button>
      </div>
      <div class="centeredBoxText" style="margin-top: 33px">
        <div
          class="alert kw-attention"
          [hidden]="!(errorPaymentFormError && !errorPaymentFormSystemError)"
          data-translate="billing.messages.error.paymentformerror"
          [innerHTML]="t('billing.messages.error.paymentformerror')"
        >
          <!-- <strong>Bestellung fehlgeschlagen!</strong> Bitte versuchen Sie es später nochmals -->
        </div>
        <div
          class="alert kw-attention centeredBoxText flexBoxInner"
          [hidden]="!(errorPaymentFormError && errorPaymentFormSystemError)"
          data-translate="register.messages.error.paymentformsystemerror"
          [innerHTML]="t('register.messages.error.paymentformsystemerror')"
        >
          <!-- <strong>System not available!</strong> -->
        </div>
        <div
          class="alert kw-attention"
          [hidden]="!invalidCard"
          data-translate="billing.messages.error.paymentformerror"
          [innerHTML]="t('billing.table.pricing.text-1')"
        >
          <!-- <strong>Bestellung fehlgeschlagen!</strong> Bitte überprüfen Sie Ihre Kartenlimite oder hinterlegen Sie eine andere Karte. -->
        </div>

        <div class="alert kw-attention" [hidden]="!(!isAuthorized && isClicked)">
          <strong>Bestellung wird verarbeitet!</strong>
        </div>

        <div class="alert kw-attention" [hidden]="!isAuthorized">
          <strong>Bestellung erfolgreich!</strong>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-elegant-black" (click)="cancel()" data-translate="global.navigation.close">
      {{ t("global.navigation.close") }}
      <!-- Close -->
    </button>
  </div>
</div>
