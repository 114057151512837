import { AfterViewInit, Directive, EventEmitter, Input, Output, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[kvisVisible]',
  standalone: true,
})
export class IsVisibleDirective implements AfterViewInit {
  @Input({ required: true }) elementVisible!: boolean;
  @Output() elementVisibleChange: EventEmitter<boolean> = new EventEmitter();
  constructor(private vcRef: ViewContainerRef) {}

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement;

    const observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting);
    });
    observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    this.vcRef.clear();
    // console.warn('isIntersecting', isIntersecting);
    this.elementVisible = isIntersecting;
    this.elementVisibleChange.emit(isIntersecting);
  }
}
