<div *transloco="let t">
  <div class="rowBackgroundGray">
    <div class="centeredBox flexBoxOuter" style="padding-top: 20px; padding-bottom: 33px">
      <div class="flexBoxInner">
        <h3 data-translate="billing.history.header">{{ t("billing.history.header") }}</h3>
      </div>
      <div *ngIf="!payments">
        <div class="flexBoxOuter">
          <div class="flexBoxInner" style="margin-top: 20px" data-translate="billing.history.in-free-time">
            {{ t("billing.history.in-free-time") }}
          </div>
        </div>
      </div>
      <div *ngIf="payments" class="flexBoxOuter">
        <div class="flexBoxInner" style="margin-top: 20px">
          <div class="table-responsive" style="overflow: visible">
            <table class="table table-striped table-striped-kw">
              <thead>
                <th>
                  <div data-translate="billing.table.order">
                    {{ t("billing.table.order") }}
                  </div>
                </th>
                <th>
                  <div data-translate="billing.table.date">
                    {{ t("billing.table.date") }}
                  </div>
                </th>
                <th>
                  <div data-translate="billing.table.amount">
                    {{ t("billing.table.amount") }}
                  </div>
                </th>
                <th>
                  <div data-translate="billing.table.state">{{ t("billing.table.state") }}</div>
                </th>
                <th>
                  <div data-translate="billing.table.download-invoice">{{ t("billing.table.download-invoice") }}</div>
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let p; in: payments">
                  <td>{{ p.confirmationId }}</td>
                  <td>{{ p.date | date: "dd/MM/yy" }}</td>
                  <td>{{ p.amount / 100 | number: "2" }}</td>
                  CHF
                  <td>{{ translateStatus(p.status) }}</td>
                  <td>
                    <button
                      class="btn btn-sm"
                      (click)="downloadPaymentPdf(p)"
                      style="border: none; background-color: Transparent; background-repeat: no-repeat"
                    >
                      <span class="glyphicon glyphicon-download"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
